import { id } from 'element-plus/es/locale'
import { defineStore } from 'pinia'
import pako from 'pako'
import moment from 'moment'
import { publicApi, userApi, contractApi } from '@/apis'
import { MarketData } from '@/@types/marketData'
import { ContractModel } from '@/@types/contract'
import { useCountStore } from '@/store/index'

const useStore = defineStore('huobiStoreId', {
  state: () => ({
    webSocket: {} as WebSocket,
    bar: [],
    date: [],
    candlestick: [],
    laseDateTime: 0,

    kTime: '1min',
    Ktype: 'ethusdt',
    lastPrice: 0,
    askArr: [],
    bidArr: [],
    loading: false, // 显示等待框
    id: 0, // unix时间，同时作为消息ID
    amount: 0, // 24小时成交量
    count: 0, // 24小时成交笔数
    open: 0, // 24小时开盘价
    close: 0, // 最新价
    low: 0, // 24小时最低价
    high: 0, // 24小时最高价
    vol: 0, // 24小时成交额
    version: 0, // 版本号
    priceChange: 0, // 版本号
    marketOrderTimeList: [],//秒合约时间列表
    kLineType0: 'ETH',
    kLineType1: 'USDT',
    levers: [
      { value: '1', text: '1x' },
      { value: '2', text: '2x' },
      { value: '3', text: '3x' },
      { value: '5', text: '5x' },
      { value: '10', text: '10x' },
      { value: '25', text: '25x' },
      { value: '50', text: '50x' },
      { value: '100', text: '100x' }
    ],
    showLeversPopup: false, // 显示杠杆倍数选择
    showMarketSheet: false, // 显示市场币种列表
    showContractCreateOrder: false, // 显示创建合约订单
    showTradingType: 1, // 显示的交易窗口类型 0图表 1盘口
    contractList: [] as ContractModel[],
    contractId: 0, // 当前选择的合约Id
    contract: {} as ContractModel, // 当前选择的合约
    security: 0, // 合约订单保证金
    handling: 0, // 合约订单手续费
    tradeType: 'buy', // 合约方向
    lever: 1, // 合约杠杆
    securityType: '0', // 保证金类型
    orderNumber: '', // 合约当前数量
    orderList: [], // 合约订单列表
    orderPage: 1, // 订单页码
    orderLimit: 10, // 订单页数据大小
    orderStatus: 0, // 订单状态

    orderMarketList: [], // 合约订单列表
    orderMarketPage: 1, // 订单页码
    orderMarketLimit: 10, // 订单页数据大小

    showCreateMarketOrder: false, // 显示秒合约下单窗口
    marketTimeId: 1, // 选择的秒合约时间Id
    marketTimeText: 1, // 选择的秒合约时间Id
    marketContractType: 0 // 选择的秒合约方向 1做多 0做空
  }),
  actions: {
    showLoading() {
      this.loading = true
    },
    hideLoading() {
      this.loading = false
    },
    /**
     * 订阅火币数据
     */
    subHuobi() {
      try {
        this.webSocket.send(JSON.stringify({ sub: `market.${this.kLineType0.toLowerCase()}${this.kLineType1.toLowerCase()}.kline.${this.kTime}` }))
        this.webSocket.send(JSON.stringify({ req: `market.${this.kLineType0.toLowerCase()}${this.kLineType1.toLowerCase()}.kline.${this.kTime}` }))

        this.webSocket.send(JSON.stringify({ sub: `market.${this.kLineType0.toLowerCase()}${this.kLineType1.toLowerCase()}.detail` }))
        this.webSocket.send(JSON.stringify({ sub: `market.${this.kLineType0.toLowerCase()}${this.kLineType1.toLowerCase()}.depth.step1` }))
      } catch (error) { }
    },
    /**
     * 取消订阅火币数据
     */
    unSubHuobi() {
      try {
        this.webSocket.send(
          JSON.stringify({
            unsub: `market.${this.kLineType0.toLowerCase()}${this.kLineType1.toLowerCase()}.depth.step1`,
            id: 'id1'
          })
        )

        this.webSocket.send(
          JSON.stringify({
            unsub: `market.${this.kLineType0.toLowerCase()}${this.kLineType1.toLowerCase()}.detail`,
            id: 'id1'
          })
        )

        this.webSocket.send(
          JSON.stringify({
            unsub: `market.${this.kLineType0.toLowerCase()}${this.kLineType1.toLowerCase()}.kline.${this.kTime}`,
            id: 'id1'
          })
        )
      } catch (error) { }
    },
    /**
     * 处理行情详情数据
     */
    processDetail(data) {
      const { tick } = data
      // 计算涨跌幅
      const priceChange = ((tick.close - this.lasrPrice) / this.lasrPrice) * 100

      this.lasrPrice = tick.close
      const marketData: MarketData = {
        id: tick.id,
        amount: tick.amount,
        count: tick.count,
        open: tick.open,
        close: tick.close,
        low: tick.low,
        high: tick.high,
        vol: tick.vol,
        version: tick.version,
        priceChange
      }
      this.setMarketData(marketData)
    },
    /**
     * 处理行情深度数据
     */
    processDepth(data) {
      const askTempArr = []
      const bidTempArr = []
      const askMax = data.tick.asks[5][0]
      const askMin = data.tick.asks[0][0]
      const askChaSum = askMax - askMin

      const bidMax = data.tick.bids[5][0]
      const bidMin = data.tick.bids[0][0]
      const bidChaSum = bidMax - bidMin
      for (let index = 0; index < 5; index++) {
        const askRow = data.tick.asks[index]
        const askCha = askMax - askRow[0]
        const ask = {
          price: askRow[0],
          num: askRow[1],
          rate: (askCha / askChaSum) * 95
        }

        const bidRow = data.tick.bids[index]
        const bidCha = bidMax - bidRow[0]
        const bid = {
          price: bidRow[0],
          num: bidRow[1],
          rate: 100 - (bidCha / bidChaSum) * 95
        }
        askTempArr.push(ask)
        bidTempArr.push(bid)
      }
      this.askArr = askTempArr
      this.bidArr = bidTempArr
    },
    /**
     * 处理行情K线数据
     */
    processKline(data) {
      const dataArr = []
      const barArr = []
      const dateArr = []
      data.data.forEach((row) => {
        dataArr.push([row.open, row.close, row.low, row.high])
        barArr.push(row.high)
        if (this.kTime.value == '1day') {
          dateArr.push(moment.unix(row.id).format('MM/DD'))
        } else {
          dateArr.push(moment.unix(row.id).format('HH:mm'))
        }
      })

      this.bar = barArr
      this.date = dateArr
      this.candlestick = dataArr
      this.laseDateTime = data.data[data.data.length - 1].id
    },
    /**
     * 处理行情K线数据  增量数据
     */
    processKlineTick(data) {
      if (data.tick.id > this.laseDateTime) {
        this.candlestick.push([data.tick.open, data.tick.close, data.tick.low, data.tick.high])
        this.bar.push(data.tick.high)
        if (this.kTime == '1day') {
          this.date.push(moment.unix(data.tick.id).format('MM/DD'))
        } else {
          this.date.push(moment.unix(data.tick.id).format('HH:mm'))
        }

        this.laseDateTime = data.tick.id
      } else {
        this.candlestick[this.candlestick.length - 1] = [data.tick.open, data.tick.close, data.tick.low, data.tick.high]
        this.bar[this.bar.length - 1] = data.tick.high
      }
    },
    /**
     * 初始化WebSocket
     */
    initWebSocket() {
      const _this = this
      const thisWebSocket = new WebSocket('wss://api.huobi.pro/ws')
      this.webSocket = thisWebSocket
      thisWebSocket.onopen = function () {
        _this.subHuobi()
      }
      thisWebSocket.onmessage = function (event) {
        const blob = event.data
        const reader: any = new FileReader()
        reader.onload = function (e: any) {
          const ploydata = new Uint8Array(e.target.result)

          const restored = JSON.parse(pako.inflate(ploydata, { to: 'string' }))
          if (restored.ping) {
            thisWebSocket.send(JSON.stringify({ pong: restored.ping }))
            return
          }

          // 订阅数据成功!,消息忽略
          if (restored.subbed) {
            return
          }

          // console.log(restored)
          if (restored.rep && restored.rep.indexOf('.kline') >= 1) {
            /**
             * 处理行情K线数据  全量数据
             */
            _this.processKline(restored)
          }
          if (restored.tick && restored.ch.indexOf('.kline') >= 1) {
            /**
             * 处理行情K线数据  增量数据
             */
            _this.processKlineTick(restored)
          }

          if (restored.ch && restored.ch.indexOf('.detail') >= 1) {
            /**
             * 处理行情详情数据
             */
            _this.processDetail(restored)
          }

          if (restored.ch && restored.ch.indexOf('depth.step') >= 1) {
            /**
             * 处理行情深度数据
             */
            _this.processDepth(restored)
          }
        }
        reader.readAsArrayBuffer(blob, 'utf-8')
      }
    },
    /**
     * 修改K线时间
     */
    changeKtime(kTimeType) {
      this.unSubHuobi()
      this.kTime = kTimeType
      this.subHuobi()
    },
    /**
     * 设置杠杆
     */
    setLever(lever) {
      this.lever = lever.selectedValues[0]

      this.changeNumber()
    },
    /**
     * 设置市场行情数据
     * @param marketData 市场当前行情数据
     */
    setMarketData(marketData: MarketData) {
      this.id = marketData.id // unix时间，同时作为消息ID
      this.amount = marketData.amount // 24小时成交量
      this.count = marketData.count // 24小时成交笔数
      this.open = marketData.open // 24小时开盘价
      this.close = marketData.close // 最新价
      this.low = marketData.low // 24小时最低价
      this.high = marketData.high // 24小时最高价
      this.vol = marketData.vol // 24小时成交额
      this.version = marketData.version // 版本号
      this.priceChange = marketData.priceChange // 涨跌幅
      this.contract.close = marketData.close
      this.changeNumber()

      // 更新前端订单涨跌信息
      for (let index = 0; index < this.orderList.length; index++) {
        const order = this.orderList[index]
        if (this.contractId == order.contractId) {
          const priceChange = (marketData.close - order.startPrice) / order.startPrice
          this.orderList[index].priceChange = priceChange
          const profit = this.calcProfit(order.amount, this.contract.mianzhi, order.startPrice, marketData.close, order.contractOrderType)

          this.orderList[index].close = marketData.close
          this.orderList[index].profit = profit
          this.orderList[index].handling = profit <= 0 ? 0 : (profit * this.contract.handling).toFixed(4)
          this.orderList[index].securityRate = this.calcSecurityRate(this.orderList[index].security, profit)
        }
      }
    },
    // 计算盈亏
    calcProfit(amount, mianzhi, startPrice, close, contractOrderType) {
      const chicang = amount * mianzhi

      let priceChange = ((close - startPrice) / startPrice) * 100

      let profit = 0

      if (contractOrderType === 0) {
        if (priceChange >= 0) {
          priceChange = -Math.abs(priceChange)
        } else {
          priceChange = Math.abs(priceChange)
        }
        // 做空
        profit = (priceChange * chicang) / 100
      } else {
        // 做多
        profit = (priceChange * chicang) / 100
      }

      return profit
    },
    /**
     * 计算保证金
     * @param security 保证金
     * @param profit 盈利
     * @returns
     */
    calcSecurityRate(security, profit) {
      return ((security + profit) / security) * 100
    },
    /**
     * 选择合约
     * @param contractId 选择的合约Id
     */
    selectContractById(contractId) {
      for (const key in this.contractList) {
        if (this.contractList[key].id == contractId) {
          this.unSubHuobi()

          this.kLineType0 = this.contractList[key].marketType1
          this.kLineType1 = this.contractList[key].marketType2
          this.contractId = this.contractList[key].id
          this.contract = this.contractList[key]

          this.subHuobi()

          useCountStore().updateOrder()
          return
        }
      }
    },
    /**
     * 设置默认合约
     */
    selectContractByDefault() {
      for (const key in this.contractList) {
        if (this.contractList[key].default) {
          this.unSubHuobi()
          this.kLineType0 = this.contractList[key].marketType1
          this.kLineType1 = this.contractList[key].marketType2
          this.contractId = this.contractList[key].id
          this.contract = this.contractList[key]
          this.subHuobi()

          useCountStore().updateOrder()
          return
        }
      }
    },
    /**
     * 设置交易方向
     * @param type 交易方向
     */
    selectTradeType(type) {
      this.tradeType = type
    },
    /**
     * 显示隐藏合约列表
     */
    changeShowMarketSheet() {
      this.showMarketSheet = !this.showMarketSheet
      if (this.showMarketSheet) {
        this.loadMarketData()
      }
    },
    /**
     * 设置显示隐藏创建订单Sheel
     * @param type 交易方向
     */
    changeShowContractCreateOrder(type) {
      console.log(type)
      this.selectTradeType(type)
      this.showContractCreateOrder = !this.showContractCreateOrder
    },
    /**
 * 载入秒合约时间列表
 */
    async loadMarkeOrderTimeData() {
      const result = await publicApi.getMarketOrderTimeList()
      if (result.code == 0) {
        this.marketOrderTimeList = result.data
        if (this.marketOrderTimeList.length >= 1) {
          this.marketTimeId = this.marketOrderTimeList[0].id;
          this.marketTimeText = this.marketOrderTimeList[0].contractTime;
        }
      }
      return result
    },
    /**
     * 载入币种市场行情数据
     */
    async loadMarketData() {
      const result = await publicApi.getMarketList()
      if (result.code == 0) {
        this.contractList = result.data

        this.selectContractByDefault()
      }
      return result
    },
    /**
     * 创建秒合约订单
     */
    async createMarketOrder() {
      const result = await contractApi.createMarketOrder(this.contractId, this.marketTimeId, this.marketContractType, this.orderNumber)
      if (result.code == 0) {
      }
      return result
    },
    /**
     * 获取订单列表
     * @param page 页码
     * @param limit 页数锯大小
     * @returns 订单列表实体
     */
    async getMarketOrderList(page, limit) {
      const result = await contractApi.getMarketOrderList(page, limit)
      if (result.code == 0) {
        this.orderMarketPage = result.page
        this.orderMarketLimit = result.limit
        this.orderMarketList = result.data
      }

      return result
    },
    /**
     * 创建合约订单
     */
    async contractTrade(type, showBuy) {
      const result = await contractApi.createContractOrder(this.contractId, type, this.tradeType, this.orderNumber, this.lever, this.securityType)
      if (result.code == 0) {
        this.orderNumber = ''
        await this.getOrderList(1, 99, 0)

        if (showBuy) {
          this.changeShowContractCreateOrder()
        }

        useCountStore().updateOrder()
      }
      return result
    },
    /**
     * 获取订单列表
     * @param page 页码
     * @param limit 页数锯大小
     * @param status 订单状态
     * @returns 订单列表实体
     */
    async getOrderList(page, limit, status) {
      const result = await contractApi.getContractOrderList(page, limit, status)
      if (result.code == 0) {
        this.orderPage = result.page
        this.orderLimit = result.limit
        this.orderStatus = status
        this.orderList = result.data
      }

      return result
    },
    /**
     * 订单平仓
     * @param orderId 订单Id
     */
    async closePosition(orderId) {
      const result = await contractApi.closePosition(orderId)
      if (result.code == 0) {
        await this.getOrderList(1, 99, 0)
      }

      return result
    },
    /**
     * 下单金额或者杠杆修改,重新计算保证金
     */
    changeNumber() {
      if (!this.orderNumber) {
        this.security = 0
        return
      }
      // 手续费 = 张数 * 当前价格 * 手续费比例
      this.handling = this.orderNumber * this.contract.close * this.contract.handling
      // 初始保证金 = 面值 * |张数| * 合约乘数 * 标记价格／杠杆倍数，初始保证金将随交易币种价格变化而变动。
      const baozhengjin = ((this.contract.mianzhi * this.orderNumber * this.contract.close) / this.lever) * this.contract.securityDepositRate
      // var baozhengjin = this.contract.mianzhi * this.orderNumber * this.contract.heyueChengshu * 10000 / this.lever
      console.log('baozhengjin', baozhengjin)
      // 假设当前 ETH 价格为 10,000 USDT/BTC，用户希望使用 10 倍杠杆开多 1 BTC 等值的永续合约，
      // 用户开多张数 = 开多 BTC 数量 / 面值=1/0.01=100张。
      // 初始保证金 = 面值*张数*BTC 价格/杠杆倍数=0.01*100*10,000/10=1,000USDT

      if (this.securityType == '0') {
        // 全仓保证金
        this.security = baozhengjin
      } else {
        // 逐仓保证金
        this.security = baozhengjin
      }
    }
  }
})

export default useStore
