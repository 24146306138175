export default {
  English: 'English',
  Chinese: '简体中文',
  TraditionalChinese: '繁體中文',
  Korean: '한국인',
  Japanese: '日本語',
  Vietnamese: 'Tiếng Việt',

  Asset_Management_Savings_Bank: '资产储蓄银行',
  Bank_Account: '银行账户',
  Participate_in_savings: '参与储蓄',
  Selected_financial_management: '精选理财',
  user_pool: '用户池',
  account: '账户',
  total_output: '总产量',
  Valid_node: '有效节点',
  participant: '参加者',
  income: '收益',
  Remaining_amount: '储蓄收益',
  savings_income: '储蓄收益',
  Todays_earnings: '今日收益',
  Financial_management_income: '理财收益',
  exchange: '交换',
  recharge: '充值',
  extract: '提取',
  Record: '记录',
  convertible_income: '可兌換收益',
  exchange_amount: '交换金额',
  Please_enter_the_exchange_amount: '请输入交换金额',
  total: '全部',
  Available_Balance: '可用余额',
  Withdrawal_amount: '提款金额',
  Please_enter_the_withdrawal_amount: '请输入提款金额',
  Profit_record: '盈利记录',
  address: '地址',
  number: '数字',
  common_problem: '常见问题',
  Previous_page: '上一页',
  Next_page: '下一页',
  Audit_Report: '审计报告',
  We_have_a_security_audit_report: '我們有一份安全的审计报告',
  partner: '伙伴',
  our_business_partners: '我们的商业伙伴',
  expenditure: '支出',
  amount: '账户',
  remark: '备注',
  bankNumberName: '账户名',
  Quote_change: '涨跌幅',
  Highest: '最高',
  My_Order: '我的订单',
  Buy: '购买',
  Kline_chart: 'K线图',
  Please_select_contract_time: '请选择合约时间',
  purchasing_price: '购买金额',
  Please_enter_the_purchase_amount: '请输入购买金额',
  savings: '储蓄单位',
  Financial_institution_name: '金融机构名称',
  Please_enter: '请输入',
  Branch_name: '分店名称',
  branch_number: '分行号',
  zhanghao: '账号',
  Account_holder: '账户持有人',
  UsdtERC20Address: 'Usdt ERC20 地址',
  binding: '绑定',
  Invite_friends: '邀请朋友',
  CopiedSuccessfully: '复制成功',
  Copy_failed: '复制失败',
  Please_enter_the_amount: '请输入金额',
  Select_storage_period: '选择存储周期',
  money: '金额',
  USDT: 'USDT',
  Daily_rate_of_return: '日收益率',
  savings_products: '储蓄产品',
  Currency: '币种',
  Saving_amount: '储蓄金额',
  PleaseEnterTheCorrectAmount: '请输入正确金额',
  NotOpenYet: '暂未开放',
  Earnings: '收入',
  Expenses: '支出',
  Confirm: '确定',
  Cancel: '取消',
  Copy: '复制',
  RechargeAmount: '充值数量',
  PleaseEnterTheRechargeAmount: '请输入充值数量',
  RechargeERC20Address: 'UsdtERC20充值地址',
  Submit: '提交',

  YourAccountHasBeenDisabled: '您的账户已禁用!',
  InformationUpdatedSuccessfully: '信息更新成功',
  YourAccountIsFrozen: '您的账户被冻结!',
  ProductDoesNotExist: '产品不存在',
  ThePurchaseAmountMustBeLessThanTheProductAmount: '购买金额必须小于产品金额',
  InsufficientAccountBalance: '账户余额不足',
  WithdrawalRequestHasBeenSubmitted: '提现请求已提交',
  RechargeRequestHasBeenSubmitted: '充值请求已提交',
  AmountEnteredIncorrectly: '输入的金额错误!',
  Successfully: '成功',
  Day: '天',
  BuyFailed: '购买失败',
  BuySuccessfully: '购买成功',

  Withdrawal: '提现',
  PurchaseProduct: '购买产品',
  RegistrationTrialBonus: '注册体验金',
  CurrencyConversion: '货币转换',
  SavingsIncome: '储蓄收益',
  FinancialIncome: '理财收益',
  SavingsRelease: '储蓄释放',
  BindingSuccessful: '绑定成功',
  ViewThisAccount: '查看此账户',
  NoWalletEnv: '请使用钱包浏览器打开本系统!',
  PleaseBindYourBankCardBeforeWithdrawingMoney: '请绑定银行卡后提现!',
  天: '天',
  周: '周',
  月: '月',
  年: '年',
  ContractTrading: '合约交易',
  '1Min': '分时',
  '5Min': '5分',
  '1Hour': '1时',
  '1Day': '1日',

  Loading: '加载中',
  ContractTimeError: '合约时间错误!',
  ContractTypeError: '合约类型错误!',
  ContractNotSupported: '合约不支持!',
  YouHaveAContractOrderInProgressButTheOrderFailed: '你有合约订单进行中，下单失败!',
  NotOrder: '订单不存在!',

  PleaseSelectContractTime: '请选择合约时间',
  PleaseEnterThePurchaseAmount: '请输入购买金额',
  BuyLong: '做多',
  BuyLess: '做空',
  PurchasingPrice: '购买金额',
  Gain: '盈',
  Loss: '亏',
  Ok: '确定',
  ContractOrder: '合约下单',
  ContractPrincipal: '合约本金',
  WithdrawalReturn: '提现退回',

  OrderStatusError: '订单状态错误!',
  HighestPrice: '最高价',
  LowestPrice: '最低价',
  TradingVolume: '交易量',
  TransactionVolume: '交易额',
  Position: '仓位',
  CommissionRecord: '委托记录',
  ProfitLoss: '盈亏',
  OpeningPrice: '开仓价格',
  CurrentPrice: '当前价格',
  PositionQuantity: '持仓数量',
  ClosePosition: '平仓',
  Service: '客服',
  ContractList: '合约列表',
  LatestPrice: '最新价',
  PleaseEnterTheSearchKeyword: '请输入搜索关键词',
  Number: '数量',
  NoData: '暂无数据',
  Price: '价格',
  TradeBuy: '买入',
  TradeSell: '卖出',
  Security: '保证金',
  Lever: '合约杠杆',
  Chart: '图表',
  Trading: '盘口',
  Cross: '全仓',
  Isolated: '逐仓',
  WalletLogin: '钱包登录',
  Username: '用户名',
  Password: '密码',
  ConfirPassword: '确认密码',
  PleaseEnterTheUsername: '请输入用户名!',
  PleaseEnterThePassword: '请输入密码!',
  PleaseEnterTheConfirPassword: '请输入确认密码!',
  Logout: '退出登录',
  ConfirPasswordError: '确认密码错误!',
  UsernameAlreadyExists: '用户名已存在!',
  InsufficientAccountSecurity: '账户保证金不足!',
  AlreadyHaveAnAccountClickToLogIn: '已经有账号? 点击登录...',
  DontHaveAnAccountYetClickToRegister: '还没有账号? 点击注册...',
  PleaseCompleteTheIdentityAuthenticationFirst: '请先完成身份认证!',
  AuthenticationBindingSuccessful: '身份验证绑定成功!',
  WaitingReview: '等待验证!',
  Next: '下一步',
  Jump: '跳过',
  RegisterAccount: '注册账户',
  Authentication: '身份验证',
  SecureBinding: '安全绑定',
  Finish: '完成',
  Name: '姓名',
  PleaseTypeInYourName: '请输入姓名',
  IdNumber: '身份证号',
  PleaseTypeInYourIdNumber: '请输入身份证号',
  FrontOfDocument: '证件正面',
  ReverseSideOfId: '证件反面',
  HoldingIdPhoto: '手持证件照',
  UsdtAddress: 'Usdt地址',
  PleaseTypeInYourUsdtAddress: '请输入您的Usdt地址',
  Failed: '失败',
  ClosePositionPrice: '平仓价格',
  PositionClosed: '已平仓',
  QuickRecharge: '快速充值',
  CopyRechargeAddress: '复制充值地址',
  Login: '登录',
  Email: '邮箱',
  PleaseEnterTheEmail: '请输入您的邮箱地址',
  PleaseEnterTheVerifyCode: '请输入您的邮箱验证码',
  EmailCode: '邮箱验证码',
  GetCode: '获取验证码',
  SwitchLanguage: '选择语言',
  RegisterType: '注册类型',
  RegisterSuccess: '注册成功',
  PasswordError: '密码错误',
  PleaseEnterTheTransferAddress: '请输入您的转账地址',
  TransferVoucher: '转账凭证',
  OpenPosition: '开仓',
  BuyToOpenLong: '买入开多',
  SellToOpenShort: '卖出开空',
  BuyShort: '卖出平多',
  SellLong: '买入平空',
  Kepingduo: '可平多',
  Kepingkong: '可平空',

  PleaseTypeInYourETHAddress: '请输入您的ETH地址',
  PleaseTypeInYourBTCAddress: '请输入您的BTC地址',
  Save: '保存',
  SecurityRate: '保证金率',
  Handling: '手续费',
  Zhang: '张',
  SecurityUnfreeze: '保证金解冻',
  AccountError: '账号错误!',
  jiaoyileixing: '交易类型：',
  Account_holder_jiaming: '账户名（假名）：',
  Close: '关闭'
}
