import axios from 'axios'
import qs from 'qs'

import {
  TRCVT_USER_ACCESSTOKEN,
  TRCVT_USER_REFRESHTOKEN,
} from '@/store/constant'
import { locale } from 'moment';
import { useCountStore } from '@/store/index';



var baseUrl = import.meta.env.VITE_APP_AXIOS_BASE_URL;
var appType = import.meta.env.VITE_APP_TYPE
const instance = axios.create({
  baseURL: baseUrl
})

/**
 * 请求拦截
 */
instance.interceptors.request.use((config) => {
  const { method, params } = config
  var lang = localStorage.getItem('LANG')
  const token = 'Bearer ' + localStorage.getItem(TRCVT_USER_ACCESSTOKEN)
  const xtoken = 'Bearer ' + localStorage.getItem(TRCVT_USER_REFRESHTOKEN)

  // X-Authorization: Bearer 你的刷新token

  // 附带鉴权的token
  const headers: any = {
    Authorization: token,
    'X-Authorization': xtoken,
    'Accept-Language': lang
  }

  if (method === 'post') {
    headers['Content-type'] = 'application/x-www-form-urlencoded'
    config.data = qs.stringify(config.data)
  }


  // 不缓存get请求
  if (method === 'get') {
    headers['Cache-Control'] = 'no-cache'
  }
  // delete请求参数放入body中
  if (method === 'delete') {
    headers['Content-type'] = 'application/json;'
    Object.assign(config, {
      data: params,
      params: {}
    })
  }

  return {
    ...config,
    headers
  }
})

/**
 * 响应拦截
 */
instance.interceptors.response.use((v) => {
  if (v.config.url == "/API/Login") {
    if (v.data.code == 0) {
      localStorage.setItem(TRCVT_USER_ACCESSTOKEN, v.data.data.accessToken)
      localStorage.setItem(TRCVT_USER_REFRESHTOKEN, v.data.data.refreshToken)

      const store = useCountStore()
      store.showLoginSheel = false
    }
  }

  var accessToken = v.headers['access-token']
  var refreshToken = v.headers['x-access-token']

  if (accessToken) {
    localStorage.setItem(TRCVT_USER_ACCESSTOKEN, accessToken)
    localStorage.setItem(TRCVT_USER_REFRESHTOKEN, refreshToken)
  }

  if (v.data?.code === 401) {
    localStorage.removeItem(TRCVT_USER_ACCESSTOKEN)
    localStorage.removeItem(TRCVT_USER_REFRESHTOKEN)
    // alert('即将跳转登录页。。。', '登录过期')
    // setTimeout(redirectHome, 1500)
    // window.location.reload()
    return v.data
  }
  if (v.status === 200) {
    return v.data
  }
  // alert(v.statusText, '网络错误')
  return Promise.reject(v)
}, async function (errorData) {
  if (errorData.response.status == 401 || errorData.response.status == 403) {
    localStorage.removeItem(TRCVT_USER_ACCESSTOKEN)
    localStorage.removeItem(TRCVT_USER_REFRESHTOKEN)

    var store = useCountStore()

    store.checkedLoginAndShowLogin()
  }
})
export default instance
