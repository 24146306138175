import { defineStore } from 'pinia'
import { publicApi, userApi } from '@/apis/index'
import { useHuobiStore } from '@/store/index'

import {
  TRCVT_Agent,
  TRCVT_Uid,
  TRVCT_TOKEN,
  TRVCT_USER_ADDRESS,
  TRVCT_USER_BALANCE,
  TRCVT_USER_USERID,
  TRCVT_USER_NICKNAME,
  TRCVT_USER_AVATAR,
  TRCVT_USER_USDT_AMOUNT,
  TRCVT_USER_CHUXU_USDT_AMOUNT,
  TRCVT_USER_ETH_AMOUNT,
  TRCVT_USER_USERNUMBER,
  TRCVT_USER_TYPE,
  TRCVT_USER_ACCESS,
  TRCVT_USER_ACCESSTOKEN,
  TRCVT_USER_REFRESHTOKEN,

  TRCVT_RechargeUsdtERC20Address,
  TRCVT_RechargeUsdtTRC20Address,
  TRCVT_RechargeUsdtOmniAddress,

  TRCVT_Authentication,
  TRCVT_UsdtAddress,
  TRCVT_UsdtEthAddress,
  TRCVT_UsdtBtcAddress,
  TRCVT_Name,
  TRCVT_Identity,
  TRCVT_Img1,
  TRCVT_Img2,
  TRCVT_Img3,
  TRCVT_ContractOrderArr,

  TRCVT_USER_Chuxushouyi,
  TRCVT_USER_Jinrishouyi,
  TRCVT_USER_Licaishouyi,
  TRCVT_USER_Zhanghu,
} from '../constant'

const useStore = defineStore('storeId', {
  // arrow function recommended for full type inference
  state: () => {
    var orderArrJson = localStorage.getItem(TRCVT_ContractOrderArr)
    var orderArr = []
    try {
      orderArr = JSON.parse(orderArrJson)
    } catch {
      orderArr = []
    }

    return ({
      agent: localStorage.getItem(TRCVT_Agent) || null,
      uid: localStorage.getItem(TRCVT_Uid) || null,
      email: '',
      username: '',
      password: '',
      showLoginSheel: false,
      token: localStorage.getItem(TRVCT_TOKEN) || null,
      userAddress: localStorage.getItem(TRVCT_USER_ADDRESS) || null,
      userBalance: localStorage.getItem(TRVCT_USER_BALANCE) || null,
      userId: localStorage.getItem(TRCVT_USER_USERID) || null,
      nickname: localStorage.getItem(TRCVT_USER_NICKNAME) || null,
      usdtAmount: parseFloat(localStorage.getItem(TRCVT_USER_USDT_AMOUNT)) || 0,

      chuxushouyi: localStorage.getItem(TRCVT_USER_Chuxushouyi) ?? 0,  //储蓄收益
      jinrishouyi: localStorage.getItem(TRCVT_USER_Jinrishouyi) ?? 0,  //今日收益
      licaishouyi: localStorage.getItem(TRCVT_USER_Licaishouyi) ?? 0,  //理财收益
      zhanghu: localStorage.getItem(TRCVT_USER_Zhanghu) ?? 0,  //账户余额 USDT

      // localStorage.getItem(TRCVT_USER_USDT_AMOUNT) || null,

      chuxuUsdtAmount: localStorage.getItem(TRCVT_USER_CHUXU_USDT_AMOUNT) || null,
      ethAmount: localStorage.getItem(TRCVT_USER_ETH_AMOUNT) || null,
      avatar: localStorage.getItem(TRCVT_USER_AVATAR) || null,
      usernumber: localStorage.getItem(TRCVT_USER_USERNUMBER) || "",
      type: localStorage.getItem(TRCVT_USER_TYPE) || null,
      access: localStorage.getItem(TRCVT_USER_ACCESS) || null,
      accessToken: localStorage.getItem(TRCVT_USER_ACCESSTOKEN) || null,
      refreshToken: localStorage.getItem(TRCVT_USER_REFRESHTOKEN) || null,

      rechargeUsdtERC20Address: localStorage.getItem(TRCVT_RechargeUsdtERC20Address) || "",
      rechargeUsdtTRC20Address: localStorage.getItem(TRCVT_RechargeUsdtTRC20Address) || "",
      rechargeUsdtOmniAddress: localStorage.getItem(TRCVT_RechargeUsdtOmniAddress) || "",

      rechargeType: "1",
      rechargeNumber: '',
      rechargeImg: '',
      rechargeUserAddress: '',

      ethShouyiAmountSum: localStorage.getItem("ethShouyiAmountSum") || 0,
      ethShouyiAmountToDay: localStorage.getItem("ethShouyiAmountToDay") || 0,

      bankName: localStorage.getItem("bankName") || null,
      bankStoreName: localStorage.getItem("bankStoreName") || null,
      bankStoreNumber: localStorage.getItem("bankStoreNumber") || null,
      jiaoyileixing: localStorage.getItem("jiaoyileixing") || null,
      bankNumber: localStorage.getItem("bankNumber") || null,
      bankNumberName: localStorage.getItem("bankNumberName") || null,
      bankUsername: localStorage.getItem("bankUsername") || null,
      usdtERC20Address: localStorage.getItem("usdtERC20Address") || null,
      vipId: localStorage.getItem("vipId") || null,
      serviceUrl: localStorage.getItem("ServiceUrl") || "",
      userRecordLoading: false,
      userRecord: {
        data: []
      },



      authentication: localStorage.getItem(TRCVT_Authentication) || null,
      usdt_address: localStorage.getItem(TRCVT_UsdtAddress) || null,
      usdt_eth_address: localStorage.getItem(TRCVT_UsdtEthAddress) || null,
      usdt_btc_address: localStorage.getItem(TRCVT_UsdtBtcAddress) || null,
      name: localStorage.getItem(TRCVT_Name) || null,
      identity: localStorage.getItem(TRCVT_Identity) || null,
      img1: localStorage.getItem(TRCVT_Img1) || null,
      img2: localStorage.getItem(TRCVT_Img2) || null,
      img3: localStorage.getItem(TRCVT_Img3) || null,

      contractOrderArr: orderArr,
      duo: 0,
      kong: 0
    })
  },
  actions: {
    setAgentCode(agent, uid) {
      if (agent) {
        this.agent = agent
        localStorage.setItem(TRCVT_Agent, agent ?? "")
      }
      if (uid) {
        this.uid = uid
        localStorage.setItem(TRCVT_Uid, uid ?? "")
      }
    },
    /**
     * 提交充值订单
     */
    async submitRechargeInfo() {
      let number = parseInt(this.rechargeNumber)
      if (number <= 0) {
        return { code: -1, msg: "PleaseEnterTheCorrectAmount" }
      }

      var address = '';
      switch (this.rechargeType) {
        case '1':
          address = this.rechargeUsdtERC20Address
          break;

        case '2':
          address = this.rechargeUsdtTRC20Address
          break;

        default:
          address = this.rechargeUsdtOmniAddress
          break;
      }

      let result = await userApi.recharge(number + '', this.rechargeType, this.rechargeImg, this.rechargeUserAddress, address)

      if (result.code == 0) {
        this.rechargeNumber = 0
      }
      return result
    },
    openService() {
      const appType = import.meta.env.VITE_APP_TYPE.toString();
      switch (appType) {
        default:
        case "1":
          window.location.href = import.meta.env.VITE_APP_SERVICE_URL1;
          break;

        case "2":
          window.location.href = import.meta.env.VITE_APP_SERVICE_URL2;
          break;

        case "3":
          window.location.href = import.meta.env.VITE_APP_SERVICE_URL3;
          break;

        case "5":
          window.location.href = this.serviceUrl
          break;
      }
    },
    /**
     * 检查登录并且显示登录面板
     */
    checkedLoginAndShowLogin() {
      if (this.isLogin()) {
        return true
      }

      this.showLogin()
      return false
    },
    /**
     * 显示登录面板
     */
    showLogin() {
      this.email = ''
      this.username = ''
      this.password = ''
      this.showLoginSheel = true
    },
    /**
     * 是否已经登录
     */
    isLogin() {
      var token = localStorage.getItem(TRCVT_USER_ACCESSTOKEN)
      if (token) {
        return true
      }

      return false
    },
    loadUserRecord(page, limit) {
      this.userRecordLoading = true
      userApi.getRecordList(page, limit, null).then((recordResult) => {
        this.userRecordLoading = false
        if (recordResult.code == 0) {
          this.userRecord = recordResult
        }
      })
    },
    userRecordNextPage() {
      this.loadUserRecord(this.userRecord.page + 1, this.userRecord.limit)
    },
    userRecordPreviousPage() {
      this.loadUserRecord(this.userRecord.page - 1, this.userRecord.limit)
    },
    setServiceUrl(serviceUrl: any) {
      this.serviceUrl = serviceUrl
      localStorage.setItem("ServiceUrl", serviceUrl)
    },
    setUserinfo(userinfo: any) {
      this.authentication = userinfo.authentication
      localStorage.setItem(TRCVT_Authentication, userinfo.authentication ?? "2")

      this.usdt_address = userinfo.usdt_address
      localStorage.setItem(TRCVT_UsdtAddress, userinfo.usdt_address ?? "")

      this.usdt_eth_address = userinfo.ethAddress
      localStorage.setItem(TRCVT_UsdtEthAddress, userinfo.ethAddress ?? "")

      this.usdt_btc_address = userinfo.btcAddress
      localStorage.setItem(TRCVT_UsdtBtcAddress, userinfo.btcAddress ?? "")

      this.name = userinfo.name
      localStorage.setItem(TRCVT_Name, userinfo.name ?? "")
      this.identity = userinfo.identity
      localStorage.setItem(TRCVT_Identity, userinfo.identity ?? "")
      this.img1 = userinfo.img1
      localStorage.setItem(TRCVT_Img1, userinfo.img1 ?? "")
      this.img2 = userinfo.img2
      localStorage.setItem(TRCVT_Img2, userinfo.img2 ?? "")
      this.img3 = userinfo.img3
      localStorage.setItem(TRCVT_Img3, userinfo.img3 ?? "")

      this.userId = userinfo.userId
      localStorage.setItem(TRCVT_USER_USERID, userinfo.userId)

      this.nickname = userinfo.nickname
      localStorage.setItem(TRCVT_USER_NICKNAME, userinfo.nickname)

      this.avatar = userinfo.avatar
      localStorage.setItem(TRCVT_USER_AVATAR, userinfo.avatar)

      this.usdtAmount = userinfo.usdtAmount
      localStorage.setItem(TRCVT_USER_USDT_AMOUNT, userinfo.usdtAmount)

      this.ethAmount = userinfo.ethAmount
      localStorage.setItem(TRCVT_USER_ETH_AMOUNT, userinfo.ethAmount)

      this.chuxuUsdtAmount = userinfo.chuxuUsdtAmount
      localStorage.setItem(TRCVT_USER_CHUXU_USDT_AMOUNT, userinfo.chuxuUsdtAmount)

      this.usernumber = userinfo.usernumber
      localStorage.setItem(TRCVT_USER_USERNUMBER, userinfo.usernumber ?? "")

      this.type = userinfo.type
      localStorage.setItem(TRCVT_USER_TYPE, userinfo.type)

      this.access = userinfo.access
      localStorage.setItem(TRCVT_USER_ACCESS, userinfo.access)

      this.accessToken = userinfo.accessToken
      localStorage.setItem(TRCVT_USER_ACCESSTOKEN, userinfo.accessToken)

      this.refreshToken = userinfo.refreshToken
      localStorage.setItem(TRCVT_USER_REFRESHTOKEN, userinfo.refreshToken)

      this.bankName = userinfo.bankName
      localStorage.setItem("bankName", userinfo.bankName ?? "")

      this.bankStoreName = userinfo.bankStoreName
      localStorage.setItem("bankStoreName", userinfo.bankStoreName ?? "")

      this.bankStoreNumber = userinfo.bankStoreNumber
      localStorage.setItem("bankStoreNumber", userinfo.bankStoreNumber ?? "")

      this.jiaoyileixing = userinfo.jiaoyileixing
      localStorage.setItem("jiaoyileixing", userinfo.jiaoyileixing ?? "")

      this.bankNumber = userinfo.bankNumber
      localStorage.setItem("bankNumber", userinfo.bankNumber ?? "")

      this.bankNumberName = userinfo.bankNumberName
      localStorage.setItem("bankNumberName", userinfo.bankNumberName ?? "")

      this.bankUsername = userinfo.bankUsername
      localStorage.setItem("bankUsername", userinfo.bankUsername ?? "")

      this.usdtERC20Address = userinfo.usdtERC20Address
      localStorage.setItem("usdtERC20Address", userinfo.usdtERC20Address ?? "")

      this.vipId = userinfo.vipId
      localStorage.setItem("vipId", userinfo.vipId ?? "")

      this.contractOrderArr = userinfo.contractOrderArr
      var arr = JSON.stringify(userinfo.contractOrderArr)
      // console.log(arr)
      localStorage.setItem(TRCVT_ContractOrderArr, arr)


      this.loadUserRecord(1, 5)
    },
    /**
     * 获取最新用户信息
     */
    async getUserinfo() {
      var result = await userApi.getUserInfo()

      if (result.code === 0) {
        this.updateUserinfo(result.data)
      }

      return result
    },
    setIdentity(name, identity, img1, img2, img3) {
      this.name = name
      this.identity = identity
      this.img1 = img1
      this.img2 = img2
      this.img3 = img3


      localStorage.setItem(TRCVT_Name, this.name ?? "")
      localStorage.setItem(TRCVT_Identity, this.identity ?? "")
      localStorage.setItem(TRCVT_Img1, this.img1 ?? "")
      localStorage.setItem(TRCVT_Img2, this.img2 ?? "")
      localStorage.setItem(TRCVT_Img3, this.img3 ?? "")
    },
    async updateIdentity() {
      var result = await userApi.updateIdentity(
        this.name,
        this.identity,
        this.img1,
        this.img2,
        this.img3,
      )

      if (result.code == 0) {
        localStorage.setItem(TRCVT_Authentication, "2")
        this.authentication = "2"
      }

      return result
    },
    async updateUsdtAddress() {
      var result = await userApi.updateUsdtAddress(
        this.usdt_address,
        this.usdt_eth_address,
        this.usdt_btc_address,
      )

      if (result.code == 0) {
        localStorage.setItem(TRCVT_Authentication, "3")
        this.authentication = "3"
      }

      return result
    },
    updateUserinfo(userinfo) {
      this.usdtAmount = parseFloat(userinfo.usdtAmount)
      localStorage.setItem(TRCVT_USER_USDT_AMOUNT, userinfo.usdtAmount)


      this.chuxushouyi = userinfo.usdtAccount.chuxushouyi//储蓄收益
      localStorage.setItem(TRCVT_USER_Chuxushouyi, userinfo.usdtAccount.chuxushouyi)

      this.jinrishouyi = userinfo.usdtAccount.jinrishouyi//今日收益
      localStorage.setItem(TRCVT_USER_Jinrishouyi, userinfo.usdtAccount.jinrishouyi)

      this.licaishouyi = userinfo.usdtAccount.licaishouyi//理财收益
      localStorage.setItem(TRCVT_USER_Licaishouyi, userinfo.usdtAccount.licaishouyi)

      this.zhanghu = userinfo.usdtAccount.zhanghu //账户余额 USDT
      localStorage.setItem(TRCVT_USER_Zhanghu, userinfo.usdtAccount.zhanghu)


      this.ethAmount = userinfo.ethAmount
      localStorage.setItem(TRCVT_USER_ETH_AMOUNT, userinfo.ethAmount)

      this.chuxuUsdtAmount = userinfo.chuxuUsdtAmount
      localStorage.setItem(TRCVT_USER_CHUXU_USDT_AMOUNT, userinfo.chuxuUsdtAmount)


      this.ethShouyiAmountSum = userinfo.ethShouyiAmountSum
      localStorage.setItem("ethShouyiAmountSum", userinfo.ethShouyiAmountSum ?? "")

      this.ethShouyiAmountToDay = userinfo.ethShouyiAmountToDay
      localStorage.setItem("ethShouyiAmountToDay", userinfo.ethShouyiAmountToDay ?? "")

      this.bankName = userinfo.bankName
      localStorage.setItem("bankName", userinfo.bankName ?? "")

      this.bankStoreName = userinfo.bankStoreName
      localStorage.setItem("bankStoreName", userinfo.bankStoreName ?? "")

      this.bankStoreNumber = userinfo.bankStoreNumber
      localStorage.setItem("bankStoreNumber", userinfo.bankStoreNumber ?? "")
      this.jiaoyileixing = userinfo.jiaoyileixing
      localStorage.setItem("jiaoyileixing", userinfo.jiaoyileixing ?? "")

      this.bankNumber = userinfo.bankNumber
      localStorage.setItem("bankNumber", userinfo.bankNumber ?? "")

      this.bankNumberName = userinfo.bankNumberName
      localStorage.setItem("bankNumberName", userinfo.bankNumberName ?? "")

      this.bankUsername = userinfo.bankUsername
      localStorage.setItem("bankUsername", userinfo.bankUsername ?? "")

      this.usdtERC20Address = userinfo.usdtERC20Address
      localStorage.setItem("usdtERC20Address", userinfo.usdtERC20Address ?? "")

      this.usdt_address = userinfo.usdt_address
      localStorage.setItem(TRCVT_UsdtAddress, userinfo.usdt_address ?? "")

      this.usdt_eth_address = userinfo.ethAddress
      localStorage.setItem(TRCVT_UsdtEthAddress, userinfo.ethAddress ?? "")

      this.usdt_btc_address = userinfo.btcAddress
      localStorage.setItem(TRCVT_UsdtBtcAddress, userinfo.btcAddress ?? "")


      this.authentication = userinfo.authentication
      localStorage.setItem(TRCVT_Authentication, userinfo.authentication ?? "2")

      this.usernumber = userinfo.usernumber
      localStorage.setItem(TRCVT_USER_USERNUMBER, userinfo.usernumber ?? "2")

      this.contractOrderArr = userinfo.contractOrderArr
      var arr = JSON.stringify(userinfo.contractOrderArr)
      localStorage.setItem(TRCVT_ContractOrderArr, arr)

      var huobiStore = useHuobiStore()

      for (var key in this.contractOrderArr) {
        if (key = huobiStore.contract.name) {
          this.duo = this.contractOrderArr[key].Duo
          this.kong = this.contractOrderArr[key].Kong
        }
      }
    },
    //更新多空持仓信息
    updateOrder() {
      var huobiStore = useHuobiStore()

      for (var key in this.contractOrderArr) {
        if (key = huobiStore.contract.name) {
          this.duo = this.contractOrderArr[key].Duo
          this.kong = this.contractOrderArr[key].Kong
        }
      }
    },
    updateAddress(value: string) {
      this.userAddress = value
      localStorage.setItem(TRVCT_USER_ADDRESS, value)
    },
    updateBalance(value: string) {
      this.userBalance = value
      localStorage.setItem(TRVCT_USER_BALANCE, value)
    },
    updateToken(data: any) {
      this.token = data.token
    },
    async asyncIncrease() {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      this.count += 1
    },
    /**
     * 钱包登录系统
     */
    async walletLogin() {
      var result = await userApi.login()

      if (result.code == 0) {
        // console.log(result.data)
        this.setUserinfo(result.data)
        this.updateUserinfo(result.data)
      }

      return result
    },
    /**
     * 账号密码登录系统
     */
    async usernameLogin() {
      var result = await userApi.loginByUsername(this.username, this.password)

      if (result.code == 0) {
        // console.log(result.data)
        this.setUserinfo(result.data)
        this.updateUserinfo(result.data)
      }

      return result
    },
    /**
     * 邮箱注册系统
     */
    async emailRegister(email, code, password) {
      var result = await userApi.emailRegister(email, code, password)

      if (result.code == 0) {
        // console.log(result.data)
        // this.setUserinfo(result.data)
      }

      return result
    },
    /**
     * 账号密码注册系统
     */
    async usernameRegister(username, password) {
      var result = await userApi.usernameRegister(username, password)

      if (result.code == 0) {
        // console.log(result.data)
        // this.setUserinfo(result.data)
      }

      return result
    },
    async logout() {
      localStorage.clear()
      window.location.reload()
    },
    //获取系统信息
    async getBaseinfo() {
      var result = await publicApi.getBaseInfo()
      if (result.code == 0) {
        this.rechargeUsdtERC20Address = result.data.rechargeUsdtERC20Address
        localStorage.setItem(TRCVT_RechargeUsdtERC20Address, result.data.rechargeUsdtERC20Address ?? "")

        this.rechargeUsdtTRC20Address = result.data.rechargeUsdtTRC20Address
        localStorage.setItem(TRCVT_RechargeUsdtTRC20Address, result.data.rechargeUsdtTRC20Address ?? "")

        this.rechargeUsdtOmniAddress = result.data.rechargeUsdtOmniAddress
        localStorage.setItem(TRCVT_RechargeUsdtOmniAddress, result.data.rechargeUsdtOmniAddress ?? "")
      }

      return result
    }
  }
})

export default useStore
