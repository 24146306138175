<script setup>

const appType = import.meta.env.VITE_APP_TYPE

</script>

<template>
  <van-swipe v-if="appType != 5" class="my-swipe" :autoplay="3000" indicator-color="white">
    <van-swipe-item>
      <img src="@/assets/swipe/1.jpg" style="width: 100%" />
    </van-swipe-item>
    <van-swipe-item>
      <img src="@/assets/swipe/2.jpg" style="width: 100%" />
    </van-swipe-item>
    <van-swipe-item>
      <img src="@/assets/swipe/3.jpg" style="width: 100%" />
    </van-swipe-item>
    <van-swipe-item>
      <img src="@/assets/swipe/4.jpg" style="width: 100%" />
    </van-swipe-item>
  </van-swipe>

  <van-swipe v-if="appType == 5" class="my-swipe" :autoplay="3000" indicator-color="white">
    <van-swipe-item>
      <img src="@/assets/swipe/5_1.jpg" style="width: 100%" />
    </van-swipe-item>
    <van-swipe-item>
      <img src="@/assets/swipe/5_2.jpg" style="width: 100%" />
    </van-swipe-item>
    <van-swipe-item>
      <img src="@/assets/swipe/5_3.jpg" style="width: 100%" />
    </van-swipe-item>
    <van-swipe-item>
      <img src="@/assets/swipe/5_4.jpg" style="width: 100%" />
    </van-swipe-item>
  </van-swipe>
</template>