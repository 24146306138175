<template>
  <div class="record-container">
    <div class="record-top-menu">
      <ul>
        <li class="record-top-menu-curr">
          {{ $t('Position') }}({{ huobiStore.orderMarketList.length }})
        </li>
        <!-- <li>
          当前委托(0)
        </li>
        <li>
          全站成交
        </li> -->
      </ul>
      <!-- <div>
        {{ $t('CommissionRecord') }}
      </div> -->
    </div>
    <div class="record-box">
      <div class="record-empty" v-if="huobiStore.orderMarketList.length <= 0" @click="loadRecord">
        <van-icon name="notes-o" />
        <div class="record-empty-text">{{ $t('NoData') }}</div>
      </div>
      <ul class="record-list" v-else>
        <li class="record-item" v-for="(item, index) in huobiStore.orderMarketList">
          <div class="record-info" v-if="appType == '2'">
            <span class="info-name">{{ item.name }}</span>
            <div class="info-trade-down" v-if="item.contractOrderType == 1">{{ $t('BuyLong') }}</div>
            <div class="info-trade-up" v-if="item.contractOrderType == 0">{{ $t('BuyLess') }}</div>
          </div>
          <div class="record-info" v-else>
            <span class="info-name">{{ item.name }}</span>
            <div class="info-trade-up" v-if="item.contractOrderType == 1">{{ $t('BuyLong') }}</div>
            <div class="info-trade-down" v-if="item.contractOrderType == 0">{{ $t('BuyLess') }}</div>
          </div>
          <div class="trade-result" v-if="appType == '2'">
            <div class="trade-result-title">{{ item.makeProfit > 0 ? $t('Gain') : $t('Loss') }}({{ item.currency2Title }})
            </div>

            <div class="trade-result-number" style="color: chartreuse;" v-if="item.makeProfit > 0">
              {{ (item.makeProfit * 1).toFixed(4) }}({{ (item.priceChange * 100).toFixed(4) }}%)
            </div>
            <div class="trade-result-number" v-else-if="item.makeProfit < 0">
              {{ (item.makeProfit * 1).toFixed(4) }}({{ (item.priceChange * 100).toFixed(4) }}%)
            </div>

            <div class="trade-result-number" v-else style="color: white;">
              {{ (item.makeProfit * 1).toFixed(4) }}({{ (item.priceChange * 100).toFixed(4) }}%)
            </div>
          </div>
          <div class="trade-result" v-else>
            <div class="trade-result-title">{{ $t('ProfitLoss') }}({{ item.currency2Title }})</div>

            <div class="trade-result-number" v-if="item.makeProfit > 0">
              {{ (item.makeProfit * 1).toFixed(4) }}({{ (item.priceChange * 100).toFixed(4) }}%)
            </div>
            <div class="trade-result-number" style="color: chartreuse;" v-else>
              {{ (item.makeProfit * 1).toFixed(4) }}({{ (item.priceChange * 100).toFixed(4) }}%)
            </div>
          </div>
          <div class="record-data-box">
            <!-- <div class="record-title">
              <div>
                
              </div>
              <div>
                保证金
              </div>
              <div>
                保证金率
              </div>
            </div>

            <div class="record-data">
              <div>{{ item.amount }}</div>
              <div>0.10</div>
              <div style="color: red;">1931.41%</div>
            </div> -->

            <div class="record-title">
              <div>
                {{ $t('OpeningPrice') }}({{ item.currency1Title }})
              </div>
              <div>
                {{ $t('ClosePositionPrice') }}
              </div>
              <div>
                {{ $t('PositionClosed') }}
              </div>
            </div>

            <div class="record-data">
              <div>{{ item.startPrice }}</div>
              <div>{{ item.endPrice }}</div>
              <div>{{ item.amount }}</div>
            </div>
            <div class="record-data" style="display: flex;
    align-items: end;
    justify-content: end;
    margin: 15px 0;">
              {{ moment(item.createTime).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useCountStore, useHuobiStore } from '@/store/index'
import moment from 'moment';
import { useI18n } from 'vue-i18n'
import { MarketData } from '@/@types/marketData'
import { showFailToast, showSuccessToast } from 'vant';
const appType = import.meta.env.VITE_APP_TYPE
const { t } = useI18n()
const huobiStore = useHuobiStore()
const store = useCountStore()


onMounted(() => {
  loadRecord()
})

const loadRecord = async () => {
  huobiStore.getMarketOrderList(1, 10)
}
</script>

<style lang="less">
.record-container {
  background-color: #0F0D2A;
  color: white;

  .record-top-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #282A39;
    padding: 10px 0px;
    padding-right: 15px;
    font-size: 16px;
    font-weight: 900;

    ul {
      display: flex;
      flex-direction: row;

      color: #616879;

      li {
        margin: 0px 10px;
      }

      .record-top-menu-curr {
        color: white;
      }
    }
  }

  .record-box {
    margin-bottom: 110px;
    padding: 0px 15px;

    .record-empty {
      width: 100%;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 80px;
      // background-color: white;

      color: white;

      .record-empty-text {
        margin-top: 10px;
        font-size: 16px;
      }
    }

    .record-list {
      .record-item {
        border-bottom: 2px solid #202433;

        .record-info {
          display: flex;
          align-items: center;
          margin: 5px 0px;

          .info-name {
            color: white;
            font-size: 20px;
            font-weight: 900;
          }

          .info-trade-up {
            display: inline-block;
            height: 26px;
            margin: 0px 10px;
            padding: 0px 5px;
            border-radius: 3px;
            color: red;
            background-color: #371A24;
          }

          .info-trade-down {
            display: inline-block;
            height: 26px;
            margin: 0px 10px;
            padding: 0px 5px;
            border-radius: 3px;
            color: greenyellow;
            background-color: #24371a;
          }
        }

        .trade-result {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0px;
          padding: 5px;
          background-color: #282A39;
          font-size: 16px;

          .trade-result-title {
            color: #90929C;
          }

          .trade-result-number {
            color: red;
          }
        }

        .record-data-box {
          .record-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 15px;
            color: #616879;
            font-size: 15px;
            font-weight: 900;

            div {
              width: 33%;
            }

            div:nth-child(1) {
              text-align: left;
            }

            div:nth-child(2) {
              text-align: center;
            }

            div:nth-child(3) {
              text-align: right;
            }
          }

          .record-data {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 15px;
            font-weight: 900;

            div {
              width: 33%;
            }

            div:nth-child(1) {
              text-align: left;
            }

            div:nth-child(2) {
              text-align: center;
            }

            div:nth-child(3) {
              text-align: right;
            }
          }

          .record-btns {
            display: flex;
            flex-direction: row;
            justify-content: end;
            margin: 15px 0px;

            button {
              width: 24%;
              height: 38px;
              background-color: #2A2E41;
              border: 0;
              color: white;
            }
          }
        }
      }
    }
  }

  //   record-box
  // record-list
  // record-item
  // record-info
}
</style>
