<template>
  <CommonHeader v-if="route.meta.showHeader" />
  <RouterView />
  <!-- <div class="customer-container" v-if="route.meta.showHeader && appType != 4" @click="store.openService()">
    <img src="@/assets/customer.d5b655c0.png" draggable="false" />
  </div> -->
  <van-overlay :show="huobiStore.loading">
    <div class="flex-center h-100vh">
      <van-loading />
    </div>
  </van-overlay>
  <van-action-sheet v-model:show="store.showLoginSheel">
    <div class="content">
      <Login />
    </div>
  </van-action-sheet>
</template>

<script setup>
import { ref, onMounted, } from 'vue'
import { useRouter, RouterView } from 'vue-router'

import CommonHeader from '@/components/CommonHeader.vue'
import Login from '@/components/Login.vue'

import { useCountStore, useHuobiStore } from '@/store'
import { userApi } from '@/apis/index'
import { useI18n } from 'vue-i18n'
import { showFailToast, showSuccessToast } from 'vant'
const appType = import.meta.env.VITE_APP_TYPE;
const route = useRoute()
const store = useCountStore()
const huobiStore = useHuobiStore()
const { t } = useI18n()
const router = useRouter()


onMounted(() => {
  var agent = getUrlParam("agent")
  var uid = getUrlParam("uid")
  store.setAgentCode(agent, uid)

  store.getBaseinfo()
})

const getUrlParam = (param) => {
  // 获取由全部参数组成的字符串。
  let query = window.location.search.substring(1);
  // 分割参数，得到每一个参数字符串组成的数组。
  let vars = query.split('&');
  // 遍历数组，得到每一个参数字符串。
  for (let i = 0; i < vars.length; i++) {
    // 分割每一个参数字符串，得到参数名和参数值组成的数组。
    var pair = vars[i].split('=');
    // 如果参数名等于传入的param，则返回该值。
    if (pair[0] == param) {
      return decodeURI(pair[1]);
    }
  }
  // 若参数不存在，则返回false。
  return false;
}
</script>

<style>
.customer-container {
  position: fixed;
  right: 0;
  top: 80%;
  margin-top: -50px;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  z-index: 90;
}

.customer-container img {
  width: 100%;
  height: 100%;
}


.f-ajc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.f-ajs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.f-ac {
  display: flex;
  align-items: center;
}

.f-a {
  display: flex;
}

:root {
  --van-radio-label-color: #fff
}
</style>
