<template>
  <div class="record-container">
    <div class="record-top-menu">
      <ul>
        <li class="record-top-menu-curr">{{ $t('Position') }}({{ huobiStore.orderList.length }})</li>
        <!-- <li>
          当前委托(0)
        </li>
        <li>
          全站成交
        </li> -->
      </ul>
      <!-- <div>
        {{ $t('CommissionRecord') }}
      </div> -->
    </div>
    <div class="record-box">
      <div class="record-empty" v-if="huobiStore.orderList.length <= 0" @click="loadRecord">
        <van-icon name="notes-o" />
        <div class="record-empty-text">{{ $t('NoData') }}</div>
      </div>
      <ul class="record-list" v-else>
        <li class="record-item" v-for="(item, index) in huobiStore.orderList">
          <div class="record-info">
            <div class="info-left">
              <span class="info-name">{{ item.name }}</span>
              <div class="info-trade-up" v-if="item.contractOrderType == 1">{{ $t('BuyLong') }}</div>
              <div class="info-trade-down" v-if="item.contractOrderType == 0">{{ $t('BuyLess') }}</div>
            </div>
            <div class="info-right">
              <span class="info-name" v-if="item.securityType == 0">{{ $t('Cross') }}</span>
              &nbsp;
              <span class="info-name" v-if="item.securityType == 1">{{ $t('Isolated') }}</span>
              <span class="info-name">{{ item.lever }}x</span>
            </div>
          </div>
          <div class="trade-result">
            <div class="trade-result-title">{{ $t('ProfitLoss') }}({{ item.currency2Title }})</div>
            <div class="trade-result-number" v-if="item.profit > 0">{{ (item.profit * 1).toFixed(4) }}({{
          (item.priceChange * 100).toFixed(4) }}%)</div>
            <div class="trade-result-number" style="color: chartreuse" v-else>{{ (item.profit * 1).toFixed(4) }}({{
          (item.priceChange * 100).toFixed(4) }}%)</div>
          </div>
          <div class="record-data-box">
            <div class="record-title">
              <div>{{ $t('OpeningPrice') }}({{ item.currency1Title }})</div>
              <div>
                {{ $t('CurrentPrice') }}
              </div>
              <div>
                {{ $t('PositionQuantity') }}
              </div>
            </div>

            <div class="record-data">
              <div>{{ item.startPrice }}</div>
              <div>{{ item.close }}</div>
              <div>{{ item.amount }}</div>
            </div>

            <div class="record-title">
              <div>
                {{ $t('Security') }}
              </div>
              <div>
                {{ $t('SecurityRate') }}
              </div>
              <div>
                {{ $t('Handling') }}
              </div>
            </div>

            <div class="record-data">
              <div>{{ item.security }}</div>
              <div v-if="item.securityRate > 50" style="color: chartreuse">{{ item.securityRate.toFixed(4) }}%</div>
              <div v-else style="color: red">{{ item.securityRate.toFixed(4) }}%</div>
              <div>{{ item.handling }}</div>
            </div>
            <div class="record-btns">
              <!-- <van-button type="default">调整杠杆</van-button>
              <van-button type="default">止盈止损</van-button>
              <van-button type="default">反手</van-button> -->
              <van-button type="default" @click="closePosition(item.id)">{{ $t('ClosePosition') }}</van-button>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <van-dialog v-model:show="showResult" :confirmButtonText="t('Confirm')">
      <div style="display: flex; justify-content: center">
        <van-icon v-if="orderResult?.profitStatus == 1" name="success" style="color: #07c160; font-size: 56px" />
        <van-icon v-if="orderResult?.profitStatus == 2" name="cross" style="color: #c10718; font-size: 56px" />
      </div>

      <div class="order-result-info">
        <div class="order-item">
          <div class="order-item-label">{{ t('OpeningPrice') }}:</div>
          <div class="order-item-value">{{ orderResult?.startPrice.toFixed(4) }}</div>
        </div>
        <div class="order-item">
          <div class="order-item-label">{{ t('ClosePositionPrice') }}:</div>
          <div class="order-item-value">{{ orderResult?.endPrice.toFixed(4) }}</div>
        </div>
        <div class="order-item">
          <div class="order-item-label">{{ t('Quote_change') }}:</div>
          <div class="order-item-value">{{ orderResult?.priceChange.toFixed(4) }}</div>
        </div>
        <div class="order-item">
          <div class="order-item-label">{{ t('income') }}:</div>
          <div class="order-item-value">{{ orderResult?.makeProfit.toFixed(4) }}</div>
        </div>
        <div class="order-item">
          <div class="order-item-label">{{ t('Handling') }}:</div>
          <div class="order-item-value">{{ orderResult?.handling.toFixed(4) }}</div>
        </div>
        <div class="order-item">
          <div class="order-item-label">{{ t('Number') }}:</div>
          <div class="order-item-value">{{ orderResult?.amount.toFixed(4) }}</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import { showFailToast, showSuccessToast } from 'vant'
import { MarketData } from '@/@types/marketData'
import { useCountStore, useHuobiStore } from '@/store/index'

const { t } = useI18n()
const huobiStore = useHuobiStore()
const store = useCountStore()
const showResult = ref(false)
const orderResult = ref()

onMounted(() => {
  loadRecord()
})

const loadRecord = async () => {
  huobiStore.getOrderList(1, 100, 0)
}

const closePosition = async (orderId) => {
  huobiStore.showLoading()
  const result = await huobiStore.closePosition(orderId)
  huobiStore.hideLoading()

  if (result.code != 0) {
    showFailToast(t(result.msg))
  } else {
    orderResult.value = result.data
    showResult.value = true
    showSuccessToast(t(result.msg))
    loadRecord()

    store.getUserinfo()
  }
}
</script>

<style lang="less">
.record-container {
  background-color: #0f0d2a;
  color: white;

  .record-top-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #282a39;
    padding: 10px 0px;
    padding-right: 15px;
    font-size: 16px;
    font-weight: 900;

    ul {
      display: flex;
      flex-direction: row;

      color: #616879;

      li {
        margin: 0px 10px;
      }

      .record-top-menu-curr {
        color: white;
      }
    }
  }

  .record-box {
    margin-bottom: 110px;
    padding: 0px 15px;

    .record-empty {
      width: 100%;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 80px;
      // background-color: white;

      color: white;

      .record-empty-text {
        margin-top: 10px;
        font-size: 16px;
      }
    }

    .record-list {
      .record-item {
        border-bottom: 2px solid #202433;

        .record-info {
          display: flex;
          align-items: center;
          margin: 5px 0px;

          justify-content: space-between;

          .info-left {
            .info-name {
              color: white;
              font-size: 20px;
              font-weight: 900;
            }

            .info-trade-up {
              display: inline-block;
              height: 26px;
              margin: 0px 10px;
              padding: 0px 5px;
              border-radius: 3px;
              color: red;
              background-color: #371a24;
            }

            .info-trade-down {
              display: inline-block;
              height: 26px;
              margin: 0px 10px;
              padding: 0px 5px;
              border-radius: 3px;
              color: greenyellow;
              background-color: #24371a;
            }
          }
        }

        .trade-result {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 10px 0px;
          padding: 5px;
          background-color: #282a39;
          font-size: 16px;

          .trade-result-title {
            color: #90929c;
          }

          .trade-result-number {
            color: red;
          }
        }

        .record-data-box {
          .record-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 15px;
            color: #616879;
            font-size: 15px;
            font-weight: 900;

            div {
              width: 33%;
            }

            div:nth-child(1) {
              text-align: left;
            }

            div:nth-child(2) {
              text-align: center;
            }

            div:nth-child(3) {
              text-align: right;
            }
          }

          .record-data {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 15px;
            font-weight: 900;

            div {
              width: 33%;
            }

            div:nth-child(1) {
              text-align: left;
            }

            div:nth-child(2) {
              text-align: center;
            }

            div:nth-child(3) {
              text-align: right;
            }
          }

          .record-btns {
            display: flex;
            flex-direction: row;
            justify-content: end;
            margin: 15px 0px;

            button {
              width: 24%;
              height: 38px;
              background-color: #2a2e41;
              border: 0;
              color: white;
            }
          }
        }
      }
    }
  }

  .order-result-info {
    .order-item {
      margin: 5px;
      padding: 12px;
      padding-bottom: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #656161;

      .order-item-label {}

      .order-item-value {}
    }
  }

  //   record-box
  // record-list
  // record-item
  // record-info
}
</style>
