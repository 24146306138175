<template>
  <van-cell-group>
    <van-field v-model="usdtUsdtAddress" rows="3" autosize label="USDT" :placeholder="$t('PleaseTypeInYourUsdtAddress')"
      type="textarea" @blur="updateData"  class="autosize"/>

    <van-field v-model="usdtEthAddress" rows="3" autosize label="ETH" :placeholder="$t('PleaseTypeInYourETHAddress')"
      type="textarea" @blur="updateData"  class="autosize"/>

    <van-field v-model="usdtBtcAddress" rows="3" autosize label="BTC" :placeholder="$t('PleaseTypeInYourBTCAddress')"
      type="textarea" @blur="updateData"  class="autosize"/>
  </van-cell-group>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useCountStore, useHuobiStore } from '@/store/index'
import { showFailToast, showToast, showLoadingToast, showSuccessToast } from 'vant'
import { useI18n } from 'vue-i18n'
import { publicApi, userApi } from '@/apis/index'

const { t } = useI18n()
const route = useRoute()
const store = useCountStore()
const huobiStore = useHuobiStore()

const usdtUsdtAddress = ref("")
const usdtEthAddress = ref("")
const usdtBtcAddress = ref("")

onMounted(() => {
  usdtUsdtAddress.value = store.usdt_address
  usdtEthAddress.value = store.usdt_eth_address
  usdtBtcAddress.value = store.usdt_btc_address
})

const updateData = () => {
  store.usdt_address = usdtUsdtAddress.value
  store.usdt_eth_address = usdtEthAddress.value
  store.usdt_btc_address = usdtBtcAddress.value
}
</script>

<style lang="less" scoped>
.upload-box {
  margin: 15px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .upload-item {
    .upload {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0px;
      padding: 5px;
      border: 1.5px dashed #848484;

      .upload-icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 80px;
      }
    }

    .upload-text {
      margin-top: 8px;
      text-align: center;
    }
  }
}
</style>
