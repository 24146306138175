import { createI18n } from 'vue-i18n'
import messages from './lang/index'


var lang = '';
var appType = import.meta.env.VITE_APP_TYPE
console.log(appType)
switch (appType) {
  default:
  case 1:
    lang = 'zh-tw';
    break;

  case 5:
    lang = 'ja';
    break;

  case 2:
    lang = 'vi';
    break;

  case 3:
    lang = 'en';
    break;

  case 4:
    lang = 'en';
    break;
}

var langDb = localStorage.getItem('LANG')
console.log(langDb, lang)
if (!langDb) {
  localStorage.setItem('LANG', lang)
}

const i18n = createI18n({
  locale: localStorage.getItem('LANG') || lang, // 设置语言类型
  legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
  globalInjection: true, // 全局注册$t方法
  messages
})

export default i18n
