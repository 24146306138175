<template>
  <div class="subtotal-container">
    <div>
      <p>{{ store.zhanghu.toFixed(2) }}</p>
      <span>{{ $t('account') }} (USDT)</span>
    </div>
    <div>
      <p>{{ store.chuxushouyi.toFixed(4) }}</p>
      <span>{{ $t('savings_income') }} {{ store.chuxushouyi }}(ETH)</span>
    </div>
    <div>
      <p>{{ store.jinrishouyi.toFixed(4) }}</p>
      <span>{{ $t('Todays_earnings') }} (ETH)</span>
    </div>
    <div>
      <p>{{ store.licaishouyi.toFixed(2) }}</p>
      <span>{{ $t('Financial_management_income') }} (USDT)</span>
    </div>
  </div>
  <div class="accounts">
    <div class="action-container">
      <div :class="usdtType === 1 ? 'active' : ''" @click="usdtType = 1">
        {{ $t('exchange') }}
      </div>
      <div v-if="appType != 1 && appType != 5" :class="usdtType === 2 ? 'active' : ''" @click="usdtType = 2">
        {{ $t('recharge') }}
      </div>
      <div :class="usdtType === 3 ? 'active' : ''" @click="usdtType = 3">
        {{ $t('extract') }}
      </div>
      <div :class="usdtType === 4 ? 'active' : ''" @click="usdtType = 4">
        {{ $t('Record') }}
      </div>
    </div>
  </div>
  <!-- 交换/提取 -->
  <div class="actions action-change" v-if="usdtType === 1">
    <div class="withdraw-container mt-10px">
      <div class="amount-left">
        <span>{{ $t('convertible_income') }} (ETH)</span>
        <span class="text-30px font-700 text-#51e7d3">{{
          store.ethAmount
        }}</span>
      </div>
      <div class="amount-input">
        <span>{{ $t('exchange_amount') }}</span>
        <div class="input-container">
          <van-field type="number" v-model="convertAmount" :placeholder="$t('Please_enter_the_exchange_amount')"
            style="background: rgb(0 0 0 / 0%); color: white">
            <template #button>
              <van-button size="small" type="primary" @click="convertAmount = store.ethAmount">{{ $t('total')
              }}</van-button>
            </template>
          </van-field>
          <!-- <input type="number" maxlength="140" v-model="convertAmount"
                                                                                                                :placeholder="$t('Please_enter_the_exchange_amount')" /> -->
          <!-- <span @click="convertAmount = store.ethAmount">{{ $t('total') }}</span> -->
        </div>
      </div>
    </div>
    <div class="action-button" @click="convertCurrency">
      {{ $t('exchange') }}
    </div>
  </div>
  <HomeRecharge :address="baseInfo.rechargeUsdtERC20Address" v-if="usdtType == 2" />
  <div class="actions action-change" v-if="usdtType === 3">
    <div class="withdraw-container mt-10px">
      <div class="amount-left">
        <span>{{ $t('Available_Balance') }} (USDT)</span>
        <span class="text-30px font-700 text-#51e7d3">{{
          store.usdtAmount
        }}</span>
      </div>
      <div class="amount-input">
        <span>{{ $t('Withdrawal_amount') }}</span>
        <div class="input-container">
          <van-field type="number" v-model="withdrawalAmount" :placeholder="$t('Please_enter_the_withdrawal_amount')"
            style="background: rgb(0 0 0 / 0%)">
            <template #button>
              <van-button size="small" type="primary" @click="withdrawalAmount = store.usdtAmount">{{ $t('total')
              }}</van-button>
            </template>
          </van-field>
        </div>
      </div>
    </div>
    <div class="action-button" @click="withdrawal">{{ $t('extract') }}</div>
  </div>
  <!-- 记录 -->
  <HomeRecord v-if="usdtType == 4" />
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { showFailToast, showSuccessToast, showToast, showDialog } from 'vant'
import { Vue3SeamlessScroll } from 'vue3-seamless-scroll'
import { useI18n } from 'vue-i18n'
import { publicApi, userApi } from '../apis/index'
import HomeRecharge from '../components/HomeRecharge.vue'
import HomeRecord from '../components/HomeRecord.vue'
import { useCountStore, useHuobiStore } from '@/store'

const { t } = useI18n()
const store = useCountStore()
const huobiStore = useHuobiStore()

const appType = import.meta.env.VITE_APP_TYPE

// loading true 显示 false 隐藏
const showLoading = ref(false)

// USDT 账户操作类型
const usdtType = ref(1)
const baseInfo = ref({
  usersData: {
    zongchanliang: 0.0,
    youxiaojiedian: 0.0,
    canyuzhe: 0,
    shouyi: 0.0
  },
  usdtAccount: {
    zhanghu: 0.0,
    chuxushouyi: 0.0,
    jinrishouyi: 0,
    licaishouyi: 0.0
  },
  yingliJilu: [],
  changjianWenti: [],
  rechargeUsdtERC20Address: '',
  gonggao: {
    title: '',
    content: ''
  }
})

// 记录
const records = ref([])

const showSystemGonggao = ref(false)
onMounted(async () => {
  showLoading.value = true
  const resultBaseinfo = await publicApi.getBaseInfo()
  if (resultBaseinfo.code == 0) {
    if (resultBaseinfo.data.gonggao) {
      showSystemGonggao.value = true
    } else {
      resultBaseinfo.data.gonggao = {
        title: '',
        content: ''
      }
    }

    baseInfo.value = resultBaseinfo.data

    console.log(baseInfo.value)

    store.setServiceUrl(resultBaseinfo.data.serviceUrl)
  }

  store.loadUserRecord(1, 5)
  updateBalance()
  showLoading.value = false
  // const result_1 = await userApi.login()

  // if (result_1.code == 0) {
  //   store.setUserinfo(result_1.data)
  //   loadRecord(1, 5)
  //   updateBalance()
  // } else {
  //   showFailToast(t(result_1.msg))
  // }
})

const showUserGonggao = ref(0)
// 更新余额
const updateBalance = () => {
  userApi.getUserInfo().then((result) => {
    showLoading.value = false
    if (result.code === 0) {
      store.updateUserinfo(result.data)
      baseInfo.value.usdtAccount = result.data.usdtAccount

      if (result.data.gonggao && showUserGonggao.value == 0) {
        showDialog({
          title: result.data.gonggao.title,
          message: result.data.gonggao.content,
          confirmButtonText: t('Confirm')
        }).then(() => {
          showUserGonggao.value = 1
        })
      }

      setTimeout(() => {
        updateBalance()
      }, 10000) // 设定10秒更新一次余额
    }
  })
}



const router = useRouter()


// ETH兑换USDT
const convertAmount = ref('')
const convertCurrency = () => {
  if (!checkedAuthentication()) {
    return
  }

  showLoading.value = true
  userApi.convertCurrency(convertAmount.value).then((result) => {
    showLoading.value = false
    if (result.code == 0) {
      showSuccessToast(t(result.msg))
    } else {
      showFailToast(t(result.msg))
    }
  })
}



//  提取USDT
const withdrawalAmount = ref('')
const withdrawal = () => {
  if (!checkedAuthentication()) {
    return
  }

  if (appType != '3' && !store.bankName) {
    showDialog({
      message: t('PleaseBindYourBankCardBeforeWithdrawingMoney'),
      confirmButtonText: t('Confirm')
    }).then(() => {
      goPage('HomeBankCard')
    })
    return
  }
  if (appType == '3' && !store.usdtERC20) {
    showDialog({
      message: t('PleaseBindYourBankCardBeforeWithdrawingMoney'),
      confirmButtonText: t('Confirm')
    }).then(() => {
      goPage('HomeBankCard')
    })
    return
  }

  showLoading.value = true
  userApi.withdrawal(withdrawalAmount.value).then((result) => {
    showLoading.value = false
    console.log(result)
    if (result.code == 0) {
      showSuccessToast(t(result.msg))
    } else {
      showFailToast(t(result.msg))
    }
  })
}

/**
 * 检查是否通过身份验证
 */
const checkedAuthentication = () => {
  const appType = import.meta.env.VITE_APP_TYPE
  if (appType == '2') {
    return true
  }
  if (store.authentication != '4') {
    showFailToast(t('PleaseCompleteTheIdentityAuthenticationFirst'))
    goPage('IdEntity')
    return false
  }

  return true
}
</script>
<style lang="scss" scoped>
.subtotal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #1b2130;
  margin: 10px 0;

  &>div {
    width: 50%;
    box-sizing: border-box;
    text-align: center;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>p {
      display: block;
      color: #51e7d3;
      font-weight: 600;
      font-size: 18px;
    }

    &>span {
      font-size: 12px;
      font-weight: 400;
      color: #fff;
    }
  }
}

.accounts {
  .action-container {
    background-color: #1b2130;
    border-radius: 4px;
    height: 46px;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    &>div {
      flex: 1;
      text-align: center;
    }

    &>.active {
      background-color: #51e7d3;
      color: #1b2130;
      font-size: 15px;
      border-radius: 4px;
      height: 46px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.amount-left {
  height: 100px;
  width: 100%;
  background-image: url(@/assets/bg-withdraw.ea1f3478.png);
  background-size: cover;
  background-position: 50%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 30px;
  box-sizing: border-box;
}

.amount-input {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0 30px;
  box-sizing: border-box;
  background-color: #1b2130;
  height: 96px;
  border-radius: 4px;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;

  input {
    flex: 2;
    color: #898b98;
    font-size: 14px;
    outline: none;
    border: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
  }

  span {
    flex: 1;
    color: #51e7d3;
    font-size: 12px;
    font-weight: 700;
    text-align: right;
  }
}


.incomes-container {
  background-color: #1b2130;
  border-radius: 4px;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  top: 0;
  -webkit-animation: scrollText1 8s infinite cubic-bezier(1, 0, 0.5, 0);
  animation: scrollText1 8s infinite cubic-bezier(1, 0, 0.5, 0);
}

.faqs-container {
  background-color: #1b2130;
  padding: 5px;
  margin-top: 10px;
  border-radius: 4px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  width: 100%;
  display: block;
}

.faqs-item {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
  align-items: stretch;
  background-color: #0b1222;
  margin: 5px 0;
  border-radius: 4px;
}

.faqs-content {
  overflow: hidden;
  height: 0;

  &.active {
    height: auto;
    transition: height 400ms ease-in-out 0ms,
      -webkit-transform 400ms ease-in-out 0ms, transform 400ms ease-in-out 0ms;
    transform-origin: 50% 50% 0px;
  }

  &>.content-text {
    padding: 12px 15px;
    color: #606266;
    font-size: 14px;
    line-height: 18px;
  }
}

.notice-box {
  width: 500px;
  height: 30px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  border: 1px solid red;
}

.notice-item {
  width: 100%;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
}

.notice-slide-enter-active,
.notice-slide-leave-active {
  transition: all 0.8s linear;
}

.notice-slide-enter {
  top: 30px;
}

.notice-slide-leave-to {
  top: -30px;
}
</style>
