<template>
  <van-nav-bar left-arrow :title="$t('QuickRecharge')" @click-left="onClickLeft"  class="left-arrow"/>
  <div class="px-15px mt-15px">
    <div class="recharge-info">
      <van-config-provider theme="dark">
        <van-radio-group class="recharge-type" direction="horizontal" v-model:model-value="store.rechargeType">
          <van-radio name="1">USDT/ETH</van-radio>
          <van-radio name="2">USDT/TRC</van-radio>
          <van-radio name="3">BTC</van-radio>
        </van-radio-group>
      </van-config-provider>
      <template v-if="store.rechargeType == '1'">
        <QRCodeVue3 :width="200" :height="200" :value="store.rechargeUsdtERC20Address"
          :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }" :dotsOptions="{
    type: 'dots',
    color: '#26249a',
    gradient: {
      type: 'linear',
      rotation: 0,
      colorStops: [
        { offset: 0, color: '#26249a' },
        { offset: 1, color: '#26249a' }
      ]
    }
  }" :backgroundOptions="{ color: '#ffffff' }" :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
          :cornersDotOptions="{ type: undefined, color: '#000000' }" fileExt="png" />

        <van-field class="recharge-address autosize readonly" v-model="store.rechargeUsdtERC20Address" rows="1" autosize type="textarea"
          readonly />
      </template>
      <template v-else-if="store.rechargeType == '2'">
        <QRCodeVue3 :width="200" :height="200" :value="store.rechargeUsdtTRC20Address"
          :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }" :dotsOptions="{
    type: 'dots',
    color: '#26249a',
    gradient: {
      type: 'linear',
      rotation: 0,
      colorStops: [
        { offset: 0, color: '#26249a' },
        { offset: 1, color: '#26249a' }
      ]
    }
  }" :backgroundOptions="{ color: '#ffffff' }" :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
          :cornersDotOptions="{ type: undefined, color: '#000000' }" fileExt="png" />

        <van-field class="recharge-address autosize readonly" v-model="store.rechargeUsdtTRC20Address" rows="1" autosize type="textarea"
          readonly />
      </template>
      <template v-else>
        <QRCodeVue3 :width="200" :height="200" :value="store.rechargeUsdtOmniAddress"
          :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }" :dotsOptions="{
    type: 'dots',
    color: '#26249a',
    gradient: {
      type: 'linear',
      rotation: 0,
      colorStops: [
        { offset: 0, color: '#26249a' },
        { offset: 1, color: '#26249a' }
      ]
    }
  }" :backgroundOptions="{ color: '#ffffff' }" :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
          :cornersDotOptions="{ type: undefined, color: '#000000' }" fileExt="png" />

        <van-field class="recharge-address autosize readonly" v-model="store.rechargeUsdtOmniAddress" rows="1" autosize type="textarea"
          readonly />
      </template>
      <van-button type="warning" class="copyRechargeUsdtERC20Address" @click="copyText">
        {{ $t('CopyRechargeAddress') }}
      </van-button>
    </div>

    <div class="recharge-order-info">
      <van-field class="recharge-number autosize" v-model="store.rechargeNumber" autosize type="number"
        :placeholder="$t('PleaseEnterTheRechargeAmount')" />

      <div style="width: 90%; display: flex; flex-direction: column" class="">
        <span style="margin: 10px 0px; font-size: 16px">{{ $t('TransferVoucher') }}</span>
        <van-uploader class="upload" :before-read="beforeRead" :after-read="uploadImg">
          <img v-if="store.rechargeImg" :src="baseUrl + store.rechargeImg" style="width: 80px; height: 80px" />
          <van-icon v-else name="photo" class="upload-icon" />
        </van-uploader>
      </div>

      <van-button type="warning" class="btn-submit-order-info" @click="submitOrderInfo">
        {{ $t('Submit') }}
      </van-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { showFailToast, showSuccessToast } from 'vant/lib/toast'
import QRCodeVue3 from 'qrcode-vue3'
import Clipboard from 'clipboard'
import { useCountStore, useHuobiStore } from '@/store'
import { publicApi } from '@/apis'

const router = useRouter()
const store = useCountStore()
const huobiStore = useHuobiStore()

const { t } = useI18n()

const onClickLeft = () => {
  router.push({
    name: 'HomeIndex'
  })
}
const baseUrl = ref('')
const tradeImg = ref('')

const uploadImg = (result) => {
  console.log(result)
}
const beforeRead = async (file) => {
  const result = await publicApi.uploadImg(file)
  if (result.data.code == 0) {
    store.rechargeImg = result.data.data.fileName
  }

  return true
}

/**
 * 提交充值订单信息
 */
const submitOrderInfo = async () => {
  huobiStore.loading = true
  const result = await store.submitRechargeInfo()
  huobiStore.loading = false

  if (result.code != 0) {
    showFailToast(t(result.msg))
    return
  }

  showSuccessToast(t(result.msg))
}

onMounted(() => {
  baseUrl.value = `${import.meta.env.VITE_APP_AXIOS_BASE_URL}wwwroot`
})

const copyText = () => {
  try {
    let address = ''
    switch (store.rechargeType) {
      case '1':
      default:
        address = store.rechargeUsdtERC20Address
        break

      case '2':
        address = store.rechargeUsdtTRC20Address
        break

      case '3':
        address = store.rechargeUsdtOmniAddress
        break
    }

    const clipboard = new Clipboard('.copyRechargeUsdtERC20Address', {
      text: () => {
        return address
      }
    })

    clipboard.on('success', () => {
      showSuccessToast(t('CopiedSuccessfully'))
    })

    clipboard.on('error', () => {
      showFailToast(t('CopyFailed'))
    })
  } catch (error) {
    console.log(error)
  }
}
</script>

<style lang="scss" scoped>
.recharge-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .recharge-type {
    margin: 15px 0px;
  }

  .recharge-address {
    width: 90%;
    margin: 15px 0px;
  }

  .copyRechargeUsdtERC20Address {
    width: 90%;
  }
}

.recharge-order-info {
  margin: 25px 0px;
  padding: 25px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // border: 1px dotted red;
  // border-radius: 15px;

  .recharge-number {
    width: 90%;
    margin: 15px 0px;
  }

  .upload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 5px;
    border: 1.5px dashed #848484;

    .upload-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 80px;
    }
  }

  .btn-submit-order-info {
    width: 90%;
    margin: 15px 0px;
  }
}
</style>
