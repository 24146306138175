<template>
  <div class="contract-container">
    <div v-if="appType != '2'">
      <van-search shape="round" background="#0F0D2A" :placeholder="$t('PleaseEnterTheSearchKeyword')" />
    </div>
    <div class="market-title" v-if="appType != '2'">
      <div>{{ $t('Currency') }}/{{ $t('TradingVolume') }}</div>
      <div>{{ $t('LatestPrice') }}/{{ $t('Quote_change') }}</div>
    </div>
    <div class="market-list">
      <div class="market-item" @click="selectContractById(item.id)" v-for="(item, index) in huobiStore.contractList">
        <div class="coin-info">
          <div class="coin-name">{{ item.name }}</div>
          <div class="coin-price">{{ item.close }}</div>
        </div>
        <div class="coin-market">
          <div class="coin-price-amount">{{ item.vol }}</div>
          <div class="coin-price-change">{{ item.priceChange }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useCountStore, useHuobiStore } from '@/store'
const appType = import.meta.env.VITE_APP_TYPE
const huobiStore = useHuobiStore()

const selectContractById = (id) => {
  huobiStore.selectContractById(id)

  huobiStore.showMarketSheet = false
}


</script>

<style lang="scss" scoped>
.contract-container {
  background-color: #171827;
  border: 0px;
  min-height: 300px;

  .market-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 15px;
    color: #3B4051;
    font-size: 16px;
  }


  .market-list {
    .market-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 10px 20px;
      border-bottom: 1px solid #3B4051;


      .coin-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .coin-name {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 700;
          margin: 10px 0px;
        }

        .coin-price {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 700;
        }
      }

      .coin-market {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .coin-price-amount {
          display: flex;
          align-items: center;
          color: #686D81;
        }

        .coin-price-change {
          display: flex;
          align-items: center;
          color: red;
        }
      }
    }
  }
}
</style>