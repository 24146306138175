<template>
  <div class="contract-order-container">
    <div class="btns-box">
      <van-button type="danger" v-bind:class="{ btnNotSelect: huobiStore.tradeType == 'sell' }"
        @click="huobiStore.selectTradeType('buy')">{{ $t('BuyLong') }}</van-button>
      <div class="btn-space"></div>
      <van-button type="success" v-bind:class="{ btnNotSelect: huobiStore.tradeType == 'buy' }"
        @click="huobiStore.selectTradeType('sell')">{{ $t('BuyLess') }}</van-button>
    </div>

    <!-- <div class="trade-type-box">
      <ul>
        <li>市价</li>
      </ul>
    </div> -->

    <div class="trade-info-box">
      <div class="trade-info-item trade-security-type">
        <div>

        </div>
      </div>
      <div class="trade-info-item trade-title">
        <div>{{ $t('Available_Balance') }}</div>
        <div>{{ store.usdtAmount }} {{ huobiStore.kLineType1 }}</div>
      </div>
      <!-- <div class="trade-info-item trade-title">
        <div>保证金</div>
        <div>0 {{ huobiStore.kLineType1 }}</div>
      </div> -->

      <div class="trade-info-item">
        <van-space direction="vertical" fill class="fill">
          <van-field :label="$t('Number')" label-class="input-label" class="trade-info-input"
            v-model="huobiStore.orderNumber">
            <template #extra class="_wn_extra">
              <div class="input-label">{{ huobiStore.kLineType1 }}</div>
            </template>

          </van-field>
          <!-- <van-field label="数量" class="trade-info-input">
            <template #extra class="_wn_extra">
              <div>ETH</div>
            </template>
          </van-field> -->
        </van-space>

      </div>

      <div class="trade-info-item" style="padding: 0px 15px;">
        <van-slider v-model="huobiStore.orderNumber" :min="0" :max="store.usdtAmount">
        </van-slider>
      </div>

      <!-- <div class="trade-info-item trade-title">
        <div>最大可买</div>
        <div>0 ETH</div>
      </div> -->

      <div class="trade-info-item" style="margin-top: 40px;">
        <van-button type="danger" block v-if="huobiStore.tradeType == 'buy'" @click="trade" class="block">
          {{ $t('BuyLong') }}
        </van-button>
        <van-button type="success" block v-if="huobiStore.tradeType == 'sell'" @click="trade" class="block">
          {{ $t('BuyLess') }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { showFailToast, showToast, showLoadingToast, showSuccessToast } from 'vant'
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useCountStore, useHuobiStore } from '@/store'

const { t } = useI18n()
const router = useRouter()
const store = useCountStore()
const huobiStore = useHuobiStore()

const numberSlider = ref(0)

const trade = async () => {
  if (!checkedAuthentication()) {
    return
  }

  huobiStore.showLoading()
  let result = await huobiStore.contractTrade(true)
  huobiStore.hideLoading()

  if (result.code != 0) {
    showFailToast(t(result.msg))
  } else {
    showSuccessToast(t(result.msg))

    store.getUserinfo()
  }
}



/**
 * 检查是否通过身份验证
 */
const checkedAuthentication = () => {
  const appType = import.meta.env.VITE_APP_TYPE
  if (appType == '2') {
    return true
  }

  if (store.authentication != '4') {
    showFailToast(t('PleaseCompleteTheIdentityAuthenticationFirst'))
    window.scrollTo(0, 0)
    router.push({
      name: "IdEntity"
    })
    return false
  }

  return true
}
</script>

<style lang="scss" scoped>
.contract-order-container {
  background-color: #171827;
  border: 0px;
  min-height: 300px;
  padding: 15px;
  font-size: 16px;
  color: #989FB4;

  .btns-box {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      width: 49%;
    }

    .btnNotSelect {
      background-color: #202433;
      border: 0px;
    }

    //     .btn-space{
    // width: ;
    //     }
  }

  .trade-info-item {
    margin: 25px 0px;

    .input-label {
      color: white;
    }

    .trade-info-input {
      background-color: #242B3A;
      border-radius: 8px;
      --van-field-label-color: white;
      --van-field-input-text-color: white;
    }
  }

  .trade-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.van-field-label-color {
  color: white;
}
</style>