import en from './en'
import zhcn from './zh-cn'
import zhtw from './zh-tw'
import ja from './ja'
import ko from './ko'
import vi from './vi'

export default {
  en: {
    ...en // 添加对应的key
  },
  'zh-cn': {
    ...zhcn
  },
  'zh-tw': {
    ...zhtw
  },
  ja: {
    ...ja
  },
  ko: {
    ...ko
  },
  vi: {
    ...vi
  }
}
