export default {
  Chinese: '简体中文',
  TraditionalChinese: '繁體中文',
  English: 'English',
  Japanese: 'Japanese',
  Vietnamese: 'Tiếng Việt',
  Asset_Management_Savings_Bank: '資產銀行儲蓄',
  Bank_Account: '銀行賬戶',
  Participate_in_savings: '參與儲蓄',
  Selected_financial_management: '精選理財',
  user_pool: '用戶池',
  account: '賬戶',
  total_output: '總產量',
  Valid_node: '有效節點',
  participant: '參與者',
  income: '收益',
  savings_income: '儲蓄收益',
  Todays_earnings: '今日收益',
  Financial_management_income: '理財收益',
  exchange: '交換',
  recharge: '充值',
  extract: '提取',
  Record: '記錄',
  convertible_income: '可兌換收益',
  exchange_amount: '交換金額',
  Please_enter_the_exchange_amount: '請輸入交換金額',
  total: '全部',
  Available_Balance: '可用餘額',
  Withdrawal_amount: '提款餘額',
  Please_enter_the_withdrawal_amount: '請輸入提款金額',
  Profit_record: '盈利記錄',
  address: '地址',
  number: '數字',
  common_problem: '常見問題',
  Previous_page: '上一頁',
  Next_page: '下一頁',
  Audit_Report: '審計報告',
  We_have_a_security_audit_report: '我們有一份安全的審計報告',
  partner: '夥伴',
  our_business_partners: '我們的商業夥伴',
  expenditure: '支出',
  amount: '賬戶',
  remark: '備注',
  bankNumberName: '賬戶名',
  Quote_change: '漲跌幅',
  Highest: '最高',
  My_Order: '我的訂單',
  Buy: '購買',
  Kline_chart: 'K綫圖',
  Please_select_contract_time: '請選擇合約時間',
  purchasing_price: '購買金額',
  Please_enter_the_purchase_amount: '請輸入購買金額',
  savings: '儲蓄單位',
  Financial_institution_name: '金融機構名稱',
  Please_enter: '請輸入',
  Branch_name: '分店名稱',
  branch_number: '分行號',
  zhanghao: '帳號',
  Account_holder: '賬戶持有人',
  UsdtERC20Address: 'USDT ERC20 地址',
  binding: '綁定',
  Invite_friends: '邀請朋友',
  CopiedSuccessfully: '複製成功',
  Copy_failed: '複製失敗',
  Please_enter_the_amount: '請輸入金額',
  Select_storage_period: '選擇存儲周期',
  money: '金額',
  Daily_rate_of_return: '日收益率',
  savings_products: '儲蓄產品',
  Currency: '幣種',
  Saving_amount: '儲蓄金額',
  PleaseEnterTheCorrectAmount: '請輸入正確金額',
  NotOpenYet: '暫未開放',
  Earnings: '收入',
  Expenses: '支出',
  Confirm: '確定',
  Cancel: '取消',
  Copy: '複製',
  RechargeAmount: '充值數量',
  PleaseEnterTheRechargeAmount: '請輸入充值數量',
  RechargeERC20Address: 'USDT ERC20充值地址',
  Submit: '提交',

  YourAccountHasBeenDisabled: '您的賬戶已禁用!',
  InformationUpdatedSuccessfully: '信息更新成功',
  YourAccountIsFrozen: '您的賬戶已凍結!',
  ProductDoesNotExist: '產品不存在',
  ThePurchaseAmountMustBeLessThanTheProductAmount: '購買金額必須小于產品金額',
  InsufficientAccountBalance: '賬戶餘額不足',
  WithdrawalRequestHasBeenSubmitted: '提現請求已提交',
  RechargeRequestHasBeenSubmitted: '充值請求已提交',
  AmountEnteredIncorrectly: '輸入的金額錯誤!',

  Successfully: '成功',
  Day: '天',
  BuyFailed: '購買失敗',
  BuySuccessfully: '購買成功',

  Withdrawal: '提現',
  PurchaseProduct: '購買產品',
  RegistrationTrialBonus: '註冊體驗金',
  CurrencyConversion: '貨幣轉換',
  SavingsIncome: '儲蓄收益',
  FinancialIncome: '理財收益',
  SavingsRelease: '儲蓄釋放',
  BindingSuccessful: '綁定成功',
  ViewThisAccount: '查看此帳戶',
  NoWalletEnv: '請使用錢包瀏覽器開啟本系統!',
  PleaseBindYourBankCardBeforeWithdrawingMoney: '請綁定銀行卡後提現!',
  天: '天',
  周: '週',
  月: '月',
  年: '年',
  ContractTrading: '合約交易',
  '1Min': '1分鐘',
  '5Min': '5分鐘',
  '1Hour': '1小時',
  '1Day': '1天',

  Loading: '載入中',
  ContractTimeError: '合約時間錯誤',
  ContractTypeError: '合約類型錯誤',
  ContractNotSupported: '合約不支持',
  YouHaveAContractOrderInProgressButTheOrderFailed: '您有合約訂單正在進行中，建立訂單失敗',
  NotOrder: '沒有訂單',
  PleaseSelectContractTime: '請選擇合約時間',
  PleaseEnterThePurchaseAmount: '請輸入購買金額',
  BuyLong: '買多',
  BuyLess: '買少',
  PurchasingPrice: '購買金額',
  Gain: '獲得',
  Loss: '損失',
  Ok: '確定',
  ContractOrder: '合約下單',
  ContractPrincipal: '合約本金',
  WithdrawalReturn: '提款返回',

  OrderStatusError: '訂單狀態錯誤!',
  HighestPrice: '最高價格',
  LowestPrice: '最低價格',
  TradingVolume: '交易量',
  TransactionVolume: '貿易比率',
  Position: '從屬地位',
  CommissionRecord: '佣金記錄',
  ProfitLoss: '利潤損失',
  OpeningPrice: '開盤價',
  CurrentPrice: '時價',
  PositionQuantity: '持倉數量',
  ClosePosition: '平倉',
  Service: '服務',
  ContractList: '合約清單',
  LatestPrice: '最新價格',
  PleaseEnterTheSearchKeyword: '請輸入搜尋關鍵字',
  Number: '數位',
  NoData: '沒有數據',
  Price: '價格',

  TradeBuy: '买入',
  TradeSell: '卖出',
  Security: '保证金',
  Lever: '合约杠杆',
  Chart: '图表',
  Trading: '盘口',
  Cross: '全仓',
  Isolated: '逐仓',
  WalletLogin: '钱包登录',
  Username: '用户名',
  Password: '密码',
  ConfirPassword: '確認密碼',
  PleaseEnterTheUsername: '请输入用户名!',
  PleaseEnterThePassword: '请输入密码!',
  PleaseEnterTheConfirPassword: '请输入确认密码!',
  Logout: '退出登录',
  ConfirPasswordError: '确认密码错误!',
  UsernameAlreadyExists: '用户名已存在!',
  InsufficientAccountSecurity: '账户保证金不足!',
  AlreadyHaveAnAccountClickToLogIn: '已經有帳號? 點選登錄',
  DontHaveAnAccountYetClickToRegister: '還沒有帳號? 點選註冊',
  PleaseCompleteTheIdentityAuthenticationFirst: '請先完成身份認證!',
  AuthenticationBindingSuccessful: '身份驗證綁定成功!',
  WaitingReview: '等待驗證!',
  Next: '下一步',
  Jump: '跳過',
  RegisterAccount: '註冊帳戶',
  Authentication: '身份驗證',
  SecureBinding: '安全綁定',
  Finish: '完成',

  Name: '姓名',
  PleaseTypeInYourName: '請輸入姓名',
  IdNumber: '身分證號',
  PleaseTypeInYourIdNumber: '請輸入身分證號',
  FrontOfDocument: '證件正面',
  ReverseSideOfId: '證件反面',
  HoldingIdPhoto: '手持證件照',
  UsdtAddress: 'Usdt地址',
  Failed: '失敗',
  ClosePositionPrice: '平倉價格',
  PositionClosed: '已平倉',

  QuickRecharge: '快速儲值',
  PleaseTypeInYourUsdtAddress: '請輸入您的Usdt地址',
  CopyRechargeAddress: '複製儲值地址',
  Login: '登入',
  Email: '郵件',
  PleaseEnterTheEmail: '請輸入您的郵件地址',

  PleaseEnterTheVerifyCode: '請輸入您的信箱驗證碼',
  EmailCode: '信箱驗證碼',
  GetCode: '取得驗證碼',
  SwitchLanguage: '選擇語言',
  RegisterType: '註冊類型',
  RegisterSuccess: '註冊成功',
  PasswordError: '密碼錯誤',

  PleaseEnterTheTransferAddress: '請輸入您的轉帳地址',
  TransferVoucher: '轉帳憑證',
  OpenPosition: '開倉',
  BuyToOpenLong: '買進開多',
  SellToOpenShort: '賣出開空',
  BuyShort: '賣平多',
  SellLong: '買入平空',
  Kepingduo: '可平多',
  Kepingkong: '可平空',

  PleaseTypeInYourETHAddress: '請輸入您的ETH地址',
  PleaseTypeInYourBTCAddress: '請輸入您的BTC地址',
  Save: '儲存',
  SecurityRate: '保證金費率',
  Handling: '手續費',
  Zhang: '張',
  SecurityUnfreeze: '安全解凍',
  AccountError: '帳戶錯誤!',

  jiaoyileixing: '交易類型：',
  Account_holder_jiaming: '帳戶名稱（假名）',
  Close: '關閉'
}
