<template>
  <van-dialog v-model:show="huobiStore.showCreateMarketOrder" :show-confirm-button=false :show-cancel-button=false class="_cl_show-confirm-button false _cl_show-cancel-button false">
    <div style="display: flex;justify-content: end;margin-top: 15px;margin-right: 15px;">
      <div class="closeBtnBox" @click=" huobiStore.showCreateMarketOrder = false">X</div>
    </div>
    <div class="buyBox">
      <div class="buyContent">
        <div class="buySelectTime">{{ $t('PleaseSelectContractTime') }}:</div>
        <div class="buyList">
          <div @click="huobiStore.marketTimeId = buyItem.id" class="buyItem "
            v-bind:class="{ buyItemActive: huobiStore.marketTimeId == buyItem.id }" v-for="buyItem in buyList">
            <div>{{ buyItem.time }}s</div>
            <div>{{ buyItem.profit.toFixed(2) }}%</div>
          </div>
        </div>
        <div style="margin: 10px 0px;">{{ $t('PurchasingPrice') }}:</div>
        <div style="border-top: 1px solid #6767674a; border-bottom: 1px solid #6767674a;">
          <van-cell-group inset class="inset">
            <!-- 允许输入数字，调起带符号的纯数字键盘 -->
            <van-field v-model="huobiStore.orderNumber" type="number" :placeholder="$t('PleaseEnterThePurchaseAmount')" />
          </van-cell-group>
        </div>

        <div class="buyBtnBox">
          <van-button type="success" class="buyBtn" @click="buy(1)">{{ $t('BuyLong') }}</van-button>
          <van-button type="danger" class="buyBtn" @click="buy(0)">{{ $t('BuyLess') }}</van-button>
        </div>
      </div>
    </div>
  </van-dialog>

  <van-dialog
    style="top: 60%; z-index: 9999; width: 100vw; text-align: center; height: 100vw;display: flex;align-items: center;justify-content: center;"
    :overlay="false" v-model:show="showTimeLoading" :show-confirm-button=false :show-cancel-button=false class="_cl_show-confirm-button false _cl_show-cancel-button false">
    <van-circle v-model:current-rate="currentRote" :rate="currentTime" :speed="0" :text="currentTimeTxt + ''"
      :size="circleSize" :clockwise="false" />
  </van-dialog>

  <van-dialog v-model:show="showOrderResult" :show-cancel-button='false' :confirm-button-text="$t('Ok')">
    <div
      style="display: flex;flex-direction: column;justify-content: center;align-content: center;align-items: center; margin: 15px 0px;">
      <div style="font-size: 36px;" v-if="orderProfitStatus == 1">{{ $t('Gain') }}</div>
      <div style="font-size: 36px;" v-if="orderProfitStatus == 2">{{ $t('Loss') }}</div>
      <div>{{ orderProfit.toFixed(4) }}</div>
    </div>
  </van-dialog>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { showFailToast, showToast, showLoadingToast } from 'vant'
import { publicApi, userApi } from '../apis/index'
import moment from 'moment'
import { useCountStore, useHuobiStore } from '@/store/index'
import user from '@/apis/modules/user'
import { store } from '@/utils/const'

const { t } = useI18n()
const huobiStore = useHuobiStore()
const userStore = useCountStore()

const showTimeLoading = ref(false)

const buyList = ref([
  {
    id: 1,
    time: 60,
    profit: 15
  },
  {
    id: 2,
    time: 120,
    profit: 25
  },
  {
    id: 3,
    time: 240,
    profit: 35
  }
])
const orderId = ref<Number>(0);
const circleSize = ref(0)
const buyItemSelect = ref(null)
const buyTime = ref(60)
const currentTime = ref(0)
const currentRote = ref(0)
const currentTimeTxt = ref("")
const endTime = ref(null)

const getOrderAction = ref(false)
const showOrderResult = ref(false)
const orderProfitStatus = ref(0)
const orderProfit = ref(0)

const calcTime = () => {
  let nowTime = moment().unix();
  let shengyu = endTime.value - nowTime;
  if (shengyu >= 0) {
    if (shengyu <= 0) {
      currentTimeTxt.value = '0'
      currentTime.value = 0
    } else {
      currentTimeTxt.value = shengyu + ''
      currentTime.value = (shengyu / buyTime.value) * 100

      huobiStore.showCreateMarketOrder = false
    }
  }


  if (shengyu < 0 && orderId.value != 0 && !getOrderAction.value) {
    getOrderAction.value = true
    showTimeLoading.value = false
    setTimeout(() => {
      userApi.getContractOrderInfo(orderId.value).then(result => {
        if (result.code == 0) {

          showTimeLoading.value = false

          showOrderResult.value = true
          getOrderAction.value = false
          orderProfitStatus.value = result.data.profitStatus
          orderProfit.value = result.data.makeProfit
          orderId.value = 0
        }
      })
      setTimeout(() => {
        huobiStore.getMarketOrderList(1, 10)
        userStore.getUserinfo()
      }, 500)
    }, 500)
  }
}

async function buy(type) {
  if (!huobiStore.marketTimeId) {
    showToast(t('PleaseSelectContractTime'));
    return
  }

  if (!huobiStore.orderNumber) {
    showToast(t('PleaseEnterThePurchaseAmount'));
    return
  }

  var loading = showLoadingToast({
    message: t('Loading'),
    forbidClick: true,
    duration: 0
  });

  huobiStore.marketContractType = type
  var result = await huobiStore.createMarketOrder()
  loading.close()
  console.log(result)

  if (result.code != 0) {
    showFailToast(t(result.msg))
    return;
  }

  showLoadingOrder(result.data)
}

/**
 * 显示订单倒计时
 * @param orderInfo 
 */
const showLoadingOrder = (orderInfo) => {
  orderId.value = orderInfo.id
  buyTime.value = orderInfo.contractTime
  let endTimeUnix = orderInfo.endTimeStamp;
  endTime.value = endTimeUnix
  let nowTime = moment(Date()).unix();

  console.log("endTimeUnix", endTimeUnix)
  console.log("nowTime", nowTime)
  console.log("nowTimeServer", orderInfo.endTimeStamp)
  let shengyu = endTimeUnix - nowTime
  console.log("shengyu", shengyu)
  currentTimeTxt.value = shengyu + ''
  if (shengyu >= 1) {
    showTimeLoading.value = true
  }
}


onMounted(() => {
  setInterval(() => {
    calcTime()
  }, 1000)

  circleSize.value = document.body.clientWidth * 0.7

  userApi.getCurrentContractOrder().then(orderResult => {
    if (orderResult.code == 0) {
      if (orderResult.data.contractOrderStatus == 0) {
        showLoadingOrder(orderResult.data)
      }
    }
  })
})
</script>

<style lang="less">
.buyBox {
  // display: flex;
  // flex-direction: row;
  // justify-content: end;

  .closeBtnBox {
    display: flex;
    width: 40px;
    height: 40px;
    margin: 10px;
    align-items: center;
    justify-content: center;


    .closeBtn {
      display: flex;
      width: 40px;
      height: 40px;
      margin: 10px;
      align-items: center;
      justify-content: center;
    }
  }

  .buyContent {
    padding: 0px 15px;

    .buySelectTime {
      margin: 5px 0px;
    }

    .buyList {
      display: flex;
      justify-content: space-around;
      margin: 15px 0px;

      .buyItem {
        width: 80px;
        height: 100px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        background-color: #dcdcdc;
        border-radius: 10px;
      }

      .buyItemActive {
        background-color: aqua;
      }
    }

    .buyBtnBox {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin: 15px 0px;

      .buyBtn {
        width: 120px;
      }
    }
  }
}
</style>
