<template>
  <van-nav-bar left-arrow :title="$t('My_Order')" @click-left="onClickLeft"  class="left-arrow"/>
  <div class="px-15px mt-15px">
    <van-card num="2" price="2.00" desc="描述信息" title="商品标题"
      thumb="https://fastly.jsdelivr.net/npm/@vant/assets/ipad.jpeg" />
  </div>
</template>

<script setup lang="ts">
const onClickLeft = () => window?.history.back()
</script>

<style lang="scss" scoped></style>
