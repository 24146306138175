<template>
  <van-loading v-if="store.userRecordLoading" />
  <!-- 记录 -->
  <div class="actions action-record flex w-full">
    <div class="p-10px records-container w-full">
      <div class="text-center py-10px" v-if="store.userRecord && store.userRecord.data.length">
        <div v-for="(item, index) in store.userRecord.data" class="text-12px" :key="index" style="
                              margin: 15px 0px;
                              padding: 10px 0px;
                              border-bottom: 1px solid;
                            ">
          <div class="flex justify-between" v-if="item.recordType == 3">

            <span class="text-green-500 font-bold" v-if="item.recordStatus == 3">
              {{ $t('WithdrawalReturn') }}
            </span>

            <span class="text-green-500 font-bold" v-else style="color: red">
              {{ $t('Withdrawal') }}
            </span>

            <span class="text-_wn_e0dddd">{{ moment(item.createDate).format("YYYY-MM-DD HH:mm:ss") }}</span>
          </div>

          <div class="flex justify-between" v-else>

            <span class="text-green-500 font-bold" v-if="item.amount > 0">
              {{ $t('Earnings') }}
            </span>

            <span class="text-green-500 font-bold" v-else style="color: red">
              {{ $t('Expenses') }}
            </span>

            <span class="text-_wn_e0dddd">{{ moment(item.createDate).format("YYYY-MM-DD HH:mm:ss") }}</span>
          </div>


          <div class="flex justify-between">
            <span class="text-gray-500" v-if="item.recordType == 3 && item.recordStatus == 3">
              {{ Math.abs(item.amount) }}
            </span>
            <span class="text-gray-500" v-else>{{ item.amount }}</span>

            <span class="text-_wn_e0dddd" v-if="item.recordType == 0">
              {{ t('PurchaseProduct') }}
            </span>
            <span class="text-_wn_e0dddd" v-if="item.recordType == 1">
              {{ t('RegistrationTrialBonus') }}
            </span>
            <span class="text-_wn_e0dddd" v-if="item.recordType == 2">
              {{ t('recharge') }}
            </span>
            <span class="text-_wn_e0dddd" v-if="item.recordType == 3">
              {{ t('Withdrawal') }}
            </span>
            <span class="text-_wn_e0dddd" v-if="item.recordType == 4">
              {{ t('CurrencyConversion') }}
            </span>
            <span class="text-_wn_e0dddd" v-if="item.recordType == 5">
              {{ t('SavingsIncome') }}
            </span>
            <span class="text-_wn_e0dddd" v-if="item.recordType == 6">
              {{ t('FinancialIncome') }}
            </span>
            <span class="text-_wn_e0dddd" v-if="item.recordType == 7">
              {{ t('SavingsRelease') }}
            </span>
            <span class="text-_wn_e0dddd" v-if="item.recordType == 8">
              {{ t('ContractOrder') }}
            </span>
            <span class="text-_wn_e0dddd" v-if="item.recordType == 9">
              {{ t('ContractPrincipal') }}
            </span>
            <span class="text-_wn_e0dddd" v-if="item.recordType == 10">
              {{ t('SecurityUnfreeze') }}
            </span>
          </div>
        </div>
      </div>
      <div class="no-data text-center my-30px" v-else>
        <img src="@/assets/empty.svg" />
      </div>
      <div class="pages">
        <span v-if="store.userRecord.hasPreviousPage" @click="store.userRecordPreviousPage">{{
          $t('Previous_page')
        }}</span>
        <span v-if="store.userRecord.hasNextPage" @click="store.userRecordNextPage">{{
          $t('Next_page')
        }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue'
import moment from 'moment'
import { showFailToast, showSuccessToast } from 'vant'
import { useI18n } from 'vue-i18n'
import { useCountStore } from '@/store'
import { publicApi, userApi } from '../apis/index'

const store = useCountStore()
const { t } = useI18n()

const showLoading = ref(false)
const appType = import.meta.env.VITE_APP_TYPE
const records = ref([])

onMounted(async () => {
  store.loadUserRecord(1, 5)
})



</script>


<style lang="scss" scoped>
.pages {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0 10px;

  span {
    display: inline-block;
    padding: 0 10px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: rgba(81, 231, 211, 0.1);
    color: #51e7d3;
    border-radius: 4px;
  }

  span:last-child {
    background-color: #51e7d3;
    font-weight: 700;
    color: #0b1222;
    margin-left: 20px;
  }
}
</style>
