export const TRCVT_Uid = "TRCVT_Uid";
export const TRCVT_Agent = "TRCVT_Agent";
export const TRVCT_TOKEN = "TRVCT_TOKEN";
export const TRVCT_USER_ADDRESS = "TRVCT_USER_ADDRESS"
export const TRVCT_USER_BALANCE = "TRVCT_USER_BALANCE"

export const TRCVT_USER_USERID = "TRCVT_USER_USERID"
export const TRCVT_USER_NICKNAME = "TRCVT_USER_NICKNAME"
export const TRCVT_USER_AVATAR = "TRCVT_USER_AVATAR"
export const TRCVT_USER_USERNUMBER = "TRCVT_USER_USERNUMBER"
export const TRCVT_USER_USDT_AMOUNT = "TRCVT_USER_USDT_AMOUNT"
export const TRCVT_USER_CHUXU_USDT_AMOUNT = "TRCVT_USER_CHUXU_USDT_AMOUNT"
export const TRCVT_USER_ETH_AMOUNT = "TRCVT_USER_ETH_AMOUNT"

export const TRCVT_USER_TYPE = "TRCVT_USER_TYPE"
export const TRCVT_USER_ACCESS = "TRCVT_USER_ACCESS"
export const TRCVT_USER_ACCESSTOKEN = "TRCVT_USER_ACCESSTOKEN"
export const TRCVT_USER_REFRESHTOKEN = "TRCVT_USER_REFRESHTOKEN"

export const TRCVT_RechargeUsdtERC20Address = "TRCVT_RechargeUsdtERC20Address"
export const TRCVT_RechargeUsdtTRC20Address = "TRCVT_RechargeUsdtTRC20Address"
export const TRCVT_RechargeUsdtOmniAddress = "TRCVT_RechargeUsdtOmniAddress"



export const TRCVT_Authentication = "TRCVT_Authentication"
export const TRCVT_UsdtAddress = "TRCVT_UsdtAddress"
export const TRCVT_UsdtEthAddress = "TRCVT_UsdtEthAddress"
export const TRCVT_UsdtBtcAddress = "TRCVT_UsdtBtcAddress"
export const TRCVT_Name = "TRCVT_Name"
export const TRCVT_Identity = "TRCVT_Identity"
export const TRCVT_Img1 = "TRCVT_Img1"
export const TRCVT_Img2 = "TRCVT_Img2"
export const TRCVT_Img3 = "TRCVT_Img3"
export const TRCVT_ContractOrderArr = "TRCVT_ContractOrderArr"

export const TRCVT_USER_Chuxushouyi = "TRCVT_USER_Chuxushouyi"
export const TRCVT_USER_Jinrishouyi = "TRCVT_USER_Jinrishouyi"
export const TRCVT_USER_Licaishouyi = "TRCVT_USER_Licaishouyi"
export const TRCVT_USER_Zhanghu = "TRCVT_USER_Zhanghu"