<template>
  <div>
    <van-nav-bar left-arrow :title="$t('savings')" @click-left="onClickLeft"  class="left-arrow"/>
    <HomeSwipe />

    <div class="px-12px">
      <div v-if="appType == '3'" class="h-120px w-full text-_wn_FFFFFF amount-left mt-20px rounded-4px px-10px">
        {{ $t('Remaining_amount') }}
        <div class="text-blod text-16px text-_wn_51e7d3">{{ store.usdtAmount }}</div>
      </div>
      <div v-else class="h-120px w-full text-_wn_FFFFFF amount-left mt-20px rounded-4px px-10px">
        {{ $t('Saving_amount') }}
        <div class="text-blod text-16px text-_wn_51e7d3">{{ store.chuxuUsdtAmount }}</div>
      </div>
      <div class="font-700 text-14px my-20px">{{ $t('savings') }} USDT</div>
      <!-- 储蓄产品列表 -->
      <div class="m-10px p-10px bg-_wn_000 rounded-4px text-white text-14px" v-for="(item, index) in products" :key="index"
        @click="goPage(item)">
        <div>{{ item.title }}</div>
        <div class="flex text-center">
          <div v-if="appType != '3'" class="flex-1">{{ $t('money') }}</div>
          <div v-if="appType == '3'" class="flex-1">{{ $t('USDT') }}</div>
          <div class="flex-1">{{ $t('Daily_rate_of_return') }}</div>
        </div>
        <div class="flex text-center text-_wn_51e7d3">
          <div class="flex-1" v-if="item.moneyMin > 0 && item.moneyMax > 0">{{ item.moneyMin + '-' + item.moneyMax }}
          </div>
          <div class="flex-1" v-else-if="item.moneyMin > 0">{{ item.moneyMin }}</div>
          <div class="flex-1" v-else>{{ item.moneyMax }}</div>
          <div class="flex-1">{{ item.profit + '%' }}</div>
        </div>
        <div class="lh-32px">{{ $t('Currency') }}</div>
        <div class="flex justify-between">
          <img src="@/assets/logo.png" class="w-22px h-22px alt srcset" alt="" srcset="" />
          <van-icon name="upgrade" size="22px" class="detail-icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { Swipe, SwipeItem, showFailToast, showSuccessToast, showToast } from 'vant'
import { publicApi, userApi } from '../apis/index'
import { Product } from '../@types/product'
import { useCountStore } from '@/store'
import HomeSwipe from "@/components/HomeSwipe.vue";
const appType = import.meta.env.VITE_APP_TYPE;
const store = useCountStore()

const onClickLeft = () => {
  router.push({
    name: "HomeIndex"
  })
}
// getProductList
const products = ref<Product[]>([])
onMounted(() => {
  publicApi.getProductList(1, 999).then((result) => {
    console.log('ProductList', result)
    if (result.code === 0) {
      products.value = result.data
    }
  })
})

const router = useRouter()
const goPage = (item: any) => {
  router.push({
    name: 'ProductOrder',
    query: { ...item }
  })
}
</script>

<style lang="scss" scoped>
.amount-left {
  height: 100px;
  width: 100%;
  background-image: url(@/assets/bg-withdraw.ea1f3478.png);
  background-size: cover;
  background-position: 50%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 7px;
  box-sizing: border-box;
}

.detail-icon {
  transform: rotate(90deg);
  color: #51e7d3;
}
</style>
