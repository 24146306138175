<template>
  <van-config-provider theme="dark" style="height: 100%;">
    <van-nav-bar left-arrow :title="$t('Authentication')" @click-left="onClickLeft"  class="left-arrow"/>
    <div>
      <van-steps :active="active">
        <van-step>{{ $t('RegisterAccount') }}</van-step>
        <van-step>{{ $t('Authentication') }}</van-step>
        <van-step> <span v-if="appType != '1'"> {{ $t('SecureBinding') }}</span></van-step>
        <van-step>{{ $t('WaitingReview') }}</van-step>
        <van-step>{{ $t('Finish') }}</van-step>
      </van-steps>
      <Authentication v-if="active == '1'" />
      <UsdtAddress v-if="appType != '1' && active == '2'" />
    </div>
    <div class="btns" v-if="active == '1' || active == '2' && (active == '2' && appType != '1')">
      <van-space>
        <van-button plain type="primary" @click="jump" class="plain">{{ $t('Jump') }}</van-button>
        <van-button type="success" @click="next">{{ $t('Next') }}</van-button>
      </van-space>
    </div>

    <div class="result" v-if="appType != '1' && (active == '3' || active == '4')">
      <div class="result-box" v-if="active == '3'">
        <van-icon name="todo-list-o" class="result-icon" />
        <span class="result-text" style="font-size: 16px;">{{ $t('WaitingReview') }}</span>
      </div>
      <div class="result-box" v-if="active == '4'">
        <van-icon name="shield-o" class="result-icon" />
        <span class="result-text" style="font-size: 16px;">{{ $t('AuthenticationBindingSuccessful') }}</span>

        <van-cell-group style="margin-top: 15px;">
          <van-field rows="3" autosize v-model="store.usdt_address" label="USDT"  class="autosize"/>
          <van-field rows="3" autosize v-model="store.usdt_eth_address" label="ETH"  class="autosize"/>
          <van-field rows="3" autosize v-model="store.usdt_btc_address" label="BTC"  class="autosize"/>
        </van-cell-group>

        <van-button type="success" block style="margin-top: 5px;" @click="saveAddress" class="block">{{ $t('Save') }}</van-button>
      </div>

      <div style="width: 100%; height: 40%;"></div>
    </div>
    <div class="result" v-if="appType == '1' && (active == '2' || active == '3' || active == '4')">
      <div class="result-box" v-if="active == '3'">
        <van-icon name="todo-list-o" class="result-icon" />
        <span class="result-text" style="font-size: 16px;">{{ $t('WaitingReview') }}</span>
      </div>
      <div class="result-box" v-if="active == '4'">
        <van-icon name="shield-o" class="result-icon" />
        <span class="result-text" style="font-size: 16px;">{{ $t('AuthenticationBindingSuccessful') }}</span>
      </div>

      <div style="width: 100%; height: 40%;"></div>
    </div>

    <van-overlay :show="loading">
      <div class="flex-center h-100vh">
        <van-loading />
      </div>
    </van-overlay>
  </van-config-provider>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { showFailToast, showSuccessToast, showToast, showDialog } from 'vant'
import { useCountStore, useHuobiStore } from '@/store'
import Authentication from "@/components/Authentication.vue";
import UsdtAddress from "@/components/UsdtAddress.vue";

const router = useRouter()
const store = useCountStore()
const huobiStore = useHuobiStore()
const { t } = useI18n()
const loading = ref(false)

const appType = import.meta.env.VITE_APP_TYPE

const onClickLeft = () => {
  clearInterval(intervalId.value)
  router.push({
    name: "HomeIndex"
  })
}

const active = ref("1")

onMounted(async () => {
  loading.value = true
  var result = await store.getUserinfo()

  active.value = result.data.authentication
  loading.value = false

  if (active.value == '3') {
    getUserIdentityState()
  }
})

const jump = async () => {
  var index = parseInt(active.value)
  active.value = (index + 1) + ''
}

const next = async () => {
  switch (active.value) {
    default:
    case '1':
      var result = await store.updateIdentity()
      if (result.code == 0) {
        if (appType == '1') {
          active.value = '3'
        }
        showSuccessToast(t(result.msg))
      } else {
        showFailToast(t(result.msg))
      }
      break;

    case '2':
      if (appType == "1") {
        active.value = '3'
      } else {
        saveAddress()
      }
      break;
  }

  getUserIdentityState()
}

const saveAddress = async () => {
  var result = await store.updateUsdtAddress()
  if (result.code == 0) {
    active.value = '3'
    showSuccessToast(t(result.msg))
  } else {
    showFailToast(t(result.msg))
  }
}

const intervalId = ref()
const getUserIdentityState = async () => {
  var result = await store.getUserinfo()
  if (result.data.authentication != '3') {
    return
  }
  clearInterval(intervalId.value)
  intervalId.value = setInterval(async () => {
    var result = await store.getUserinfo()
    if (result.code == 0) {
      if (active.value == '3' && result.data.authentication == '4') {
        clearInterval(intervalId.value)
        onClickLeft()
      }
    }
  }, 1000)
}
</script>

<style lang="scss" scoped>
.btns {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 20px 15px;
}

.result {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .result-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .result-icon {
    margin: 15px 0px;
    font-size: 66px;
  }

  .result-text {}
}
</style>