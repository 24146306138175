export default {
  English: 'English',
  Chinese: '简体中文',
  TraditionalChinese: '繁體中文',
  Korean: '한국인',
  Japanese: '日本語',
  Vietnamese: 'Tiếng Việt',
  Asset_Management_Savings_Bank: 'Asset Management Savings Bank',
  Bank_Account: 'Bank Account',
  Participate_in_savings: 'Participate in savings',
  Remaining_amount: 'Remaining amount',
  Saving_amount: 'Saving amount',
  Selected_financial_management: 'Selected financial management',
  user_pool: 'user pool',
  account: 'account',
  total_output: 'total output',
  Valid_node: 'Valid node',
  participant: 'participant',
  income: 'income',
  savings_income: 'savings income',
  Todays_earnings: 'Todays earnings',
  Financial_management_income: 'Financial management income',
  exchange: 'exchange',
  recharge: 'recharge',
  extract: 'extract',
  Record: 'Record',
  convertible_income: 'convertible income',
  exchange_amount: 'exchange amount',
  Please_enter_the_exchange_amount: 'Please enter the exchange amount',
  total: 'total',
  Available_Balance: 'Available Balance',
  Withdrawal_amount: 'Withdrawal amount',
  Please_enter_the_withdrawal_amount: 'Please enter the withdrawal amount',
  Profit_record: 'Profit record',
  address: 'address',
  number: 'number',
  common_problem: 'common problem',
  Previous_page: 'Previous page',
  Next_page: 'Next page',
  Audit_Report: 'Audit Report',
  We_have_a_security_audit_report: 'We have a security audit report',
  partner: 'partner',
  our_business_partners: 'our business partners',
  expenditure: 'expenditure',
  amount: 'amount',
  remark: 'remark',
  Quote_change: 'Quote change',
  Highest: 'Highest',
  My_Order: 'My Order',
  Buy: 'Save',
  Kline_chart: 'K-line chart',
  Please_select_contract_time: 'Please select contract time',
  purchasing_price: 'purchasing price',
  Please_enter_the_purchase_amount: 'Please enter the purchase amount',
  savings: 'savings',
  Financial_institution_name: 'Financial institution name',
  Please_enter: 'please enter',
  Branch_name: 'Branch name',
  branch_number: 'branch number',
  zhanghao: 'Account number',
  Account_holder: 'Account holder',
  UsdtERC20Address: 'Usdt ERC20 Address',
  binding: 'binding',
  Invite_friends: 'Invite friends',
  CopiedSuccessfully: 'Copied successfully',
  Copy_failed: 'Copy failed',
  Please_enter_the_amount: 'Please enter the amount',
  Select_storage_period: 'Arbitrage time',
  money: 'money',
  USDT: 'USDT',
  Daily_rate_of_return: 'daily interest',
  savings_products: 'savings products',
  Currency: 'Currency',
  PleaseEnterTheCorrectAmount: 'Please enter the correct amount',
  NotOpenYet: 'Not open yet',
  Earnings: 'Earnings',
  Expenses: 'Expenses',
  Confirm: 'confirm',
  Cancel: 'cancel',
  Copy: 'copy',

  RechargeAmount: 'recharge amount',
  PleaseEnterTheRechargeAmount: 'Please enter the recharge amount',
  RechargeERC20Address: 'Recharge ERC20 address',
  Submit: 'submit',
  YourAccountHasBeenDisabled: 'Your account hasBeen disabled!',
  InformationUpdatedSuccessfully: 'Information updated successfully',
  YourAccountIsFrozen: 'Your account is frozen!',
  ProductDoesNotExist: 'Product does not Exist',
  ThePurchaseAmountMustBeLessThanTheProductAmount: 'The purchase amount must beLess than the product amount',
  InsufficientAccountBalance: 'Insufficient account balance',
  WithdrawalRequestHasBeenSubmitted: 'Withdrawal request has been submitted',
  RechargeRequestHasBeenSubmitted: 'Recharge request has been submitted',
  AmountEnteredIncorrectly: 'Amount entered incorrectly!',
  Successfully: 'Successfully',
  Day: 'Day',
  BuyFailed: 'Buy failed',
  BuySuccessfully: 'Buy Successfully',

  Withdrawal: 'Withdraw money',
  PurchaseProduct: 'buy product',
  RegistrationTrialBonus: 'Registration trial bonus',
  CurrencyConversion: 'currency conversion',
  SavingsIncome: 'savings income',
  FinancialIncome: 'Financial management income',
  SavingsRelease: 'savings release',
  BindingSuccessful: 'Binding successful',
  ViewThisAccount: 'View this account',
  NoWalletEnv: 'Please use the wallet browser to open this system!',
  PleaseBindYourBankCardBeforeWithdrawingMoney: 'Please bind your bank card before withdrawing money!',
  天: 'Day',
  周: 'Week',
  月: 'Month',
  年: 'Year',
  ContractTrading: 'Contract Trading',
  '1Min': '1Min',
  '5Min': '5Min',
  '1Hour': '1Hour',
  '1Day': '1Day',

  Loading: 'Loading',
  ContractTimeError: 'Contract time is wrong!',
  ContractTypeError: 'Wrong contract type!',
  ContractNotSupported: 'The contract does not support it!',
  YouHaveAContractOrderInProgressButTheOrderFailed: 'You have a contract order in progress, but the order failed!',
  NotOrder: 'The order does not exist!',
  PleaseSelectContractTime: 'Please select contract time',
  PleaseEnterThePurchaseAmount: 'Please enter the purchase amount',
  BuyLong: 'Buy long',
  BuyLess: 'Buy less',
  PurchasingPrice: 'Purchasing price',
  Gain: 'Surplus',
  Loss: 'Deficit',
  Ok: 'Ok',
  ContractOrder: 'Contract Order',
  ContractPrincipal: 'Contract Principal',
  WithdrawalReturn: 'Withdrawal Return',

  OrderStatusError: 'Order Status Error!',
  HighestPrice: 'Highest Price',
  LowestPrice: 'Lowest Price',
  TradingVolume: 'Trading Volume',
  TransactionVolume: 'Transaction Volume',
  Position: 'Position',
  CommissionRecord: 'Commission Record',
  ProfitLoss: 'Profit Loss',
  OpeningPrice: 'Opening Price',
  CurrentPrice: 'Current Price',
  PositionQuantity: 'Position Quantity',
  ClosePosition: 'Close Position',
  Service: 'Service',
  ContractList: 'Contract List',
  LatestPrice: 'Latest Price',
  PleaseEnterTheSearchKeyword: 'Please Enter The Search Keyword',
  Number: 'Number',
  NoData: 'No Data',
  Price: 'Price',

  TradeBuy: 'Trade Buy',
  TradeSell: 'Trade Sell',
  Security: 'Security',
  Lever: 'Lever',
  Chart: 'Chart',
  Trading: 'Trading',
  Cross: 'Cross',
  Isolated: 'Isolated',
  WalletLogin: 'Wallet Login',
  Username: 'Username',
  Password: 'Password',
  ConfirPassword: 'Confirm Password',
  PleaseEnterTheUsername: 'Please Enter The Username!',
  PleaseEnterThePassword: 'Please Enter The Password!',
  PleaseEnterTheConfirPassword: 'Please Enter The Confirm Password!',
  Logout: 'Logout',
  ConfirPasswordError: 'Confirm Password Error!',
  UsernameAlreadyExists: 'Username Already Exists!',
  InsufficientAccountSecurity: 'Insufficient Account Security!',
  AlreadyHaveAnAccountClickToLogIn: 'Already Have An Account Click To LogIn',
  DontHaveAnAccountYetClickToRegister: 'Don’t Have An Account Yet? ClickToRegister',

  PleaseCompleteTheIdentityAuthenticationFirst: 'Please complete identity verification first!',
  AuthenticationBindingSuccessful: 'Authentication binding successful!',
  WaitingReview: 'Waiting for verification!',
  Next: 'Next step',
  Jump: 'jump over',
  RegisterAccount: 'register account',
  Authentication: 'Authentication',
  SecureBinding: 'secure binding',
  Finish: 'Finish',

  Name: 'Name',
  PleaseTypeInYourName: 'Please type in your name',
  IdNumber: 'ID number',
  PleaseTypeInYourIdNumber: 'Please enter identification number',
  FrontOfDocument: 'front of document',
  ReverseSideOfId: 'reverse side of ID',
  HoldingIdPhoto: 'Holding ID photo',
  UsdtAddress: 'Usdt address',
  Failed: 'fail',
  ClosePositionPrice: 'Closing price',
  PositionClosed: 'Position closed',

  QuickRecharge: 'Quick Recharge',
  PleaseTypeInYourUsdtAddress: 'Please enter your Usdt address',
  CopyRechargeAddress: 'Copy Recharge Address',
  Login: 'Login',
  Email: 'Email',
  PleaseEnterTheEmail: 'Please enter your e-mail address',

  PleaseEnterTheVerifyCode: 'Please enter your email verification code',
  EmailCode: 'Code',
  GetCode: 'Send Code',
  SwitchLanguage: 'Switch Language',
  RegisterType: 'Register Type',
  RegisterSuccess: 'Register Success',
  PasswordError: 'Password Error',
  PleaseEnterTheTransferAddress: 'Please enter your transfer address',
  TransferVoucher: 'Transfer voucher',
  OpenPosition: 'Open a position',
  BuyToOpenLong: 'Buy to open long',
  SellToOpenShort: 'sell short',
  BuyShort: 'sell long',
  SellLong: 'buy short',
  Kepingduo: 'Kepingduo',
  Kepingkong: 'Can be closed',

  PleaseTypeInYourETHAddress: 'Please enter your ETH address',
  PleaseTypeInYourBTCAddress: 'Please enter your BTC address',
  Save: 'save',
  SecurityRate: 'margin rate',
  Handling: 'handling fee',
  Zhang: 'Number',
  SecurityUnfreeze: 'Security Unfreeze',
  AccountError: 'Account Error!',
  jiaoyileixing: 'Transaction type:',
  Account_holder_jiaming: 'Account name (kana):'
}
