<template>
  <div>
    <div class="tradepanel ">

      <div class="panel f-ajs mt-10px">

        <div class="lpanel">
          <div class="tlbox f-ajs">
            <div class="tlltxt">
              <div class="tttxt">{{ $t('Price') }}</div>
              <div class="tbtxt">({{ huobiStore.kLineType1 }})</div>
            </div>
            <div class="trbox f-ac">
              <div class="tlltxt">
                <div class="tttxt">{{ $t('Number') }}</div>
                <div class="tbtxt">({{ huobiStore.kLineType0 }})</div>
              </div>
            </div>
          </div>
          <div class="updata">
            <div class="upitem f-ajs" v-for="(item, index) in huobiStore.askArr" :key="index">
              <div class="uplbox">{{ item.price }}</div>
              <div class="uprbox">{{ item.num.toFixed(6) }}</div>
              <div class="upbg" :style="{ 'width': item.rate + '%', 'transition-duration': '1s' }"></div>
            </div>
          </div>

          <div class="updatas" style="margin-bottom: 15px;">
            <div class="upitem f-ajs" v-for="(item, index) in huobiStore.bidArr" :key="index">
              <div class="uplbox">{{ item.price }}</div>
              <div class="uprbox">{{ item.num.toFixed(6) }}</div>
              <div class="upbg" :style="{ 'width': item.rate + '%', 'transition-duration': '1s' }"></div>
            </div>
          </div>
        </div>


        <div class="rpanel">

          <div class="topbtn f-ajs">
            <van-button type="danger" size="small" @click="huobiStore.selectTradeType('buy')">
              {{ $t('OpenPosition') }}
            </van-button>
            <van-button type="success" size="small" @click="huobiStore.selectTradeType('sell')">
              {{ $t('ClosePosition') }}
            </van-button>
          </div>

          <div class="rpbox f-ajs mt-20px" v-if="huobiStore.tradeType == 'buy'">
            <div class="rplbox" style="color: white;">{{ $t('Available_Balance') }}</div>
            <div class="rbox f-ac">
              <div class="rtxt" style="color: white;">{{ store.usdtAmount.toFixed(2) }} {{
                huobiStore.kLineType1 }}
              </div>
            </div>
          </div>

          <!-- <div class="baozhengjin" v-if="huobiStore.tradeType == 'buy'">
            <div>{{ $t('Security') }}</div>
            <div>{{ huobiStore.security.toFixed(3) }}</div>
          </div> -->
          <!-- <div class="baozhengjin" v-if="huobiStore.tradeType == 'buy'">
            <div>{{ $t('Handling') }}</div>
            <div>{{ huobiStore.handling.toFixed(3) }}</div>
          </div> -->

          <div class="ganggan" v-if="huobiStore.tradeType == 'buy'">
            <!-- <div class="ganggan-text">
              <div class="text-left">
                <van-radio-group v-model="huobiStore.securityType" @change="huobiStore.changeNumber"
                  direction="horizontal">
                  <van-radio name="0">{{ $t('Cross') }}</van-radio>
                  <van-radio name="1">{{ $t('Isolated') }}</van-radio>
                </van-radio-group>
              </div>
              <div class="text-right">{{ huobiStore.lever }}x</div>
            </div> -->

            <van-field v-model="huobiStore.marketTimeText" is-link readonly label="时间" placeholder="选择合约时间"
              @click="showPicker = true"  class="is-link readonly"/>
            <van-popup v-model:show="showPicker" round position="bottom" class="round">
              <van-picker :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
            </van-popup>

            <!-- <van-button @click="huobiStore.showLeversPopup = true" type="primary" block class="block">
              {{ $t('Lever') + ' ' + huobiStore.lever + 'x' }}
            </van-button> -->
          </div>

          <van-space direction="vertical" fill style="margin-top :15px ;" class="fill">
            <van-field type="number" input-align="center" v-model="huobiStore.orderNumber"
              @update:model-value="huobiStore.changeNumber">
            </van-field>
          </van-space>
          <div v-if="huobiStore.tradeType == 'buy'">
            <van-button type="danger" block style="margin-top: 15px;" @click="trade(1)" class="block">
              {{ $t('BuyToOpenLong') }}
            </van-button>
            <van-button type="success" block style="margin-top: 15px;" @click="trade(0)" class="block">
              {{ $t('SellToOpenShort') }}
            </van-button>
          </div>

          <div v-if="huobiStore.tradeType == 'sell'" style="margin-top: 15px;">
            <div
              style="color: white; font-size: 16px;     display: flex; flex-direction: row; justify-content: space-between;">
              <span> {{ $t('Kepingduo') }}</span>
              <span>{{ store.duo }}</span>
            </div>
            <van-button type="success" block style="margin-bottom: 15px;" @click="trade(0)" class="block">
              {{ $t('BuyShort') }}
            </van-button>

            <div
              style="color: white; font-size: 16px;     display: flex;flex-direction: row; justify-content: space-between;">
              <span> {{ $t('Kepingkong') }}</span>
              <span>{{ store.kong }}</span>
            </div>
            <van-button type="danger" block style="margin-bottom: 15px;" @click="trade(1)" class="block">
              {{ $t('SellLong') }}
            </van-button>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>

    <van-dialog id="miaoheyueDialog" class="miaoheyue-dialog _cl_show-confirm-button false _cl_show-cancel-button false" style=" top: 50%;
  z-index: 9999;
  width: 100vw;
  background-color: white;
  text-align: center;
  height: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;" :overlay="false" v-model:show="showTimeLoading" :show-confirm-button=false
      :show-cancel-button=false>

      <van-circle v-model:current-rate="currentRote" :rate="currentTime" :speed="0" :text="currentTimeTxt + ''"
        :size="circleSize" :clockwise="false" layer-color="#f4f1f1" color="#f2c664" style="margin-top: 25px;" class="color-_wn_f2c664">
        <div style="height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center;">
          <div style="margin: 0px 0px; color:#f2c664; font-size: 60px;">{{ currentTimeTxt }}</div>
          <div style="margin: 0px 0px; color:#fa5555; font-size: 25px;">{{ $t('结算中') }}</div>
        </div>
      </van-circle>

      <div class="miaoheyue-info">
        <div class="miaoheyue-info-item">
          <span class="miaoheyue-info-item-title">方向</span>
          <span class="miaoheyue-info-item-val" style="color: #13aa8e;"
            v-if="huobiStore.marketContractType == 1">买涨</span>
          <span class="miaoheyue-info-item-val" style="color: red;" v-if="huobiStore.marketContractType == 2">买跌</span>
        </div>

        <div class="miaoheyue-info-item">
          <span class="miaoheyue-info-item-title">数量（USDT）</span>
          <span class="miaoheyue-info-item-val">{{ huobiStore.orderNumber }}</span>
        </div>

        <!-- <div class="miaoheyue-info-item">
          <span class="miaoheyue-info-item-title">购买价</span>
          <span class="miaoheyue-info-item-val">{{ huobiStore.orderNumber }}</span>
        </div>

        <div class="miaoheyue-info-item">
          <span class="miaoheyue-info-item-title">预计盈亏</span>
          <span class="miaoheyue-info-item-val">盈利</span>
        </div> -->
      </div>
      <!-- <van-button type="success" block @click="huobiStore.selectTradeType('sell')" class="block">
        {{ $t('ClosePosition') }}
      </van-button> -->
    </van-dialog>


    <van-dialog id="miaoheyueDialog" class="miaoheyue-result _cl_show-confirm-button false _cl_show-cancel-button false" style=" top: 50%;
  z-index: 9999;
  width: 100vw;
  height: 100vw;
  padding: 15px;
  background-color: white;
  text-align: center;
  " :overlay="false" v-model:show="showMarketOrderTimeResult" :show-confirm-button=false :show-cancel-button=false>
      <div class="miaoheyue-result-title">
        {{ huobiStore.kLineType0 }}/{{ huobiStore.kLineType1 }}
      </div>

      <div class="miaoheyue-result-top">
        <div class="miaoheyue-result-top-prize" v-if="orderProfitStatus == 1 || orderProfitStatus == 3">
          {{ orderProfit }}
        </div>

        <div class="miaoheyue-result-top-prize" v-else>
          -{{ orderProfit }}
        </div>
        <div class="miaoheyue-result-top-text">交割结算完成</div>
      </div>

      <div class="miaoheyue-result-info">
        <div class="miaoheyue-result-info-item">
          <div class="miaoheyue-result-info-item-title">
            方向
          </div>
          <div class="miaoheyue-result-info-item-value">
            <span v-if="orderResultType == 1" style="color: #11a780;">买涨</span>
            <span v-if="orderResultType == 0" style="color: #cc3d3d;">买跌</span>
          </div>
        </div>

        <div class="miaoheyue-result-info-item">
          <div class="miaoheyue-result-info-item-title">
            数量（USDT）
          </div>
          <div class="miaoheyue-result-info-item-value">
            {{ orderResultAmount.toFixed(2) }}
          </div>
        </div>

        <div class="miaoheyue-result-info-item">
          <div class="miaoheyue-result-info-item-title">
            执行价
          </div>
          <div class="miaoheyue-result-info-item-value">
            {{ orderEndPrice.toFixed(4) }}
          </div>
        </div>

        <div class="miaoheyue-result-info-item">
          <div class="miaoheyue-result-info-item-title">
            预期收益
          </div>
          <div class="miaoheyue-result-info-item-value">
            <span v-if="orderProfitStatus == 1 || orderProfitStatus == 3" style="color: #11a780;">+{{ orderProfit
              }}</span>
            <span v-if="orderProfitStatus == 2" style="color: #cc3d3d;">-{{ orderProfit }}</span>
          </div>
        </div>
      </div>


      <van-button type="success" block @click="showMarketOrderTimeResult = false" class="block">
        {{ $t('Close') }}
      </van-button>
    </van-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useCountStore, useHuobiStore } from '@/store/index'
import { userApi } from '../apis/index'
import { showFailToast, showToast, showLoadingToast, showSuccessToast } from 'vant'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
const { t } = useI18n()
const router = useRouter()
const store = useCountStore()
const huobiStore = useHuobiStore()
const userStore = useCountStore()

const columns = ref([]);

const showPicker = ref(false);

const onConfirm = ({ selectedOptions }) => {
  showPicker.value = false;
  huobiStore.marketTimeId = selectedOptions[0].value;
  huobiStore.marketTimeText = selectedOptions[0].text;
};


const showTimeLoading = ref(false);
const showMarketOrderTimeResult = ref(false);
const orderId = ref<Number>(0);
const circleSize = ref(20)
const buyItemSelect = ref(null)
const buyTime = ref(60)
const currentTime = ref(60)
const currentRote = ref(10)
const currentTimeTxt = ref("0")
const endTime = ref(null)

const getOrderAction = ref(false)
const showOrderResult = ref(false)
const orderProfitStatus = ref(0)
const orderProfit = ref(0)
const orderEndPrice = ref(0)
const orderResultAmount = ref(0)
const orderResultType = ref(0)


const calcTime = () => {
  let nowTime = moment().unix();
  let shengyu = endTime.value - nowTime;
  if (shengyu >= 0) {
    if (shengyu <= 0) {
      currentTimeTxt.value = '0'
      currentTime.value = 0
    } else {
      currentTimeTxt.value = shengyu + ''
      currentTime.value = (shengyu / buyTime.value) * 100

      huobiStore.showCreateMarketOrder = false
    }
  }


  if (shengyu < 0 && orderId.value != 0 && !getOrderAction.value) {
    getOrderAction.value = true
    // showTimeLoading.value = false
    setTimeout(() => {
      userApi.getContractOrderInfo(orderId.value).then(result => {
        if (result.code == 0) {
          // console.log(result);
          if (result.data.contractOrderStatus != 0) {
            orderProfitStatus.value = result.data.profitStatus
            orderProfit.value = result.data.makeProfit
            orderEndPrice.value = result.data.endPrice
            orderResultAmount.value = result.data.amount * result.data.endPrice
            orderResultType.value = result.data.contractOrderType

            orderId.value = 0
            showTimeLoading.value = false
            showMarketOrderTimeResult.value = true
            getOrderAction.value = false
          }
          else {
            getOrderAction.value = false;
            endTime.value = moment().unix() + 1;
          }
        }
      })
      setTimeout(() => {
        huobiStore.getMarketOrderList(1, 10)
        userStore.getUserinfo()
      }, 500)
    }, 500)
  }
}

const trade = async (type) => {
  if (!store.checkedLoginAndShowLogin()) {
    return
  }

  if (!checkedAuthentication()) {
    return
  }

  if (!huobiStore.orderNumber) {
    showFailToast(t('Please_enter_the_amount'))
    return
  }


  // showFailToast('发起交易')
  // showTimeLoading.value = true

  buy(type)

  // huobiStore.showLoading()
  // let result = await huobiStore.contractTrade(type, false)
  // huobiStore.hideLoading()

  // if (result.code != 0) {
  //   showFailToast(t(result.msg))
  // } else {
  //   showSuccessToast(t(result.msg))
  //   store.getUserinfo()
  // }
}

async function buy(type) {
  if (!huobiStore.marketTimeId) {
    showToast(t('PleaseSelectContractTime'));
    return
  }

  if (!huobiStore.orderNumber) {
    showToast(t('PleaseEnterThePurchaseAmount'));
    return
  }

  var loading = showLoadingToast({
    message: t('Loading'),
    forbidClick: true,
    duration: 0
  });

  huobiStore.marketContractType = type
  var result = await huobiStore.createMarketOrder()
  loading.close()
  // console.log(result)

  if (result.code != 0) {
    showFailToast(t(result.msg))
    return;
  }

  // console.log("订单时间", result.data.createTime)
  // console.log("结束时间", result.data.endTime)

  showLoadingOrder(result.data)
}


/**
 * 显示订单倒计时
 * @param orderInfo 
 */
const showLoadingOrder = (orderInfo) => {
  orderId.value = orderInfo.id
  buyTime.value = orderInfo.contractTime
  let endTimeUnix = orderInfo.endTimeStamp;
  endTime.value = endTimeUnix
  let nowTime = moment(Date()).unix();

  // console.log("endTimeUnix", endTimeUnix)
  // console.log("nowTime", nowTime)
  // console.log("nowTimeServer", orderInfo.endTimeStamp)
  let shengyu = endTimeUnix - nowTime
  // console.log("shengyu", shengyu)
  currentTimeTxt.value = shengyu + ''
  if (shengyu >= 1) {
    showTimeLoading.value = true
  }
}



onMounted(() => {
  circleSize.value = document.body.clientWidth * 0.6

  setInterval(() => {
    calcTime()
  }, 1000)

  huobiStore.loadMarkeOrderTimeData().then(result => {
    // console.log(result.data);

    var data = result.data.map(function (p) {
      return { text: p.contractTime, value: p.id };
    })

    // console.log(data);

    columns.value = data
  });
})

/**
 * 检查是否通过身份验证
 */
const checkedAuthentication = () => {
  const appType = import.meta.env.VITE_APP_TYPE
  if (appType == '2') {
    return true
  }

  if (store.authentication != '4') {
    showFailToast(t('PleaseCompleteTheIdentityAuthenticationFirst'))
    window.scrollTo(0, 0)
    router.push({
      name: "IdEntity"
    })
    return false
  }

  return true
}
</script>

<style lang="less" scoped>
.icon {

  width: 14px;
  height: 14px;
}

.line {
  background: #000000;
  padding: 4px;
}

.tradepanel {
  padding: 5px 10px;

  .panel {
    align-items: flex-start;

    .lpanel {
      width: 40%;

      .tlbox {
        color: #4b4f5a;
        line-height: 1.2;
      }

      .updata {
        margin-top: 20px;

        .upitem {
          position: relative;

          .uplbox {
            color: #2ca385;
            line-height: 2;
            z-index: 1;
          }

          .uprbox {
            color: #dcebf2;
            line-height: 2;
            z-index: 1;
          }

          .upbg {
            position: absolute;
            // width: 100%;
            height: 100%;
            background: #16292f;
            z-index: 0;
          }
        }
      }

      .updatas {
        margin-top: 20px;

        .upitem {
          position: relative;

          .uplbox {
            color: #f13d3c;
            line-height: 2;
            z-index: 1;
          }

          .uprbox {
            color: #dcebf2;
            line-height: 2;
            z-index: 1;
          }

          .upbg {
            position: absolute;
            // width: 100%;
            height: 100%;
            background: #2d1b27;
            z-index: 0;
          }
        }
      }

      .botbox {
        .lbox {
          height: 26px;
          background-color: #1e2432;
          color: #4b4f5a;
          width: 80%;
          border-radius: 6px;
          padding: 0 10px;
        }

        .rbox {
          margin-left: 10px;
          flex: 1;
        }
      }

      .totalupdata {
        margin-top: 10px;

        .lbox {
          color: #4b4f5a;

          .tbox {
            color: #2ca385;
            font-size: 18px;
          }
        }

        .rbox {
          color: #4b4f5a;
        }
      }
    }

    .rpanel {
      width: 60%;
      padding-left: 20px;

      .topbtn {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        button {
          width: 49%;
        }
      }



      .rpbox {
        .rplbox {
          color: #4b4f5a;
        }

        .rbox {
          .icon {
            font-size: 14px;
            margin-left: 10px;
          }
        }
      }

      .ganggan {
        // margin-bottom: 15px;
        // padding-bottom: 15px;


        .ganggan-text {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 25px 5px;
          color: white;

          .text-left {}

          .text-right {}
        }
      }

      .baozhengjin {
        margin-bottom: 15px;
        // padding-bottom: 15px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 15px 5px;
        color: white;

        .text-left {}

        .text-right {}
      }

      .input {
        background-color: #1e2432;
        border-radius: 6px;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;

        .icon {
          color: #4b4f5a;
        }
      }

      .inputs {
        background-color: #1e2432;
        border-radius: 6px;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;

        .icon {
          color: #4b4f5a;
        }

        .text {
          width: 60%;

          .inp {
            width: 100%;
            border: none;
            background: none;
            text-align: center;
          }
        }
      }

      .buybtn {
        margin-top: 15px;
        border-radius: 6px;
        color: #fff;
        background: #f13d3c;
        text-align: center;
        height: 40px;
        line-height: 40px;
      }

      .sellbtn {
        margin-top: 15px;
        border-radius: 6px;
        color: #fff;
        background: #f13d3c;
        text-align: center;
        height: 40px;
        line-height: 40px;
      }

      .botbox {
        height: 26px;

        .lbox {
          color: #4b4f5a;

          .text {
            padding-left: 10px;
          }
        }

        .rbox {
          color: #4b4f5a;

          .text {
            padding-left: 10px;
          }
        }
      }
    }
  }


}

#miaoheyueDialog {
  top: 50%;
  z-index: 9999;
  width: 100vw;
  background-color: white;
  text-align: center;
  height: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .van-dialog__content {
    display: flex;
    justify-content: center;
  }
}

.van-dialog__content {
  display: flex;
  justify-content: center;
}

.miaoheyue-info {
  width: 80vw;
  border-top: 2px solid #f7f5f5;
  margin: 25px 0px;
  padding: 5px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #adadad;

  .miaoheyue-info-item {
    display: flex;
    flex-direction: column;


    .miaoheyue-info-item-title {
      margin: 10px 0px;
    }

    .miaoheyue-info-item-val {}
  }
}

.miaoheyue-result {
  display: block;

  .miaoheyue-result-title {
    font-size: 18px;
  }

  .miaoheyue-result-top {
    margin: 10px 0px;
    border-top: 2px solid #f4f4f4;
    border-bottom: 2px solid #f4f4f4;

    .miaoheyue-result-top-prize {
      color: #11a780;
      font-size: 48px;
    }

    .miaoheyue-result-top-text {
      color: #000000;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .miaoheyue-result-info {
    margin-bottom: 15px;
    background-color: #f1f2f7;
    border-radius: 12px;
    padding: 5px;

    .miaoheyue-result-info-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px 0px;
      padding: 0px 10px;

      .miaoheyue-result-info-item-title {}

      .miaoheyue-result-info-item-value {}
    }
  }
}
</style>
