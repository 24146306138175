import { TRCVT_Agent, TRCVT_Uid } from '@/store/constant'
import ajax from '../ajax'
import { BankInfo } from "../../@types/bankInfo";

import Web3 from 'web3'
import provider from 'eth-provider'
import { showFailToast, showSuccessToast, showToast } from 'vant'
import { RegisteredSubscription } from 'web3-eth';
let _web3: Web3<RegisteredSubscription>;
const web3 = () => {
  if (!_web3) {
    _web3 = new Web3(provider())
  }

  return _web3;
}

function getAgent() {
  return localStorage.getItem(TRCVT_Agent)
}
function getUid() {
  return localStorage.getItem(TRCVT_Uid)
}

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}

/**
 * 通过钱包地址登录系统
 */
async function login(): Promise<ResponseData> {
  try {
    var addressResult = await web3().eth.requestAccounts();
    console.log("授权", addressResult)
    var address = addressResult[0]

    var loginResult = await ajax.post('/API/Login', {
      address: address,
      uid: getUid(),
      agent: getAgent()
    })

    return loginResult
  } catch (error) {

    //浏览器不支持
    if (error.message == "Not connected") {
      console.log("浏览器不支持")
      if (!import.meta.env.DEV) {
        window.location.href = '/ethweb'
      }
    }

    if (error.message == "Returned error: User rejected the request.") {
      console.log("授权拒绝")
    }

    return {
      code: -1,
      msg: "WalletAuthorizationFailed"
    };
  }
}

async function loginByUsername(username: string, password: string): Promise<ResponseData> {
  var userResult = await ajax.post('/API/LoginByUsername', {
    username,
    password
  })

  return userResult
}

async function emailRegister(email: string, code: string, password: string): Promise<ResponseData> {
  var userResult = await ajax.post('/API/EmailRegister', {
    email,
    code,
    password,
    uid: getUid(),
    agent: getAgent()
  })

  return userResult
}

async function usernameRegister(username: string, password: string): Promise<ResponseData> {
  var userResult = await ajax.post('/API/UsernameRegister', {
    username,
    password,
    uid: getUid(),
    agent: getAgent()
  })

  return userResult
}

async function getUserInfo(): Promise<ResponseData> {
  try {
    var userResult = await ajax.post('/API/GetUserInfo')

    return userResult
  } catch (error) {
    return {
      code: -1,
      msg: "WalletAuthorizationFailed"
    };
  }
}

/**
 * 设置银行卡信息
 */
function setBankInfo(bankInfo: BankInfo): ResponseData<any> {
  return ajax.post('/API/SetBankInfo', bankInfo)
}

/**
 * 购买储蓄产品
 */
function buyProduct(
  productId: Number,
  amount: Number,
  dateType: Number
): ResponseData<any> {
  return ajax.post('/API/BuyProduct', {
    productId, amount, dateType
  })
}

/**
 * 转换货币
 */
function convertCurrency(
  amount: String
): ResponseData<any> {
  return ajax.post('/API/ConvertCurrency', {
    amount
  })
}

/**
 * 提现
 */
function withdrawal(amount: String): ResponseData<any> {
  return ajax.post('/API/Withdrawal', {
    amount: amount
  })
}

/**
 * 充值
 */
function recharge(amount: String,
  rechargeType: String,
  rechargeImg: String,
  rechargeUserAddress: String,
  address: String): ResponseData<any> {
  return ajax.post('/API/Recharge', {
    amount: amount,
    rechargeType: rechargeType,
    rechargeImg: rechargeImg,
    rechargeUserAddress: rechargeUserAddress,
    address: address
  })
}

/**
 * 获取交易记录
 */
function getRecordList(page: Number, limit: Number, type: Number): ResponseData<any> {
  return ajax.get('/API/GetRecordList', {
    params: {
      page, limit, type
    }
  })
}

/**
 * 创建合约订单
 * @param symbol 货币对
 * @param id 合约ID
 * @param type 0做空 1做多
 * @param amount 金额
 */
function createContract(symbol, id, type, amount) {
  return ajax.post('/API/CreateContract', {
    symbol: symbol,
    id: id,
    type: type,
    amount: amount
  })
}

function getCurrentContractOrder() {
  return ajax.post('/API/getCurrentContractOrder')
}

function getContractOrderInfo(id) {
  return ajax.post('/API/getContractOrderInfo', {
    id: id
  })
}

function updateIdentity(
  name,
  identity,
  img1,
  img2,
  img3,
) {
  return ajax.post('/API/UpdateIdentity', {
    name,
    identity,
    img1,
    img2,
    img3,
  })
}

function updateUsdtAddress(usdtAddress, ethAddress, btcAddress) {
  return ajax.post('/API/UpdateUsdtAddress', {
    usdtAddress,
    ethAddress,
    btcAddress
  })
}


export default {
  login,
  getUserInfo,
  setBankInfo,
  buyProduct,
  convertCurrency,
  withdrawal,
  recharge,
  getRecordList,
  createContract,
  getCurrentContractOrder,
  getContractOrderInfo,
  loginByUsername,
  emailRegister,
  usernameRegister,
  updateIdentity,
  updateUsdtAddress
}
