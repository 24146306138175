<template>
  <div class="market-info">
    <div class="coin_info_price" style="margin-top: 0px; padding-top: 16px;">
      <div class="flex-row-bt">
        <div class="flex-column-start coin_rate">
          <span class="coin_price font-add-color ">{{ huobiStore.close }}</span>
          <span class="tempCurrFiat">${{ huobiStore.close }}</span>
          <span class="coin_percent font-add-color" v-if="huobiStore.priceChange > 0">+{{
            huobiStore.priceChange.toFixed(4) }}%</span>
          <span class="coin_percent font-add-color" style="color:var(--van-button-success-background);"
            v-if="huobiStore.priceChange <= 0">{{
              huobiStore.priceChange.toFixed(4) }}%</span>
        </div>
        <div class="flex-column-start coin_info_common">
          <p style="cursor: pointer;">
            <span>24H {{ $t('HighestPrice') }}</span>
            <span>{{ huobiStore.high }}</span>
          </p>
          <p>
            <span>24H {{ $t('TradingVolume') }} ({{ huobiStore.kLineType0 }})</span>
            <span>{{ huobiStore.amount.toFixed(2) }}</span>
          </p>
        </div>
        <div class="flex-column-start coin_info_common">
          <p style="cursor: pointer;">
            <span>24H {{ $t('LowestPrice') }} </span>
            <span>{{ huobiStore.low }}</span>
          </p>
          <p>
            <span>24H {{ $t('TransactionVolume') }} ({{ huobiStore.kLineType1 }})</span>
            <span>{{ huobiStore.vol.toFixed(2) }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useCountStore, useHuobiStore } from '@/store'
const huobiStore = useHuobiStore()

</script>

<style lang="scss" scoped>
.market-info {
  width: 100%;
  padding: 0px 16px;
  background-color: #0F0D2A;


  .coin_info_common {
    p :last-child {
      color: white;
    }
  }
}
</style>