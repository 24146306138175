<template>
  <van-dialog v-model:show="showSystemGonggao" :title="baseInfo.gonggao.title" :message="baseInfo.gonggao.content"
    :confirmButtonText="t('Confirm')">
  </van-dialog>
  <van-overlay :show="showLoading" @click="showLoading = false">
    <div class="flex-center h-100vh">
      <van-loading />
    </div>
  </van-overlay>
  <HomeSwipe />

  <div class="px-10px">
    <div class="mt-7px p-7px rounded-4px bg-#51e7d3 text-10px" @click="goPage('HomeBankCard')">
      <div v-if="appType == 2" class="flex-between-center">
        <div class="flex items-center">
          <img src="@/assets/logo.png" class="w-30px h-29px" alt="" srcset="" />
          <div class="ml-3px font-700" style="font-size: 15px">
            {{ $t('Asset_Management_Savings_Bank') }}
          </div>
        </div>
        <div style="width: 224px;font-size: 14px;display: flex;align-items: center;justify-content: space-between;">
          <img v-if="store.vipId == 1 && store.vipId" src="@/assets/vip1.png" style="width: 80px" />
          <img v-if="store.vipId == 2 && store.vipId" src="@/assets/vip2.png" style="width: 80px" />
          <img v-if="store.vipId == 3 && store.vipId" src="@/assets/vip3.png" style="width: 80px" />
          <img v-if="store.vipId == 4 && store.vipId" src="@/assets/vip4.png" style="width: 80px" />
          <img v-if="store.vipId == 5 && store.vipId" src="@/assets/vip5.png" style="width: 80px" />
          <img v-if="store.vipId == 6 && store.vipId" src="@/assets/vip6.png" style="width: 80px" />
          <img v-if="store.vipId == 7 && store.vipId" src="@/assets/vip7.png" style="width: 80px" />
          <img v-if="store.vipId == 8 && store.vipId" src="@/assets/vip8.png" style="width: 80px" />
          <img v-if="store.vipId == 9 && store.vipId" src="@/assets/vip9.png" style="width: 80px" />
          ID: {{ store.usernumber }}
        </div>
      </div>

      <div v-if="appType != 2" class="flex-between-center">
        <div class="flex items-center">
          <img src="@/assets/logo.png" class="w-30px h-29px" alt="" srcset="" />
          <div class="ml-3px font-700" style="font-size: 15px">
            {{ $t('Asset_Management_Savings_Bank') }}
          </div>
        </div>
        <div v-if="store.usernumber"
          style="font-size: 14px;display: flex;align-items: center;justify-content: space-between;">
          ID: {{ store.usernumber }}
        </div>
      </div>

      <div class="mt-10px" style="display: flex; justify-content: space-between">
        <div>
          {{ store.bankName }}
        </div>
        <div style="display: flex">
          <div>{{ store.bankNumber }}</div>
          &nbsp;
          <div>{{ $t('ViewThisAccount') }}</div>
        </div>
      </div>
    </div>
    <div class="h-45px flex-between-center my-10px rounded-4px" v-if="appType != '2'">
      <div class="text-center break-all flex flex-col h-45px w-50% card-item lh-26px text-16px"
        @click="goPage('IdEntity')">
        {{ $t('Authentication') }}
      </div>
      <div class="text-center break-all flex flex-col h-45px w-50% card-item lh-26px text-16px ml-14px"
        @click="goPage('QuickRecharge')">
        {{ $t('QuickRecharge') }}
      </div>
    </div>
    <div class="h-80px flex-between-center my-10px rounded-4px">
      <div class="text-center break-all flex flex-col h-80px w-50% card-item lh-26px" @click="goPage('HomeDeposit')">
        <span class="text-16px font-700">
          {{ $t('Participate_in_savings') }}
        </span>
        <span class="text-12px font-400">
          {{ $t('Saving_amount') }} :
          <span> {{ store.chuxuUsdtAmount }} </span></span>
      </div>
      <div class="text-center break-all flex flex-col h-80px w-50% card-item lh-26px ml-14px"
        @click="goPage('UseTradeView')">
        <span class="text-16px font-700">{{ $t('Selected_financial_management') }}</span>
      </div>
    </div>
    <div class="account-container">
      <div :class="accountType === 1 ? 'active' : ''" @click="changeAccountType(1)">
        {{ $t('user_pool') }}
      </div>
      <div :class="accountType === 2 ? 'active' : ''" @click="changeAccountType(2)">
        USDT {{ $t('account') }}
      </div>
    </div>
    <template v-if="accountType === 1">
      <div class="subtotal-container">
        <div>
          <p>{{ baseInfo.usersData.zongchanliang }}</p>
          <span>{{ $t('total_output') }} (USDT)</span>
        </div>
        <div>
          <p>{{ baseInfo.usersData.youxiaojiedian }}</p>
          <span>{{ $t('Valid_node') }}</span>
        </div>
        <div>
          <p>{{ baseInfo.usersData.canyuzhe }}</p>
          <span>{{ $t('participant') }}</span>
        </div>
        <div>
          <p>{{ baseInfo.usersData.shouyi }}</p>
          <span>{{ $t('income') }} (USDT)</span>
        </div>
      </div>

      <!-- 盈利记录 -->
      <div class="incomes-container text-14px">
        <div class="text-center font-700">{{ $t('Profit_record') }}</div>
        <div class="font-600 flex-between-center">
          <span>{{ $t('address') }}</span>
          <span> {{ $t('number') }}</span>
        </div>
        <div class="vue-scroll" style="height: 110px">
          <vue3-seamless-scroll :list="baseInfo.yingliJilu" step="0.5" class="w-full">
            <div class="item w-full flex-between-center" v-for="(jilu, index) in baseInfo.yingliJilu" :key="index">
              <span class="font-600 text-12px text-#51e7d3">{{
                jilu.dizhi
              }}</span>
              <span class="font-600 text-#fff text-12px">{{
                jilu.yingli
              }}</span>
            </div>
          </vue3-seamless-scroll>
        </div>
      </div>
      <HomeRecord v-if="appType == '5'" />
    </template>
    <AccountInfo v-else />

    <div class="mt-20px px-10px" style="margin-bottom: 160px;">
      <!-- 审计报告 -->
      <div class="mt-20px">
        <div class="py-15px text-center">
          <div class="text-16px font-700 text-#fff">
            {{ $t('Audit_Report') }}
          </div>
          <div class="text-14px text-#898b98">
            {{ $t('We_have_a_security_audit_report') }}
          </div>
        </div>
        <div class="flex-between-center h-42px flex-row">
          <img src="@/assets/sj1.cd3ab6b4.png" class="w-32% h-42px rounded-4px" />
          <img src="@/assets/sj2.9543a47e.png" class="w-32% h-42px rounded-4px" />
          <img src="@/assets/sj3.f7614e7f.png" class="w-32% h-42px rounded-4px" />
        </div>
      </div>
      <div class="py-15px text-center">
        <div class="text-16px font-700 text-#fff">{{ $t('partner') }}</div>
        <div class="text-14px text-#898b98">
          {{ $t('our_business_partners') }}
        </div>
      </div>
      <div class="mb-20px flex-between-center h-42px flex-row flex-wrap">
        <img src="@/assets/p1.d7d8ae84.png" class="w-32% h-42px rounded-4px mb-10px" />
        <img src="@/assets/p2.9fe60713.png" class="w-32% h-42px rounded-4px mb-10px" />
        <img src="@/assets/p3.6a923aba.png" class="w-32% h-42px rounded-4px mb-10px" />
        <img src="@/assets/p4.bbab71bf.png" class="w-32% h-42px rounded-4px" />
        <img src="@/assets/p5.d7b07dc3.png" class="w-32% h-42px rounded-4px" />
        <img src="@/assets/p6.0f2027ad.png" class="w-32% h-42px rounded-4px" />
      </div>
    </div>
    <!-- 常见问题 -->
    <div v-if="appType != '5'" class="faqs-container">
      <p class="title">{{ $t('common_problem') }}</p>
      <div class="faqs-item" v-for="(wenti, index) in baseInfo.changjianWenti" @click="collapseIndex = index"
        :key="index">
        <div class="h-42px flex-between-center px-15px">
          <span>{{ wenti.biaoti }}</span>
          <span><van-icon name="arrow-down" /></span>
        </div>
        <div class="faqs-content" :class="collapseIndex === index ? 'active' : ''">
          <div class="content-text">{{ wenti.neirong }}</div>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 100px;"></div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { showFailToast, showSuccessToast, showToast, showDialog } from 'vant'
import { Vue3SeamlessScroll } from 'vue3-seamless-scroll'
import { useI18n } from 'vue-i18n'
import { publicApi, userApi } from '../apis/index'
import HomeRecord from '../components/HomeRecord.vue'
import HomeSwipe from '../components/HomeSwipe.vue'
import AccountInfo from '../components/AccountInfo.vue'
import { useCountStore, useHuobiStore } from '@/store'

const { t } = useI18n()
const store = useCountStore()
const huobiStore = useHuobiStore()

const appType = import.meta.env.VITE_APP_TYPE

// loading true 显示 false 隐藏
const showLoading = ref(false)

// 1-用户池 2-USDT账户
const accountType = ref(1)

const changeAccountType = (type) => {
  if (type == 2 && !store.checkedLoginAndShowLogin()) {
    return
  }
  accountType.value = type
}

const baseInfo = ref({
  usersData: {
    zongchanliang: 0.0,
    youxiaojiedian: 0.0,
    canyuzhe: 0,
    shouyi: 0.0
  },
  usdtAccount: {
    zhanghu: 0.0,
    chuxushouyi: 0.0,
    jinrishouyi: 0,
    licaishouyi: 0.0
  },
  yingliJilu: [],
  changjianWenti: [],
  rechargeUsdtERC20Address: '',
  gonggao: {
    title: '',
    content: ''
  }
})

// 记录
const records = ref([])
const page = ref(1)

const collapseIndex = ref(0)

const showSystemGonggao = ref(false)
onMounted(async () => {
  showLoading.value = true
  const resultBaseinfo = await publicApi.getBaseInfo()
  if (resultBaseinfo.code == 0) {
    if (resultBaseinfo.data.gonggao) {
      showSystemGonggao.value = true
    } else {
      resultBaseinfo.data.gonggao = {
        title: '',
        content: ''
      }
    }

    baseInfo.value = resultBaseinfo.data

    console.log(baseInfo.value)

    store.setServiceUrl(resultBaseinfo.data.serviceUrl)
  }

  loadRecord(1, 5)
  updateBalance()
  showLoading.value = false
  // const result_1 = await userApi.login()

  // if (result_1.code == 0) {
  //   store.setUserinfo(result_1.data)
  //   loadRecord(1, 5)
  //   updateBalance()
  // } else {
  //   showFailToast(t(result_1.msg))
  // }
})

const showUserGonggao = ref(0)
// 更新余额
const updateBalance = () => {
  userApi.getUserInfo().then((result) => {
    showLoading.value = false
    if (result.code === 0) {
      store.updateUserinfo(result.data)
      baseInfo.value.usdtAccount = result.data.usdtAccount

      if (result.data.gonggao && showUserGonggao.value == 0) {
        showDialog({
          title: result.data.gonggao.title,
          message: result.data.gonggao.content,
          confirmButtonText: t('Confirm')
        }).then(() => {
          showUserGonggao.value = 1
        })
      }

      setTimeout(() => {
        updateBalance()
      }, 10000) // 设定10秒更新一次余额
    }
  })
}

const loadRecord = (page, limit) => {
  showLoading.value = true
  userApi.getRecordList(page, limit, null).then((recordResult) => {
    showLoading.value = false
    if (recordResult.code == 0) {
      records.value = recordResult
    }
  })
}

const router = useRouter()
const goPage = (name) => {
  switch (name) {
    case 'HomeBankCard':
    case 'IdEntity':
    case 'QuickRecharge':
      if (!store.checkedLoginAndShowLogin()) {
        return
      }
      break;
  }
  window.scrollTo(0, 0)
  huobiStore.showMarketSheet = false
  router.push({
    name
  })
}

// ETH兑换USDT
const convertAmount = ref('')
const convertCurrency = () => {
  if (!checkedAuthentication()) {
    return
  }

  showLoading.value = true
  userApi.convertCurrency(convertAmount.value).then((result) => {
    showLoading.value = false
    if (result.code == 0) {
      showSuccessToast(t(result.msg))
    } else {
      showFailToast(t(result.msg))
    }
  })
}



//  提取USDT
const withdrawalAmount = ref('')
const withdrawal = () => {
  if (!checkedAuthentication()) {
    return
  }

  if (appType != '3' && !store.bankName) {
    showDialog({
      message: t('PleaseBindYourBankCardBeforeWithdrawingMoney'),
      confirmButtonText: t('Confirm')
    }).then(() => {
      goPage('HomeBankCard')
    })
    return
  }
  if (appType == '3' && !store.usdtERC20) {
    showDialog({
      message: t('PleaseBindYourBankCardBeforeWithdrawingMoney'),
      confirmButtonText: t('Confirm')
    }).then(() => {
      goPage('HomeBankCard')
    })
    return
  }

  showLoading.value = true
  userApi.withdrawal(withdrawalAmount.value).then((result) => {
    showLoading.value = false
    console.log(result)
    if (result.code == 0) {
      showSuccessToast(t(result.msg))
    } else {
      showFailToast(t(result.msg))
    }
  })
}

/**
 * 检查是否通过身份验证
 */
const checkedAuthentication = () => {
  const appType = import.meta.env.VITE_APP_TYPE
  if (appType == '2') {
    return true
  }
  if (store.authentication != '4') {
    showFailToast(t('PleaseCompleteTheIdentityAuthenticationFirst'))
    goPage('IdEntity')
    return false
  }

  return true
}
</script>
<style lang="scss" scoped>
::v-deep .van-field__control {
  color: white;
}

.vue-scroll {
  height: 260px;
  width: 100%;
  overflow: hidden;
}

.vue-scroll .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
}

.card-item {
  background: url('@/assets/bg.png') no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.account-container {
  background-color: #1b2130;
  border-radius: 4px;
  height: 46px;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &>div {
    flex: 1;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &>.active {
    background-color: #51e7d3;
    color: #1b2130;
    font-size: 15px;
    border-radius: 4px;
  }
}

.subtotal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #1b2130;
  margin: 10px 0;

  &>div {
    width: 50%;
    box-sizing: border-box;
    text-align: center;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>p {
      display: block;
      color: #51e7d3;
      font-weight: 600;
      font-size: 18px;
    }

    &>span {
      font-size: 12px;
      font-weight: 400;
      color: #fff;
    }
  }
}

.accounts {
  .action-container {
    background-color: #1b2130;
    border-radius: 4px;
    height: 46px;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    &>div {
      flex: 1;
      text-align: center;
    }

    &>.active {
      background-color: #51e7d3;
      color: #1b2130;
      font-size: 15px;
      border-radius: 4px;
      height: 46px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.amount-left {
  height: 100px;
  width: 100%;
  background-image: url(@/assets/bg-withdraw.ea1f3478.png);
  background-size: cover;
  background-position: 50%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 30px;
  box-sizing: border-box;
}

.amount-input {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0 30px;
  box-sizing: border-box;
  background-color: #1b2130;
  height: 96px;
  border-radius: 4px;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;

  input {
    flex: 2;
    color: #898b98;
    font-size: 14px;
    outline: none;
    border: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
  }

  span {
    flex: 1;
    color: #51e7d3;
    font-size: 12px;
    font-weight: 700;
    text-align: right;
  }
}


.incomes-container {
  background-color: #1b2130;
  border-radius: 4px;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  top: 0;
  -webkit-animation: scrollText1 8s infinite cubic-bezier(1, 0, 0.5, 0);
  animation: scrollText1 8s infinite cubic-bezier(1, 0, 0.5, 0);
}

.faqs-container {
  background-color: #1b2130;
  padding: 5px;
  margin-top: 10px;
  border-radius: 4px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  width: 100%;
  display: block;
}

.faqs-item {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
  align-items: stretch;
  background-color: #0b1222;
  margin: 5px 0;
  border-radius: 4px;
}

.faqs-content {
  overflow: hidden;
  height: 0;

  &.active {
    height: auto;
    transition: height 400ms ease-in-out 0ms,
      -webkit-transform 400ms ease-in-out 0ms, transform 400ms ease-in-out 0ms;
    transform-origin: 50% 50% 0px;
  }

  &>.content-text {
    padding: 12px 15px;
    color: #606266;
    font-size: 14px;
    line-height: 18px;
  }
}

.notice-box {
  width: 500px;
  height: 30px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  border: 1px solid red;
}

.notice-item {
  width: 100%;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
}

.notice-slide-enter-active,
.notice-slide-leave-active {
  transition: all 0.8s linear;
}

.notice-slide-enter {
  top: 30px;
}

.notice-slide-leave-to {
  top: -30px;
}
</style>
