<template>
  <div>
    <div class="tradepanel ">

      <div class="panel f-ajs mt-10px">

        <div class="lpanel">
          <div class="tlbox f-ajs">
            <div class="tlltxt">
              <div class="tttxt">{{ $t('Price') }}</div>
              <div class="tbtxt">({{ huobiStore.kLineType1 }})</div>
            </div>
            <div class="trbox f-ac">
              <div class="tlltxt">
                <div class="tttxt">{{ $t('Number') }}</div>
                <div class="tbtxt">({{ huobiStore.kLineType0 }})</div>
              </div>
            </div>
          </div>
          <div class="updata">
            <div class="upitem f-ajs" v-for="(item, index) in huobiStore.askArr" :key="index">
              <div class="uplbox">{{ item.price }}</div>
              <div class="uprbox">{{ item.num }}</div>
              <div class="upbg" :style="{ 'width': item.rate + '%', 'transition-duration': '1s' }"></div>
            </div>
          </div>

          <div class="updatas" style="margin-bottom: 15px;">
            <div class="upitem f-ajs" v-for="(item, index) in huobiStore.bidArr" :key="index">
              <div class="uplbox">{{ item.price }}</div>
              <div class="uprbox">{{ item.num }}</div>
              <div class="upbg" :style="{ 'width': item.rate + '%', 'transition-duration': '1s' }"></div>
            </div>
          </div>
        </div>


        <div class="rpanel">


          <div class="topbtn f-ajs">
            <van-button type="success" size="small" @click="huobiStore.selectTradeType('buy')">{{
              $t('TradeBuy')
            }}</van-button>
            <van-button type="danger" size="small" @click="huobiStore.selectTradeType('sell')">{{
              $t('TradeSell')
            }}</van-button>
          </div>

          <div class="rpbox f-ajs mt-20px">
            <div class="rplbox" style="color: white;">{{ $t('Available_Balance') }}: </div>
            <div class="rbox f-ac">
              <div class="rtxt" style="color: white;">{{ store.usdtAmount }} {{ huobiStore.kLineType1 }}</div>
            </div>
          </div>


          <van-space direction="vertical" fill class="fill">
            <van-field type="number" input-align="center" v-model="huobiStore.orderNumber"
              @update:model-value="huobiStore.changeNumber">
              <template #extra class="_wn_extra">
                <div>{{ huobiStore.kLineType1 }}</div>
              </template>
            </van-field>
          </van-space>


          <div>
            <van-button v-if="huobiStore.tradeType == 'buy'" type="success" block style="margin-top: 15px;"
              @click="trade" class="block">
              {{ $t('BuyLong') }}
            </van-button>
            <van-button v-if="huobiStore.tradeType == 'sell'" type="danger" block style="margin-top: 15px;"
              @click="trade" class="block">
              {{ $t('BuyLess') }}
            </van-button>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useCountStore, useHuobiStore } from '@/store/index'

import { showFailToast, showToast, showLoadingToast, showSuccessToast } from 'vant'
import { useI18n } from 'vue-i18n'
const appType = import.meta.env.VITE_APP_TYPE;
const { t } = useI18n()
const router = useRouter()
const store = useCountStore()
const huobiStore = useHuobiStore()

const trade = async () => {
  if (!store.checkedLoginAndShowLogin()) {
    return
  }

  if (!checkedAuthentication()) {
    return
  }

  if (!huobiStore.orderNumber) {
    showFailToast(t('Please_enter_the_amount'))
    return
  }

  if (appType == "2") {
    huobiStore.showCreateMarketOrder = true
    return
  }

  huobiStore.showLoading()
  let result = await huobiStore.contractTrade(false)
  huobiStore.hideLoading()

  if (result.code != 0) {
    showFailToast(t(result.msg))
  } else {
    showSuccessToast(t(result.msg))
    store.getUserinfo()
  }
}

/**
 * 检查是否通过身份验证
 */
const checkedAuthentication = () => {
  const appType = import.meta.env.VITE_APP_TYPE
  if (appType == '2') {
    return true
  }

  if (store.authentication != '4') {
    showFailToast(t('PleaseCompleteTheIdentityAuthenticationFirst'))
    window.scrollTo(0, 0)
    router.push({
      name: "IdEntity"
    })
    return false
  }

  return true
}
</script>

<style lang="less" scoped>
.icon {

  width: 14px;
  height: 14px;
}

.line {
  background: #000000;
  padding: 4px;
}

.tradepanel {
  padding: 5px 10px;

  .panel {
    align-items: flex-start;

    .lpanel {
      width: 40%;

      .tlbox {
        color: #4b4f5a;
        line-height: 1.2;
      }

      .updata {
        margin-top: 20px;

        .upitem {
          position: relative;

          .uplbox {
            color: #2ca385;
            line-height: 2;
            z-index: 1;
          }

          .uprbox {
            color: #dcebf2;
            line-height: 2;
            z-index: 1;
          }

          .upbg {
            position: absolute;
            // width: 100%;
            height: 100%;
            background: #16292f;
            z-index: 0;
          }
        }
      }

      .updatas {
        margin-top: 20px;

        .upitem {
          position: relative;

          .uplbox {
            color: #f13d3c;
            line-height: 2;
            z-index: 1;
          }

          .uprbox {
            color: #dcebf2;
            line-height: 2;
            z-index: 1;
          }

          .upbg {
            position: absolute;
            // width: 100%;
            height: 100%;
            background: #2d1b27;
            z-index: 0;
          }
        }
      }

      .botbox {
        .lbox {
          height: 26px;
          background-color: #1e2432;
          color: #4b4f5a;
          width: 80%;
          border-radius: 6px;
          padding: 0 10px;
        }

        .rbox {
          margin-left: 10px;
          flex: 1;
        }
      }

      .totalupdata {
        margin-top: 10px;

        .lbox {
          color: #4b4f5a;

          .tbox {
            color: #2ca385;
            font-size: 18px;
          }
        }

        .rbox {
          color: #4b4f5a;
        }
      }
    }

    .rpanel {
      width: 60%;
      padding-left: 20px;

      .topbtn {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        button {
          width: 49%;
        }
      }



      .rpbox {
        .rplbox {
          color: #4b4f5a;
        }

        .rbox {
          .icon {
            font-size: 14px;
            margin-left: 10px;
          }
        }
      }

      .ganggan {
        // margin-bottom: 15px;
        padding-bottom: 15px;


        .ganggan-text {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 25px 5px;
          color: white;

          .text-left {}

          .text-right {}
        }
      }

      .baozhengjin {
        margin-bottom: 15px;
        // padding-bottom: 15px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 15px 5px;
        color: white;

        .text-left {}

        .text-right {}
      }

      .input {
        background-color: #1e2432;
        border-radius: 6px;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;

        .icon {
          color: #4b4f5a;
        }
      }

      .inputs {
        background-color: #1e2432;
        border-radius: 6px;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;

        .icon {
          color: #4b4f5a;
        }

        .text {
          width: 60%;

          .inp {
            width: 100%;
            border: none;
            background: none;
            text-align: center;
          }
        }
      }

      .buybtn {
        margin-top: 15px;
        border-radius: 6px;
        color: #fff;
        background: #f13d3c;
        text-align: center;
        height: 40px;
        line-height: 40px;
      }

      .sellbtn {
        margin-top: 15px;
        border-radius: 6px;
        color: #fff;
        background: #f13d3c;
        text-align: center;
        height: 40px;
        line-height: 40px;
      }

      .botbox {
        height: 26px;

        .lbox {
          color: #4b4f5a;

          .text {
            padding-left: 10px;
          }
        }

        .rbox {
          color: #4b4f5a;

          .text {
            padding-left: 10px;
          }
        }
      }
    }
  }


}
</style>
