export default {
  English: 'English',
  Chinese: '简体中文',
  TraditionalChinese: '繁體中文',
  Korean: '한국인',
  Japanese: '日本語',
  Vietnamese: 'Tiếng Việt',

  Asset_Management_Savings_Bank: 'Ngân hàng tiền kỹ thuật số',
  Bank_Account: 'Tài khoản ngân hàng',
  Participate_in_savings: 'TÍCH LUỸ',
  Selected_financial_management: 'GIAO DỊCH',
  user_pool: 'Tổng hợp',
  account: 'Tài khoản',
  total_output: 'Tổng sản lượng',
  Valid_node: 'Giao dịch hợp lệ',
  participant: 'Người tham gia',
  income: 'Thu nhập',
  Remaining_amount: 'Thu nhập tiết kiệm',
  savings_income: 'Thu nhập tiết kiệm',
  Todays_earnings: 'Thu nhập hôm nay',
  Financial_management_income: 'Thu nhập hôm nay',
  exchange: 'Trao Đổi',
  recharge: 'Nạp Tiền',
  extract: 'Rút Tiền',
  Record: 'Lịch Sử',
  convertible_income: 'Số Dư',
  exchange_amount: 'Số Tiền Quy Đổi',
  Please_enter_the_exchange_amount: 'Vui lòng nhập số tiền quy đổi',
  total: 'Tất Cả',
  Available_Balance: 'Số dư khả dụng',
  Withdrawal_amount: 'Số tiền rút',
  Please_enter_the_withdrawal_amount: 'Vui lòng nhập số tiền rút',
  Profit_record: 'Kỷ lục lợi nhuận',
  address: 'Địa chỉ',
  number: 'số liệu',
  common_problem: 'Vấn đề thường gặp',
  Previous_page: 'Trang trước',
  Next_page: 'Trang tiếp theo',
  Audit_Report: 'Báo cáo kiểm toán',
  We_have_a_security_audit_report: 'Chúng tôi có một báo cáo kiểm tra an ninh',
  partner: 'Cộng Sự',
  our_business_partners: 'đối tác kinh doanh của chúng tôi',
  expenditure: 'chi phí',
  amount: 'Tài khoản',
  remark: 'Nhận xét',
  bankNumberName: 'Chi nhánh ngân hàng',
  Quote_change: 'Thay đổi báo giá',
  Highest: 'Cao nhất',
  My_Order: 'Đơn hàng của tôi',
  Buy: 'Xác Nhận',
  Kline_chart: 'Biểu đồ đường K',
  Please_select_contract_time: 'Vui lòng chọn thời gian hợp đồng',
  purchasing_price: 'giá mua',
  Please_enter_the_purchase_amount: 'Vui lòng nhập số tiền mua',
  savings: '貯蓄単位',
  Financial_institution_name: 'Tên tổ chức tài chính',
  Please_enter: 'vui lòng nhập',
  Branch_name: 'Tên ngân hàng',
  branch_number: 'Số tài khoản ngân hàng',
  zhanghao: 'tài khoản',
  Account_holder: 'Chủ tài khoản',
  UsdtERC20Address: 'Usdt ERC20 Địa chỉ',
  binding: 'Xác Nhận',
  Invite_friends: 'Mời bạn bè',
  CopiedSuccessfully: 'Đã sao chép thành công',
  Copy_failed: 'Sao chép không thành công',
  Please_enter_the_amount: 'Vui lòng nhập số tiền',
  Select_storage_period: 'Chọn thời gian lưu trữ',
  money: 'Số Lượng',
  USDT: 'USDT',
  Daily_rate_of_return: 'Lãi Suất',
  savings_products: 'Sản phẩm tích luỹ',
  Currency: 'Tiền tệ',
  Saving_amount: 'Số tiền tích luỹ',
  PleaseEnterTheCorrectAmount: 'Vui lòng nhập đúng số tiền',
  NotOpenYet: 'Chưa mở',
  Earnings: 'Thu nhập',
  Expenses: 'Chi phí',
  Confirm: 'Xác Nhận',
  Cancel: 'Hủy bỏ',
  Copy: 'sao chép',
  RechargeAmount: 'Số lượng nạp lại',
  PleaseEnterTheRechargeAmount: 'Vui lòng nhập số tiền nạp',
  RechargeERC20Address: 'UsdtERC20 Địa chỉ nạp tiền',
  Submit: 'NẠP',

  YourAccountHasBeenDisabled: 'tài khoản của bạn đã bị vô hiệu hóa!',
  InformationUpdatedSuccessfully: 'Thông tin được cập nhật thành công',
  YourAccountIsFrozen: 'Tài khoản của bạn bị đóng băng!',
  ProductDoesNotExist: 'Sản phẩm không tồn tại',
  ThePurchaseAmountMustBeLessThanTheProductAmount: 'Số tiền mua phải nhỏ hơn số tiền sản phẩm',
  InsufficientAccountBalance: 'Số dư tài khoản không đủ',
  WithdrawalRequestHasBeenSubmitted: 'Yêu cầu rút tiền đã được gửi',
  RechargeRequestHasBeenSubmitted: 'Yêu cầu nạp tiền đã được gửi',
  AmountEnteredIncorrectly: 'Nhập sai số tiền!',
  Successfully: 'thành công',
  Day: 'ngày',
  BuyFailed: 'Mua hàng không thành công',
  BuySuccessfully: 'Mua thành công',

  Withdrawal: 'Rút tiền',
  PurchaseProduct: 'mua sản phẩm',
  RegistrationTrialBonus: 'Tiền thưởng nhà đầu tư mới',
  CurrencyConversion: 'Chuyển đổi ngoại tệ',
  SavingsIncome: 'Thu nhập tiết kiệm',
  FinancialIncome: 'Đầu tư -Bán',
  SavingsRelease: 'phát hành tiết kiệm',
  BindingSuccessful: 'Ràng buộc thành công',
  ViewThisAccount: 'Xem tài khoản này',
  NoWalletEnv: 'Vui lòng sử dụng trình duyệt ví để mở hệ thống này!',
  PleaseBindYourBankCardBeforeWithdrawingMoney: 'Vui lòng liên kết thẻ ngân hàng của quý khách trước khi rút tiền !',
  天: 'Ngày',
  周: 'Tuần',
  月: 'Một',
  年: 'Năm',
  ContractTrading: 'ETH/USDT',
  '1Min': '1 phút',
  '5Min': '5 phút',
  '1Hour': '1 giờ',
  '1Day': '1 ngày',

  Loading: 'Đang tải',
  ContractTimeError: 'Hợp đồngThời gianLỗi',
  ContractTypeError: 'Loại hợp đồngLỗi',
  ContractNotSupported: 'Hợp đồngKhông được hỗ trợ',
  YouHaveAContractOrderInProgressButTheOrderFailed: 'Lỗi',
  NotOrder: 'Không đặt hàng',
  PleaseSelectContractTime: 'Vui lòng chọn thời gian hợp đồng',
  PleaseEnterThePurchaseAmount: 'Nhập số lượng',
  BuyLong: 'MUA LÊN',
  BuyLess: 'MUA XUỐNG',
  PurchasingPrice: 'Giá mua',
  Gain: 'Lợi nhuận',
  Loss: 'Tổn thất',
  Ok: 'Xác nhận',
  ContractOrder: 'Lệnh hợp đồng',
  ContractPrincipal: 'Hợp đồng gốc',
  WithdrawalReturn: 'Hoàn tiền',

  OrderStatusError: 'Lỗi trạng thái đơn hàng!',
  HighestPrice: 'Giá cao nhất',
  LowestPrice: 'Giá thấp nhất',
  TradingVolume: 'Khối lượng giao dịch',
  TransactionVolume: 'Khối lượng giao dịch',
  Position: 'Vị thế',
  CommissionRecord: 'Hoa hồngKỷ lục',
  ProfitLoss: 'Mất lợi nhuận',
  OpeningPrice: 'Giá mở cửa',
  CurrentPrice: 'Giá hiện tại',
  PositionQuantity: 'Chức vụSố lượng',
  ClosePosition: 'ĐóngVị trí',
  Service: 'Dịch vụ',
  ContractList: 'Danh sách hợp đồng',
  LatestPrice: 'Giá mới nhất',
  PleaseEnterTheSearchKeyword: 'Vui lòngNhậpTừ khóaTìm kiếm',
  Number: 'Con số',
  NoData: 'Không có dữ liệu',
  Price: 'Giá',

  TradeBuy: 'MUA',
  TradeSell: 'BÁN',
  Security: 'Bảo vệ',
  Lever: 'ĐÒN BẨY',
  Chart: 'ĐỒ THỊ',
  Trading: 'XÁC NHẬN',
  Cross: 'Đi qua',
  Isolated: 'Bị cô lập',
  WalletLogin: 'ĐĂNG NHẬP',
  Username: 'Tên tài khoản',
  Password: 'Mật khẩu',
  ConfirPassword: 'Xác nhận mật khẩu',
  PleaseEnterTheUsername: 'Vui lòng nhập tên người dùng!',
  PleaseEnterThePassword: 'Xin vui lòng nhập mật khẩu!',
  PleaseEnterTheConfirPassword: 'Vui lòngNhậpTheConfirMật khẩu!',
  Logout: 'Đăng xuất',
  ConfirPasswordError: 'Xác nhậnMật khẩuLỗi!',
  UsernameAlreadyExists: 'Tên này đã có người dùng!',
  InsufficientAccountSecurity: 'Không đủ Tài khoảnBảo mật!',
  AlreadyHaveAnAccountClickToLogIn: 'Đã có tài khoản Click để đăng nhập',
  DontHaveAnAccountYetClickToRegister: 'Chưa có tài khoản click để ĐĂNG KÝ',

  PleaseCompleteTheIdentityAuthenticationFirst: 'Vui lòng hoàn tất xác minh danh tính trước!',
  AuthenticationBindingSuccessful: 'Liên kết xác thực thành công!',
  WaitingReview: 'Đang chờ',
  Next: 'Bước tiếp theo',
  Jump: 'Bỏ qua',
  RegisterAccount: 'Đăng ký',
  Authentication: 'Xác thực',
  SecureBinding: 'An toàn',
  Finish: 'Hoàn thành',

  Name: 'Tên',
  PleaseTypeInYourName: 'Vui lòng nhập tên của bạn',
  IdNumber: 'Số ID',
  PleaseTypeInYourIdNumber: 'Vui lòng nhập số nhận dạng',
  FrontOfDocument: 'Mặt trước ảnh',
  ReverseSideOfId: 'Mặt sau ảnh',
  HoldingIdPhoto: 'Ảnh cầm ID',

  UsdtAddress: 'Địa chỉ USDT',
  PleaseTypeInYourUsdtAddress: 'Xin mời nhập địa chỉ USDT',
  Failed: 'thất bại',
  ClosePositionPrice: 'Giá đóng cửa',
  PositionClosed: 'Vị thế đã đóng',

  QuickRecharge: 'Nạp tiền nhanh',
  CopyRechargeAddress: 'Sao chép địa chỉ nạp tiền',

  Login: 'ĐĂNG NHẬP',
  Email: 'Email',
  PleaseEnterTheEmail: 'Hãy điền địa chỉ email của bạn',

  PleaseEnterTheVerifyCode: 'Vui lòng nhập mã xác minh email của bạn',
  EmailCode: 'Mã xác minh email',
  GetCode: 'lấy mã xác minh',
  SwitchLanguage: 'Lựa chọn ngôn ngữ',
  RegisterType: 'loại đăng ký',
  RegisterSuccess: 'đăng ký thành công',
  PasswordError: 'sai mật khẩu',

  PleaseEnterTheTransferAddress: 'Vui lòng nhập địa chỉ chuyển khoản của bạn',
  TransferVoucher: 'Chứng từ chuyển khoản',
  OpenPosition: 'Mở một vị trí',
  BuyToOpenLong: 'Mua để mở lâu',
  SellToOpenShort: 'bán khống',
  BuyShort: 'bán dài',
  SellLong: 'mua ngắn',
  Kepingduo: 'Kepingduo',
  Kepingkong: 'Có thể đóng cửa',

  PleaseTypeInYourETHAddress: 'Vui lòng nhập địa chỉ ETH của bạn',
  PleaseTypeInYourBTCAddress: 'Vui lòng nhập địa chỉ BTC của bạn',
  Save: 'cứu',
  SecurityRate: 'tỷ lệ ký quỹ',
  Handling: 'phí xử lý',
  Zhang: 'mở',
  SecurityUnfreeze: 'Giải phóng bảo mật',
  AccountError: 'Lỗi tài khoản!',

  jiaoyileixing: 'Loại giao dịch:',
  Account_holder_jiaming: 'Tên tài khoản (kana)'
}
