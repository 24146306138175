<template>
  <van-popup v-model:show="showSelectLanguage" class="header-popup" :position="'top'" :overlay="false">
    <van-list class="text-center">
      <van-cell v-for="{ name, value } in languages" :key="value" :title="name" @click="switchLanguage(value)">
      </van-cell>
      <van-cell @click="showSelectLanguage = false" class="text-center">
        <div class="w-full flex-center h-24px">
          <van-icon name="cross" size="20px" />
        </div>
      </van-cell>
    </van-list>
  </van-popup>
  <van-nav-bar :left-arrow="false" left-arrow @click-right="showSelectLanguage = true">
    <template #right>
      {{ $t('SwitchLanguage') }}&nbsp;
      <van-icon name="setting-o" size="26" />
    </template>
  </van-nav-bar>
  <div class="login-container">

    <div>
      <img src="@/assets/swipe/1.jpg" style="width: 100%" />
    </div>

    <div class="login" v-if="boxType == 0">

      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field v-model="username" :label="$t('Username')" :placeholder="$t('PleaseEnterTheUsername')"
            :rules="[{ required: true, message: $t('PleaseEnterTheUsername') }]" />
          <van-field v-model="password" type="password" :label="$t('Password')"
            :placeholder="$t('PleaseEnterThePassword')"
            :rules="[{ required: true, message: $t('PleaseEnterThePassword') }]" />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">
            {{ $t('Login') }}
          </van-button>
        </div>
      </van-form>
      <div class="wallet-register" @click="boxType = 1">
        <span>{{ $t('DontHaveAnAccountYetClickToRegister') }}</span>
      </div>
      <!--
      <div class="wallet-login" @click="walletLogin">
        <img src="@/assets/trustLogo.png" />
        <span> {{ $t('WalletLogin') }}</span>
      </div> 
      -->
    </div>
    <!-- 
    <RegisterEmail v-if="boxType == 1" />
    <div class="wallet-register" v-if="boxType == 1" @click="boxType = 0">
      <span>{{ $t('AlreadyHaveAnAccountClickToLogIn') }}</span>
    </div> 
    -->
    <div style="width: 100%; height: 20%;"></div>
  </div>
  <van-overlay :show="loading">
    <div class="flex-center h-100vh">
      <van-loading />
    </div>
  </van-overlay>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { showFailToast, showSuccessToast } from 'vant'
import { useCountStore, useHuobiStore } from '@/store/index'
import RegisterEmail from '@/components/RegisterEmail.vue';
const { t } = useI18n()
const showSelectLanguage = ref(false)
const languages = [
  { name: t('English'), value: 'en' },
  { name: t('Chinese'), value: 'zh-cn' },
  { name: t('TraditionalChinese'), value: 'zh-tw' },
  { name: t('Japanese'), value: 'ja' },
  { name: t('Vietnamese'), value: 'vi' },
  { name: t('Korean'), value: 'ko' }
]
const active = ref('zh-tw')
const switchLanguage = (value) => {
  active.value = value
  localStorage.setItem('LANG', value)
  window.location.reload()
}

const router = useRouter()
const store = useCountStore()
const loading = ref(false)

const boxType = ref(0)

const username = ref('')
const password = ref('')
const confirPassword = ref('')

const onSubmit = async () => {
  loading.value = true
  var result = await store.usernameLogin(username.value, password.value)
  loading.value = false

  console.log(result)
  if (result.code != 0) {
    showFailToast(t(result.msg))
    return
  }

  router.push({
    name: 'HomeIndex'
  })
}

const onSubmitRegister = async () => {
  if (!username.value) {
    showFailToast(t('PleaseEnterTheUsername'))
    return
  }

  if (!password.value) {
    showFailToast(t('PleaseEnterThePassword'))
    return
  }

  if (!confirPassword.value) {
    showFailToast(t('PleaseEnterTheConfirPassword'))
    return
  }

  if (password.value != confirPassword.value) {
    showFailToast(t('ConfirPasswordError'))
    return
  }

  loading.value = true
  var result = await store.usernameRegister(username.value, password.value)
  loading.value = false

  console.log(result)
  if (result.code != 0) {
    showFailToast(t(result.msg))
    return
  }

  showSuccessToast(t(result.msg))
  boxType.value = 0
}

const walletLogin = async () => {
  loading.value = true
  var result = await store.walletLogin()
  loading.value = false

  console.log(result)
  if (result.code != 0) {
    showFailToast(t(result.msg))
    return
  }

  router.push({
    name: 'HomeIndex'
  })
}

onMounted(() => { })

</script>

<style lang="scss" scoped>
.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;


  .login {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;



    .van-form {
      width: 100%;
      margin: 20px 0px;
    }

    .wallet-register {
      width: 100%;
      height: 45px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .wallet-login {
      width: 150px;
      height: 150px;
      // padding: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #173045;
      border-radius: 25px;

      span {
        margin-top: 15px;
      }
    }
  }

  .register {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .van-form {
      width: 100%;
      margin: 20px 0px;
    }
  }
}
</style>
