<template>
  <van-cell-group>
    <van-field v-model="name" :label="$t('Name')" :placeholder="$t('PleaseTypeInYourName')" @blur="updateData" />
    <van-field v-model="identity" :label="$t('IdNumber')" :placeholder="$t('PleaseTypeInYourIdNumber')"
      @blur="updateData" />
  </van-cell-group>

  <div class="upload-box">
    <div class="upload-item">
      <van-uploader class="upload" :before-read="beforeRead1" :after-read="uploadImg1">
        <img v-if="store.img1 != null" :src="(baseUrl + store.img1)" style="width: 80px; height: 80px;" />
        <van-icon v-else name="photo" class="upload-icon" />
      </van-uploader>
      <div class="upload-text">
        {{ $t('FrontOfDocument') }}
      </div>
    </div>
    <div class="upload-item">
      <van-uploader class="upload" :before-read="beforeRead2" :after-read="uploadImg2">
        <img v-if="store.img2" :src="(baseUrl + store.img2)" style="width: 80px; height: 80px;" />
        <van-icon v-else name="photo" class="upload-icon" />
      </van-uploader>
      <div class="upload-text">
        {{ $t('ReverseSideOfId') }}
      </div>
    </div>
    <div class="upload-item">
      <van-uploader class="upload" :before-read="beforeRead3" :after-read="uploadImg3">
        <img v-if="store.img3" :src="(baseUrl + store.img3)" style="width: 80px; height: 80px;" />
        <van-icon v-else name="photo" class="upload-icon" />
      </van-uploader>
      <div class="upload-text">
        {{ $t('HoldingIdPhoto') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useCountStore, useHuobiStore } from '@/store/index'
import { showFailToast, showToast, showLoadingToast, showSuccessToast } from 'vant'
import { useI18n } from 'vue-i18n'
import { publicApi, userApi } from '@/apis/index'

const { t } = useI18n()
const route = useRoute()
const store = useCountStore()
const huobiStore = useHuobiStore()

const baseUrl = ref("")
const name = ref("")
const identity = ref("")

const img1 = ref("")
const img2 = ref("")
const img3 = ref("")

onMounted(() => {
  baseUrl.value = import.meta.env.VITE_APP_AXIOS_BASE_URL + "wwwroot";

  name.value = store.name
  identity.value = store.identity
  img1.value = store.img1
  img2.value = store.img2
  img3.value = store.img3
})

const uploadImg1 = (result) => { console.log(result); }
const uploadImg2 = (result) => { console.log(result); }
const uploadImg3 = (result) => { console.log(result); }

const updateData = () => {
  store.setIdentity(name.value, identity.value, img1.value, img2.value, img3.value)
}


const beforeRead1 = async (file) => {
  var result = await publicApi.uploadImg(file)

  if (result.data.code == 0) {
    img1.value = result.data.data.fileName
    store.setIdentity(name.value, identity.value, img1.value, img2.value, img3.value)
  }

  return true
}
const beforeRead2 = async (file) => {
  var result = await publicApi.uploadImg(file)

  if (result.data.code == 0) {
    img2.value = result.data.data.fileName
    store.setIdentity(name.value, identity.value, img1.value, img2.value, img3.value)
  }

  return true
}
const beforeRead3 = async (file) => {
  var result = await publicApi.uploadImg(file)

  if (result.data.code == 0) {
    img3.value = result.data.data.fileName
    store.setIdentity(name.value, identity.value, img1.value, img2.value, img3.value)
  }

  return true
}

</script>

<style lang="less" scoped>
.upload-box {
  margin: 15px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .upload-item {
    .upload {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0px;
      padding: 5px;
      border: 1.5px dashed #848484;

      .upload-icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 80px;
      }
    }

    .upload-text {
      margin-top: 8px;
      text-align: center;
    }
  }
}
</style>
