<template>
  <div>
    <van-nav-bar left-arrow :title="$t('savings_products')" @click-left="onClickLeft"  class="left-arrow"/>
    <div class="p-12px bg-_wn_000 rounded-4px text-white text-14px">
      <div class="flex items-center h-42px">
        <img src="@/assets/logo.png" class="w-22px h-22px mr-4px ml-8px alt srcset" alt="" srcset="" />
        <span>{{ product?.title }}</span>
      </div>
      <div class="flex text-center">
        <div v-if="appType != '3'" class="flex-1">{{ $t('money') }}</div>
        <div v-if="appType == '3'" class="flex-1">{{ $t('USDT') }}</div>
        <div class="flex-1">{{ $t('Daily_rate_of_return') }}</div>
      </div>
      <div class="flex text-center text-_wn_51e7d3">
        <div class="flex-1">
          {{ product?.moneyMin + '-' + product?.moneyMax }}
        </div>
        <div class="flex-1">{{ product?.profit + '%' }}</div>
      </div>
      <div class="lh-42px">{{ $t('Select_storage_period') }}</div>
      <van-radio-group v-model="selectedCycle" :max="1" direction="horizontal">
        <van-radio v-if="appType != '3'" :name="1" class="text-_wn_fff">
          {{ product?.profitDateNumber }}
          {{ $t(product?.dateType + '') }}
        </van-radio>

        <van-radio v-if="appType == '3' && product?.profitDateNumber == 1 && product?.dateType == '天'" :name="1"
          class="text-_wn_fff">
          24 hours
        </van-radio>
        <!-- <van-radio :name="7">7天</van-radio> -->
      </van-radio-group>
      <div v-if="appType != '3'" class="lh-32px mt-10px">{{ $t('money') }}</div>
      <div v-if="appType == '3'" class="lh-32px mt-10px">{{ $t('Amount') }}</div>
      <div class="w-full flex-between-center px-12px bg-blue text-_wn_fff rounded-4px h-42px">

        <van-field type="number" v-model="money" :placeholder="$t('Please_enter_the_amount')"
          style="background: rgb(0 0 0 / 0%);">
          <template>
            <span class="w-auto">USDT</span>
          </template>
        </van-field>
      </div>
      <div class="action-button" @click="buy">{{ $t('Buy') }}</div>
    </div>
    <van-overlay :show="showLoading">
      <div class="flex-center h-100vh">
        <van-loading />
      </div>
    </van-overlay>
  </div>
</template>

<script setup lang="ts" name="ProductOrder">
import { ref, onMounted } from 'vue'
import { showFailToast, showSuccessToast, showToast } from 'vant'
import { Product } from '../@types/product'
import { publicApi, userApi } from '../apis/index'
import { useCountStore } from '@/store';
const router = useRouter()
const Router = useRoute()
const { t } = useI18n()
const store = useCountStore()

const onClickLeft = () => {
  router.push({
    name: "HomeDeposit"
  })
}
const selectedCycle: any = ref(1)
const money: any = ref('')
const product: any = ref<Product>()
const showLoading = ref<boolean>(false)


const padRight = (n, len, charStr) => {
  var s = n + '';
  if (s.indexOf('.') < 0) {
    return s + '.0000';
  }

  let index = s.indexOf('.')
  let left = s.substring(0, index)

  let right = s.substring(index, s.length - index)

  let appendNumber = right.length - len

  for (let index = 0; index < appendNumber; index++) {
    right += charStr
  }

  return left + right
}
const appType = import.meta.env.VITE_APP_TYPE;

onMounted(() => {
  product.value = Router.query
})

const buy = () => {
  if (!store.checkedLoginAndShowLogin()) {
    return
  }
  if (!checkedAuthentication()) {
    return
  }
  let amount = parseInt(money.value)
  let min = parseInt(product.value!.moneyMin)
  let max = parseInt(product.value!.moneyMax)
  if (
    amount <= 0 ||
    amount < min ||
    amount > max
  ) {
    showFailToast(t('PleaseEnterTheCorrectAmount'))
    return
  }

  showLoading.value = true
  userApi.buyProduct(product.value!.id, money.value, 1).then((result: any) => {
    showLoading.value = false

    if (result.code !== 0) {
      showFailToast(t(result.msg))
      return
    }

    showSuccessToast(t(result.msg))
    window?.history.back()
  })
}

/**
 * 检查是否通过身份验证
 */
const checkedAuthentication = () => {
  const appType = import.meta.env.VITE_APP_TYPE
  if (appType == '2') {
    return true
  }


  if (store.authentication != '4') {
    showFailToast(t('PleaseCompleteTheIdentityAuthenticationFirst'))
    window.scrollTo(0, 0)
    router.push({
      name: "IdEntity"
    })
    return
  }
  return true
}
</script>

<style lang="scss" scoped>
input::input-placeholder {
  color: #fff;
}

input::-webkit-input-placeholder {
  //兼容WebKit browsers（Chrome的内核）
  color: #fff;
}

input::-moz-placeholder {
  //Mozilla Firefox 4 to 18
  color: #fff;
}

input::-moz-placeholder {
  //Mozilla Firefox 19+
  color: #fff;
}

input::-ms-input-placeholder {
  //Internet Explorer 10+
  color: #fff;
}

input {
  outline: 0;
  border: 0;
}

::v-deep .van-radio__label {
  color: #fff;
}

.amount-left {
  height: 100px;
  width: 100%;
  background-image: url(@/assets/bg-withdraw.ea1f3478.png);
  background-size: cover;
  background-position: 50%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 7px;
  box-sizing: border-box;
}

.detail-icon {
  transform: rotate(90deg);
  color: #51e7d3;
}
</style>
