<template>
  <div class="register">
    <van-form @submit="onSubmitRegister" :label-width="120">
      <van-cell-group inset class="inset">
        <!-- <van-field name="radio" :label="$t('RegisterType')">
          <template #input class="_wn_input">
            <van-radio-group direction="horizontal" v-model="registerType" @change="changeRegisterType">
              <van-radio name="0">{{ $t('Email') }}</van-radio>
              <van-radio name="1">{{ $t('Username') }}</van-radio>
            </van-radio-group>
          </template>
</van-field> -->

        <template v-if="registerType == '0'">
          <van-field v-model="email" :label="$t('Email')" :placeholder="$t('PleaseEnterTheEmail')"
            :rules="[{ required: true, message: $t('PleaseEnterTheEmail') }]" />

          <van-field v-model="code" center clearable :label="$t('EmailCode')"
            :placeholder="$t('PleaseEnterTheVerifyCode')" class="center clearable">
            <template #button class="_wn_button">
              <van-button size="small" type="primary" @click="getEmailCode" :disabled="daojishi > 0 ? true : false">
                {{ daojishi > 0 ? daojishi : $t('GetCode') }}
              </van-button>
            </template>
          </van-field>
        </template>

        <template v-if="registerType == '1'">
          <van-field v-model="username" :label="$t('Username')" :placeholder="$t('PleaseEnterTheUsername')"
            :rules="[{ required: true, message: $t('PleaseEnterTheUsername') }]" />
        </template>


        <van-field v-model="password" type="password" :label="$t('Password')"
          :placeholder="$t('PleaseEnterThePassword')"
          :rules="[{ required: true, message: $t('PleaseEnterThePassword') }]" />

        <van-field v-model="confirPassword" type="password" :label="$t('ConfirPassword')"
          :placeholder="$t('PleaseEnterThePassword')"
          :rules="[{ required: true, message: $t('PleaseEnterTheConfirPassword') }]" />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit" class="round block">
          {{ $t('Submit') }}
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useCountStore, useHuobiStore } from '@/store'
import { showFailToast, showSuccessToast } from 'vant/lib/toast';
import { publicApi } from '@/apis';
import router from '@/router';
const { t } = useI18n()
const huobiStore = useHuobiStore()
const store = useCountStore()

const registerType = ref('1')
const username = ref('')
const email = ref('')
const code = ref('')
const password = ref('')
const confirPassword = ref('')

const daojishi = ref(0)

const changeRegisterType = (type) => {
  console.log(type)
}

const jishi = () => {
  daojishi.value--
  if (daojishi.value > 0) {
    setTimeout(jishi, 1000)
  }
}

const getEmailCode = async () => {
  if (!email.value) {
    showFailToast(t('PleaseEnterTheEmail'))
    return
  }

  huobiStore.showLoading()
  var result = await publicApi.getEmailCode(email.value);
  huobiStore.hideLoading()
  if (result.code != 0) {
    showFailToast(t(result.msg))
    return
  }

  showSuccessToast(t(result.msg))

  daojishi.value = 60;
  jishi()
}

const onSubmitRegister = async () => {

  if (!password.value) {
    showFailToast(t('PleaseEnterThePassword'))
    return
  }

  if (!confirPassword.value) {
    showFailToast(t('PleaseEnterTheConfirPassword'))
    return
  }

  if (password.value != confirPassword.value) {
    showFailToast(t('ConfirPasswordError'))
    return
  }

  var result = {}
  if (registerType.value == '0') {
    if (!email.value) {
      showFailToast(t('PleaseEnterTheEmail'))
      return
    }

    if (!code.value) {
      showFailToast(t('PleaseEnterTheVerifyCode'))
      return
    }


    huobiStore.showLoading()
    result = await store.emailRegister(email.value, code.value, password.value)
    huobiStore.hideLoading()
  }

  if (registerType.value == '1') {
    if (!username.value) {
      showFailToast(t('PleaseEnterTheUsername'))
      return
    }

    huobiStore.showLoading()
    result = await store.usernameRegister(username.value, password.value)
    huobiStore.hideLoading()
  }



  console.log(result)
  if (result.code != 0) {
    showFailToast(t(result.msg))
    return
  }

  showSuccessToast(t(result.msg))
  window.location.reload()
}

</script>

<style lang="scss" scoped>
.register {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .van-form {
    width: 100%;
    margin: 0px 0px;
  }
}
</style>