<template>
  <div class="eth-web">
    <h1>{{ $t('NoWalletEnv') }}</h1>
  </div>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'

onMounted(() => {
  const route = useRoute()
  console.log(route.params)
})
</script>

<style>
.eth-web {
  width: 100vw;
  height: 100vh;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

html,
body {
  height: 100vh;
}

#app {
  height: 100vh;
}
</style>
