<template>
  <div class="actions action-change">
    <van-overlay :show="showLoading" @click="showLoading = false">
      <div class="flex-center h-100vh">
        <van-loading />
      </div>
    </van-overlay>
    <div class="withdraw-container mt-10px">
      <div class="amount-left" style="height: 210px;">
        <span>{{ $t('RechargeERC20Address') }}</span>
        <span class=" text-20px font-700 text-_wn_51e7d3" style="margin-top: 10px; word-wrap: anywhere;">{{
          props.address
        }}</span>
        <div>
          <div class="action-button copyAddress" style="width: 80px;" @click="copyText">
            {{ $t('Copy') }}
          </div>
        </div>

      </div>
      <div class="amount-input" v-if="appType != '2'">
        <span>{{ $t('RechargeAmount') }}</span>
        <div class="input-container">
          <van-field type="number" v-model="rechargeAnount" :placeholder="$t('PleaseEnterTheRechargeAmount')"
            style="background: rgb(0 0 0 / 0%);">
          </van-field>
        </div>
      </div>
    </div>
    <div class="action-button" @click="submitRechargeERC20" v-if="appType != '2'">
      {{ $t('Submit') }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import Clipboard from 'clipboard'
import { showFailToast, showSuccessToast } from 'vant'
import { useI18n } from 'vue-i18n'
// @ts-ignore
import { useCountStore } from '@/store'
import { publicApi, userApi } from '../apis/index'
const appType = import.meta.env.VITE_APP_TYPE
// loading true 显示 false 隐藏
const showLoading = ref(false)

const store = useCountStore()
const { t } = useI18n()
const router = useRouter()
const rechargeAnount: any = ref('')

const props = defineProps({
  address: String
})

const copyText = async () => {
  try {
    var erc20Address = props.address
    let clipboard = new Clipboard('.copyAddress', {
      text: () => {
        return erc20Address
      }
    })

    clipboard.on('success', () => {
      showSuccessToast(t('CopiedSuccessfully'))
    })

    clipboard.on('error', () => {
      showFailToast(t('Copy_failed'))
    })
  } catch (error) { console.log(error) }
}

const submitRechargeERC20 = () => {
  if (!checkedAuthentication()) {
    return
  }
  userApi.recharge(rechargeAnount.value, props.address).then((result) => {
    showLoading.value = false
    if (result.code == 0) {
      showSuccessToast(t(result.msg))
      rechargeAnount.value = ''
    } else {
      showFailToast(t(result.msg))
    }
  })
}

/**
 * 检查是否通过身份验证
 */
const checkedAuthentication = () => {
  const appType = import.meta.env.VITE_APP_TYPE
  if (appType == '2') {
    return true
  }

  if (store.authentication != '4') {
    showFailToast(t('PleaseCompleteTheIdentityAuthenticationFirst'))
    window.scrollTo(0, 0)
    router.push({
      name: "IdEntity"
    })
    return false
  }

  return true
}
</script>


<style lang="scss" scoped>
.vue-scroll {
  height: 260px;
  width: 100%;
  overflow: hidden;
}

.vue-scroll .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
}

.card-item {
  background: url('@/assets/bg.png') no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.account-container {
  background-color: #1b2130;
  border-radius: 4px;
  height: 46px;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &>div {
    flex: 1;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &>.active {
    background-color: #51e7d3;
    color: #1b2130;
    font-size: 15px;
    border-radius: 4px;
  }
}

.subtotal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #1b2130;
  margin: 10px 0;

  &>div {
    width: 50%;
    box-sizing: border-box;
    text-align: center;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &>p {
      display: block;
      color: #51e7d3;
      font-weight: 600;
      font-size: 18px;
    }

    &>span {
      font-size: 12px;
      font-weight: 400;
      color: #fff;
    }
  }
}

.accounts {
  .action-container {
    background-color: #1b2130;
    border-radius: 4px;
    height: 46px;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    &>div {
      flex: 1;
      text-align: center;
    }

    &>.active {
      background-color: #51e7d3;
      color: #1b2130;
      font-size: 15px;
      border-radius: 4px;
      height: 46px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.amount-left {
  height: 100px;
  width: 100%;
  background-image: url(@/assets/bg-withdraw.ea1f3478.png);
  background-size: cover;
  background-position: 50%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 30px;
  box-sizing: border-box;
}

.amount-input {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0 30px;
  box-sizing: border-box;
  background-color: #1b2130;
  height: 96px;
  border-radius: 4px;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;

  input {
    flex: 2;
    color: #898b98;
    font-size: 14px;
    outline: none;
    border: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
  }

  span {
    flex: 1;
    color: #51e7d3;
    font-size: 12px;
    font-weight: 700;
    text-align: right;
  }
}

.pages {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0 10px;

  span {
    display: inline-block;
    padding: 0 10px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: rgba(81, 231, 211, 0.1);
    color: #51e7d3;
    border-radius: 4px;
  }

  span:last-child {
    background-color: #51e7d3;
    font-weight: 700;
    color: #0b1222;
    margin-left: 20px;
  }
}

.incomes-container {
  background-color: #1b2130;
  border-radius: 4px;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  top: 0;
  -webkit-animation: scrollText1 8s infinite cubic-bezier(1, 0, 0.5, 0);
  animation: scrollText1 8s infinite cubic-bezier(1, 0, 0.5, 0);
}

.faqs-container {
  background-color: #1b2130;
  padding: 5px;
  margin-top: 10px;
  border-radius: 4px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  width: 100%;
  display: block;
}

.faqs-item {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
  align-items: stretch;
  background-color: #0b1222;
  margin: 5px 0;
  border-radius: 4px;
}

.faqs-content {
  overflow: hidden;
  height: 0;

  &.active {
    height: auto;
    transition: height 400ms ease-in-out 0ms,
      -webkit-transform 400ms ease-in-out 0ms, transform 400ms ease-in-out 0ms;
    transform-origin: 50% 50% 0px;
  }

  &>.content-text {
    padding: 12px 15px;
    color: #606266;
    font-size: 14px;
    line-height: 18px;
  }
}

.notice-box {
  width: 500px;
  height: 30px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  border: 1px solid red;
}

.notice-item {
  width: 100%;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
}

.notice-slide-enter-active,
.notice-slide-leave-active {
  transition: all 0.8s linear;
}

.notice-slide-enter {
  top: 30px;
}

.notice-slide-leave-to {
  top: -30px;
}
</style>
