<template>
  <van-config-provider theme="dark">
    <div class="index-body is_phone  cn-body rise-red-body w-100vw h-100vh bg-_wn_fff overflow-scroll text-_wn_04091A">
      <div class="layout">
        <div id="trade-panel">
          <div class="flex--grid--layout  grid_info_wrap market">
            <van-nav-bar left-arrow :title="$t(huobiStore.kLineType0 + '/' + huobiStore.kLineType1)"
              @click-left="onClickLeft" @click="huobiStore.changeShowMarketSheet" class="left-arrow">
              <template #right class="_wn_right">
                <van-icon name="wap-nav" size="18" />
              </template>
            </van-nav-bar>
            <MarketHeader />
          </div>
          <div class="chart-dom">
            <div class="flex--grid--layout  ">
              <div class="flex-row-center tab_h5_wrap">
                <div class="tab_h5 " @click="huobiStore.showTradingType = 0"><span>{{ $t('Chart') }}</span></div>
                <div class="tab_h5 " @click="huobiStore.showTradingType = 1"><span>{{ $t('Trading') }}</span></div>
              </div>
            </div>
            <div class="chart-dom-open">
              <MarketKLine v-if="huobiStore.showTradingType == 0"></MarketKLine>
              <OrderBookSecond v-if="huobiStore.showTradingType == 1 && appType == '1'"></OrderBookSecond>
              <OrderBookVI v-if="huobiStore.showTradingType == 1 && appType == '2'"></OrderBookVI>
            </div>
          </div>

          <MarketRecordContract style="margin-top: 5px;" v-if="appType == '2'" />
          <MarketRecord style="margin-top: 5px;" v-else />
        </div>

        <!-- <van-action-bar v-if="huobiStore.showTradingType == 0"
          style="background-color: #0F0D2A; color: aliceblue;font-size: 16px;">
          <van-action-bar-icon icon="chat-o" :text="$t('Service')" color="#fff"
            style="background-color: #0F0D2A; color: #fff;" @click="store.openService()"  class="color-_wn_fff"/>
          <van-action-bar-button type="danger" :text="$t('BuyLong')"
            @click="huobiStore.changeShowContractCreateOrder('buy')" />
          <van-action-bar-button type="success" :text="$t('BuyLess')"
            @click="huobiStore.changeShowContractCreateOrder('sell')" />
        </van-action-bar> -->
      </div>
    </div>

    <van-action-sheet v-model:show="huobiStore.showMarketSheet" :title="$t('ContractList')"
      style="background-color: #0F0D2A; color: white;">
      <MarketList></MarketList>
    </van-action-sheet>
    <van-action-sheet v-model:show="huobiStore.showContractCreateOrder" :title="$t('ContractOrder')"
      style="background-color: #0F0D2A; color: white;">
      <ContractCreateOrder></ContractCreateOrder>
    </van-action-sheet>
    <van-popup v-model:show="huobiStore.showLeversPopup" position="bottom">
      <van-picker :title="$t('Lever')" :columns="huobiStore.levers" :cancel-button-text="$t('Cancel')"
        :confirm-button-text="$t('Confirm')" @cancel="huobiStore.showLeversPopup = false"
        @confirm="huobiStore.showLeversPopup = false" @change="huobiStore.setLever" />
    </van-popup>
  </van-config-provider>

  <CreateMarketOrder />
</template>

<script setup lang="ts">
import MarketHeader from '@/components/MarketHeader.vue'
import MarketKLine from '@/components/MarketKLine.vue'
import OrderBookSecond from '@/components/OrderBookSecond.vue'
import OrderBookVI from '@/components/OrderBookVI.vue'
import MarketRecord from '@/components/MarketRecord.vue'
import MarketList from '@/components/MarketList.vue'
import CreateMarketOrder from '@/components/CreateMarketOrder.vue'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useCountStore, useHuobiStore } from '@/store/index'
import ContractCreateOrder from '@/components/ContractCreateOrder.vue'
import MarketRecordContract from '@/components/MarketRecordContract.vue'

const appType = import.meta.env.VITE_APP_TYPE
const { t } = useI18n()
const store = useCountStore()
const huobiStore = useHuobiStore()
const router = useRouter()
const onClickLeft = (item: any) => {
  router.push({
    name: 'HomeIndex',
  })
}

onMounted(async () => {
  await huobiStore.loadMarketData()
  huobiStore.initWebSocket()
  huobiStore.changeNumber()

  store.getUserinfo()
})
</script>

<style lang="scss" scoped>
.index-body {
  margin: 0;
  color: var(--colorFont1);
  // background-color: #0F0D2A;
  background-color: #020204;
  font-size: 14px;
  font-family: 'Gate_Sans', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overflow: auto;

  .market {
    padding: 0;
  }

  .chart-dom {
    background-color: #0F0D2A;
    border: 0px;
  }
}
</style>
