import { ContractModel } from '@/@types/contract'
import ajax from '../ajax'
import axios from 'axios'
import {
  TRCVT_USER_ACCESSTOKEN
} from '@/store/constant'


/**
 * 获取邮件验证码
 */
function getEmailCode(email): ResponseData<any> {
  return ajax.get('/API/GetEmailCode', {
    params: {
      email
    }
  })
}

/**
 * 获取系统的基本信息
 */
function getBaseInfo(): ResponseData<any> {
  return ajax.get('/API/GetBaseInfo', {})
}

/**
 * 获取储蓄产品列表
 * @param page 页码
 * @param limit 每页数据量
 */
function getProductList(page: Number, limit: Number): ResponseData<any> {
  return ajax.get('/API/GetProductList', {
    params: {
      page,
      limit
    }
  })
}

/**
 * 获取合约交易货币对列表
 */
function getMarketList(): ResponseData<ContractModel[]> {
  return ajax.get('/API/GetMarketList', {})
}


/**
 * 获取秒合约时间列表
 */
function getMarketOrderTimeList(): ResponseData<ContractModel[]> {
  return ajax.get('/API/GetMarketOrderTimeList', {})
}

/**
 * 上传图片到服务器
 * @param e 文件信息
 */
function uploadImg(file) {
  let formData = new FormData()
  formData.append("file", file)

  var baseUrl = import.meta.env.VITE_APP_AXIOS_BASE_URL;
  return axios({
    baseURL: baseUrl,
    method: 'post',
    url: '/API/UploadImg',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': 'Bearer ' + localStorage.getItem(TRCVT_USER_ACCESSTOKEN)
    }
  })
}

export default {
  getEmailCode,
  getBaseInfo,
  getProductList,
  getMarketList,
  getMarketOrderTimeList,
  uploadImg
}
