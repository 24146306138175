<template>
  <div class="bg-_wn_0b1222 h-44px lh-44px flex-between-center">
    <div class="flex items-center pl-10px">
      <img src="@/assets/logo.png" class="w-26px h-25px mr-13px alt srcset" alt="" srcset="" />
      <span class="font-bold">USDT</span>
    </div>
    <img src="@/assets/menu.png" class="w-20px h-20px mx-13px cursor-pointer alt srcset" alt="" srcset="" @click="show = !show" />
  </div>
  <div>
    <van-popup v-model:show="show" class="header-popup" :position="'top'" :overlay="false">
      <van-list class="text-center">
        <van-cell v-if="appType != '5' && store.isLogin()" class="invite" @click="showInviteDialog = true"
          :title="$t('Invite_friends')"></van-cell>

        <van-cell v-for="{ name, value } in languages" :key="value" :title="name" @click="switchLanguage(value)">
        </van-cell>

        <van-cell v-if="store.isLogin()" :title="$t('Logout')" @click="store.logout()" class="text-center"></van-cell>
        <van-cell @click="show = false" class="text-center">
          <div class="w-full flex-center h-24px">
            <van-icon name="cross" size="20px" />
          </div>
        </van-cell>
      </van-list>
    </van-popup>
    <van-dialog v-model:show="showInviteDialog" :title="$t('Invite_friends')" :cancel-button-text="$t('Cancel')"
      :confirm-button-text="$t('Copy')" :before-close="copyText" class="invite-dialog show-cancel-button" show-cancel-button>
      <div class="bg-_wn_fff w-full rounded-24px h-32px flex-center text-_wn_000 text-12px copyLink" @click="copyText">
        <span>{{ inviteLink + store.usernumber }}</span>
      </div>
    </van-dialog>
  </div>
</template>

<script lang="ts" setup>
import { showFailToast, showSuccessToast } from 'vant'
import Clipboard from 'clipboard'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
// @ts-ignore
import { useCountStore } from '@/store'
const appType = import.meta.env.VITE_APP_TYPE
const store = useCountStore()

const show = ref(false)
const { t } = useI18n()
const languages = [
  { name: t('English'), value: 'en' },
  { name: t('Chinese'), value: 'zh-cn' },
  { name: t('TraditionalChinese'), value: 'zh-tw' },
  { name: t('Japanese'), value: 'ja' },
  { name: t('Vietnamese'), value: 'vi' },
  { name: t('Korean'), value: 'ko' }
]
const active = ref('zh-tw')
const switchLanguage = (value: string) => {
  active.value = value
  localStorage.setItem('LANG', value)
  window.location.reload()
}
const showInviteDialog = ref<boolean>(false)
const inviteLink = ref<string>(`${window?.location.origin}?uid=`)
const copyText = async (action: any, done?: any) => {
  if (action == "confirm") {
    try {
      var link = inviteLink.value + store.usernumber;
      let clipboard = new Clipboard('.van-dialog__confirm', {
        text: () => {
          return link
        }
      })

      clipboard.on('success', () => {
        showSuccessToast(t('CopiedSuccessfully'))
      })

      clipboard.on('error', () => {
        showFailToast(t('Copy_failed'))
      })
    } catch (error) { console.log(error) }
  }
  showInviteDialog.value = false
}
</script>

<style lang="scss" scoped>
::v-deep .invite-dialog {
  .van-dialog__header {
    padding-top: 10px;
  }
}

::v-deep .header-popup {
  background: #1b2130;

  .van-cell {
    background: transparent;
    color: #fff;
    font-size: 14px;
    padding: 10px 18px;
  }
}

.van-cell {
  &.invite {
    color: #51e7d3;

    span {
      font-weight: 600;
    }
  }

  span {
    font-weight: 700;
  }
}

.van-cell:after {
  border-bottom-color: #0b1222;
  left: 0;
  right: 0;
}

.van-popup {
  margin-top: 44px;
}
</style>
