import type { RouteRecordRaw } from 'vue-router'
import HomeView from '@/pages/HomeView.vue'
import HomeBankCard from '@/pages/HomeBankCard.vue'
import HomeDeposit from '@/pages/HomeDeposit.vue'
import HomeMarket from '@/pages/HomeMarket.vue'
import HomeOrder from '@/pages/HomeOrder.vue'
import ProductOrder from '@/pages/ProductOrder.vue'
import Login from '@/pages/Login.vue'
import EthWeb from '@/pages/EthWeb.vue'
import UseLWChart from '@/pages/UseLWChart.vue'
import UseTradeView from '@/pages/UseTradeView.vue'
import IdEntity from '@/pages/HomeIdentity.vue'
import QuickRecharge from '@/pages/QuickRecharge.vue'

const NotFind = () => import('@/pages/404/index.vue')

const routes: RouteRecordRaw[] = [
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFind },
  {
    path: '/',
    name: 'HomeIndex',
    component: HomeView,
    meta: {
      showHeader: true,
      requireLogin: true
    }
  },
  {
    path: '/ethweb',
    name: 'EthWeb',
    component: EthWeb
  },
  {
    path: '/home/bankcard',
    name: 'HomeBankCard',
    component: HomeBankCard,
    meta: {
      showHeader: false,
      requireLogin: true
    }
  },
  {
    path: '/home/deposit',
    name: 'HomeDeposit',
    component: HomeDeposit,
    meta: {
      showHeader: false,
      requireLogin: true
    }
  },
  // HomeMarket
  {
    path: '/home/market',
    name: 'HomeMarket',
    component: HomeMarket,
    meta: {
      showHeader: false,
      requireLogin: true
    }
  },
  {
    path: '/home/order',
    name: 'HomeOrder',
    component: HomeOrder,
    meta: {
      showHeader: false,
      requireLogin: true
    }
  },
  {
    path: '/home/product/order',
    name: 'ProductOrder',
    component: ProductOrder,
    meta: {
      showHeader: false,
      requireLogin: true
    }
  },
  {
    path: '/UseLWChart',
    name: 'UseLWChart',
    component: UseLWChart,
    meta: {
      showHeader: false,
      requireLogin: true
    }
  },
  {
    path: '/UseTradeView',
    name: 'UseTradeView',
    component: UseTradeView,
    meta: {
      showHeader: false,
      requireLogin: true
    }
  },
  {
    path: '/IdEntity',
    name: 'IdEntity',
    component: IdEntity,
    meta: {
      showHeader: false,
      requireLogin: true
    }
  },
  {
    path: '/QuickRecharge',
    name: 'QuickRecharge',
    component: QuickRecharge,
    meta: {
      showHeader: false,
      requireLogin: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      showHeader: false,
      requireLogin: false
    }
  },
]

export default routes
