export default {
  English: 'English',
  Chinese: '简体中文',
  TraditionalChinese: '繁體中文',
  Korean: '한국인',
  Japanese: '日本語',
  Vietnamese: 'Tiếng Việt',

  Asset_Management_Savings_Bank: '資産貯蓄銀行',
  Bank_Account: '銀行口座',
  Participate_in_savings: '貯蓄に参加する',
  Selected_financial_management: '契約取引',
  user_pool: 'ユーザープール',
  account: 'アカウント',
  total_output: '総生産高',
  Valid_node: '有効なノード',
  participant: '参加者',
  income: '所得',
  Remaining_amount: '貯蓄収入',
  savings_income: '貯蓄収入',
  Todays_earnings: '今日の収益',
  Financial_management_income: '財務管理収入',
  exchange: '交換',
  recharge: '補充する',
  extract: '抽出する',
  Record: '記録',
  convertible_income: '転換可能な収入',
  exchange_amount: '交換金額',
  Please_enter_the_exchange_amount: '両替金額を入力してください',
  total: '全て',
  Available_Balance: '利用可能残高',
  Withdrawal_amount: '出金額',
  Please_enter_the_withdrawal_amount: '出金額を入力してください',
  Profit_record: '利益実績',
  address: '住所',
  number: '番号',
  common_problem: 'よくある問題',
  Previous_page: '前のページ',
  Next_page: '次のページ',
  Audit_Report: '監査報告書',
  We_have_a_security_audit_report: 'セキュリティ監査レポートがあります',
  partner: '相棒',
  our_business_partners: '私たちのビジネスパートナー',
  expenditure: '支出',
  amount: 'アカウント',
  remark: '述べる',
  bankNumberName: '口座名義(カナ)：',
  Quote_change: '見積変更',
  Highest: '最高',
  My_Order: '私の注文',
  Buy: '買う',
  Kline_chart: 'K 折れ線グラフ',
  Please_select_contract_time: '契約時間を選択してください',
  purchasing_price: '購入価格',
  Please_enter_the_purchase_amount: '購入金額を入力してください',
  savings: '貯蓄',
  Financial_institution_name: '銀行名：',
  Please_enter: '入ってください',
  Branch_name: '支店名：',
  branch_number: '店番：',
  jiaoyileixing: '取引種類：',
  Account_holder_jiaming: '口座名義(カナ)：',
  zhanghao: '囗座番号：',
  Account_holder: '口座名義：',
  UsdtERC20Address: 'ユーザー返金アドレス',
  binding: 'バインディング',
  Invite_friends: '友達を招待',
  CopiedSuccessfully: '正常にコピーされました',
  Copy_failed: 'コピーに失敗しました',
  Please_enter_the_amount: '金額を入力してください',
  Select_storage_period: '貯蓄サイクルを選択する',
  money: '金额',
  USDT: 'USDT',
  Daily_rate_of_return: '日次利回り',
  savings_products: '貯蓄商品',
  Currency: '通貨',
  Saving_amount: '貯金額',
  PleaseEnterTheCorrectAmount: '正しい金額を入力してください',
  NotOpenYet: 'まだオープンしていません',
  Earnings: '所得',
  Expenses: '支出',
  Confirm: 'もちろん',
  Cancel: 'キャンセル',
  Copy: 'コピー',
  RechargeAmount: 'リチャージ量',
  PleaseEnterTheRechargeAmount: 'チャージ金額を入力してください',
  RechargeERC20Address: 'UsdtERC20入金アドレス',
  Submit: '提出する',

  YourAccountHasBeenDisabled: 'あなたのアカウントは無効になっています!',
  InformationUpdatedSuccessfully: '情報が正常に更新されました',
  YourAccountIsFrozen: 'あなたのアカウントは凍結されています!',
  ProductDoesNotExist: '製品が存在しません',
  ThePurchaseAmountMustBeLessThanTheProductAmount: '購入金額は商品金額未満である必要があります',
  InsufficientAccountBalance: '口座残高不足',
  WithdrawalRequestHasBeenSubmitted: '出金リクエストが送信されました',
  RechargeRequestHasBeenSubmitted: 'リチャージリクエストが送信されました',
  AmountEnteredIncorrectly: '入力された金額が間違っています!',
  Successfully: '成功しました',
  Day: '日',
  BuyFailed: '貯蓄に失敗する',
  BuySuccessfully: '貯金に成功する',

  Withdrawal: 'お金を引き出す',
  PurchaseProduct: '製品を購入する',
  RegistrationTrialBonus: '登録お試しボーナス',
  CurrencyConversion: '通貨換算',
  SavingsIncome: '貯蓄収入',
  FinancialIncome: '財務管理収入',
  SavingsRelease: '貯蓄の解放',

  BindingSuccessful: 'バインド成功',
  ViewThisAccount: 'このアカウントを見る',
  NoWalletEnv: 'このシステムを開くにはウォレットブラウザを使用してください!',
  PleaseBindYourBankCardBeforeWithdrawingMoney: 'お金を引き出す前に銀行カードをバインドしてください!',
  天: '日',
  周: '週間',
  月: 'ヶ月',
  年: '年',
  ContractTrading: '契約取引',
  '1Min': '1分',
  '5Min': '5分',
  '1Hour': '1時間',
  '1Day': '1日',

  Loading: '読み込み中',
  ContractTimeError: '契約時間エラー',
  ContractTypeError: 'ContractTypeError',
  ContractNotSupported: '契約はサポートされていません',
  YouHaveAContractOrderInProgressButTheOrderFailed: '契約注文は進行中ですが注文は失敗しました',
  NotOrder: '注文しない',
  PleaseSelectContractTime: '契約時間を選択してください',
  PleaseEnterThePurchaseAmount: '購入金額を入力してください',
  BuyLong: '長く買う',
  BuyLess: '購入を減らす',
  PurchasingPrice: '購入価格',
  Gain: '得',
  Loss: '損失',
  Ok: 'Ok',
  ContractOrder: '契約注文',
  ContractPrincipal: '契約本人',
  WithdrawalReturn: '引き出し返却',

  OrderStatusError: '注文ステータスエラー!',
  HighestPrice: '最高価格',
  LowestPrice: '最低価格',
  TradingVolume: '取引量',
  TransactionVolume: '取引量',
  Position: '位置',
  CommissionRecord: '手数料記録',
  ProfitLoss: '利益損失',

  OpeningPrice: '開始価格',
  CurrentPrice: '現在の価格',
  PositionQuantity: '位置数量',
  ClosePosition: '閉じる位置',
  Service: 'サービス',
  ContractList: '契約一覧',
  LatestPrice: '最新の価格',
  PleaseEnterTheSearchKeyword: '検索キーワードを入力してください',
  Number: '量',
  NoData: 'データなし',
  Price: '価格',

  TradeBuy: '下取り購入',
  TradeSell: '貿易販売',
  Security: '安全',
  Lever: 'レバー',
  Chart: 'チャート',
  Trading: '取引',
  Cross: 'クロス',
  Isolated: '孤立した',
  WalletLogin: 'ウォレットへのログイン',
  Username: 'ユーザー名',
  Password: 'パスワード',
  ConfirPassword: 'パスワードを認証する',
  PleaseEnterTheUsername: 'ユーザー名を入力してください!',
  PleaseEnterThePassword: 'パスワードを入力してください!',
  PleaseEnterTheConfirPassword: '確認用のパスワードを入力してください。!',
  Logout: 'ログアウト',
  ConfirPasswordError: 'パスワードエラーを確認してください!',
  UsernameAlreadyExists: 'ユーザー名は既に存在します！',
  InsufficientAccountSecurity: 'アカウントのセキュリティが不十分です!',
  AlreadyHaveAnAccountClickToLogIn: 'すでにアカウントをお持ちの場合クリックしてログイン',
  DontHaveAnAccountYetClickToRegister: 'まだアカウントを持っていない場合はクリックして登録してください',

  PleaseCompleteTheIdentityAuthenticationFirst: 'まずは本人確認を完了してください！',
  AuthenticationBindingSuccessful: '認証バインディングが成功しました!',
  WaitingReview: '検証待ちです！',
  Next: '次のステップ',
  Jump: '飛び越える',
  RegisterAccount: 'アカウント登録',
  Authentication: '認証',
  SecureBinding: '安全なバインディング',
  Finish: '仕上げる',

  Name: '名前',
  PleaseTypeInYourName: 'あなたの名前を入力してください',
  IdNumber: 'ID番号',
  PleaseTypeInYourIdNumber: '識別番号を入力してください',
  FrontOfDocument: '書類の前面',
  ReverseSideOfId: '身分証明書の裏側',
  HoldingIdPhoto: '証明写真を持っている',
  UsdtAddress: 'USDTアドレス',
  Failed: '失敗',
  ClosePositionPrice: '終値',
  PositionClosed: 'ポジションをクローズ',

  QuickRecharge: 'クイックリチャージ',
  PleaseTypeInYourUsdtAddress: 'USDTアドレスを入力してください',
  CopyRechargeAddress: 'リチャージアドレスをコピーする',
  Login: 'ログイン',
  Email: 'メール',
  PleaseEnterTheEmail: 'メールアドレスを入力してください',

  PleaseEnterTheVerifyCode: 'メール認証コードを入力してください',
  EmailCode: '検証コード',
  GetCode: '得る',
  SwitchLanguage: '言語を選択する',
  RegisterType: '登録タイプ',
  RegisterSuccess: '登録成功',
  PasswordError: '間違ったパスワード',

  PleaseEnterTheTransferAddress: '転送先住所を入力してください',
  TransferVoucher: '振替伝票',
  OpenPosition: 'ポジションをオープンする',
  BuyToOpenLong: '長く開くために買う',
  SellToOpenShort: '空売りする',
  BuyShort: '長く売る',
  SellLong: '空売りを買う',
  Kepingduo: '京平多',
  Kepingkong: '閉じることができます',

  PleaseTypeInYourETHAddress: 'ETHアドレスを入力してください',
  PleaseTypeInYourBTCAddress: 'BTC アドレスを入力してください',
  Save: '保存',
  SecurityRate: 'マージン率',
  Handling: '手数料',
  Zhang: '開ける',
  SecurityUnfreeze: 'セキュリティの凍結解除',
  AccountError: 'アカウントエラー!'
}
