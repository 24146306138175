<template>
  <div>
    <van-overlay :show="showLoading" @click="showLoading = false">
      <div class="flex-center h-100vh">
        <van-loading />
      </div>
    </van-overlay>
    <van-nav-bar left-arrow :title="$t('Bank_Account')" @click-left="onClickLeft"  class="left-arrow"/>
    <van-cell-group inset class="inset">
      <van-field v-if="appType != '3'" v-model="bankinfo.bankName" :label="$t('Financial_institution_name')" :placeholder="`${lang == 'vi' ? '' : $t('Please_enter') + $t(
        'Financial_institution_name'
      )}`" label-align="top" />

      <van-field v-if="appType != '3'" v-model="bankinfo.bankStoreName" :label="$t('Branch_name')"
        :placeholder="`${lang == 'vi' ? '' : ($t('Please_enter') + $t('Branch_name'))}`" label-align="top" />

      <van-field v-if="appType != '3'" v-model="bankinfo.bankStoreNumber" :label="$t('branch_number')"
        :placeholder="`${lang == 'vi' ? '' : ($t('Please_enter') + $t('branch_number'))}`" label-align="top" />

      <van-field v-if="appType == '1'" v-model="bankinfo.jiaoyileixing" :label="$t('jiaoyileixing')"
        :placeholder="`${lang == 'vi' ? '' : ($t('Please_enter') + $t('jiaoyileixing'))}`" label-align="top" />

      <van-field v-if="appType != '3' && appType != '2'" v-model="bankinfo.bankNumber"
        :label="appType == '1' ? $t('zhanghao') : $t('amount')"
        :placeholder="`${lang == 'vi' ? '' : ($t('Please_enter') + $t('amount'))}`" label-align="top" />

      <van-field v-if="appType != '3'" v-model="bankinfo.bankNumberName" :label="$t('bankNumberName')"
        :placeholder="`${lang == 'vi' ? '' : ($t('Please_enter') + $t('amount'))}`" label-align="top" />

      <van-field v-if="appType != '3'" v-model="bankinfo.bankUsername" :label="$t('Account_holder')"
        :placeholder="`${lang == 'vi' ? '' : ($t('Please_enter') + $t('Account_holder'))}`" label-align="top" />

      <van-field v-if="appType != '2'" v-model="bankinfo.usdtERC20" type="textarea" :label="$t('UsdtERC20Address')"
        :placeholder="`${lang == 'vi' ? '' : ($t('Please_enter') + $t('UsdtERC20Address'))}`" label-align="top" />
    </van-cell-group>
    <div class="px-15px">
      <div v-if="appType == '2' && !store.bankName" class="action-button rounded-25px" @click="bindBankinfo">
        {{ $t('binding') }}
      </div>
      <div v-if="appType != '2'" class="action-button rounded-25px" @click="bindBankinfo">
        {{ $t('binding') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BankInfo } from '../@types/bankInfo'
import { userApi } from '../apis/index'
import { showFailToast, showSuccessToast, showToast } from 'vant'
import { ref, onMounted } from 'vue'
import { useCountStore } from '@/store'

const lang = ref<String>('')
onMounted(() => {
  lang.value = localStorage.getItem('LANG')?.toString()
  setTimeout(() => {
    document.querySelector("html").scrollTop = 0
  }, 500);
})
const store = useCountStore()
const { t } = useI18n()
const appType = import.meta.env.VITE_APP_TYPE;
// loading true 显示 false 隐藏
const showLoading = ref(false)

const bankinfo: any = ref<BankInfo>({
  bankName: store.bankName,
  bankNumber: store.bankNumber,
  bankNumberName: store.bankNumberName,
  bankStoreName: store.bankStoreName,
  bankStoreNumber: store.bankStoreNumber,
  jiaoyileixing: store.jiaoyileixing,
  bankUsername: store.bankUsername,
  usdtERC20: store.usdtERC20Address,
})
const onClickLeft = () => window?.history.back()

// 绑定银行卡信息
const bindBankinfo = () => {
  showLoading.value = true
  userApi.setBankInfo(bankinfo.value).then((result) => {
    showLoading.value = false
    if (result.code == 0) {
      showSuccessToast(t(result.msg))

      store.updateUserinfo(result.data)
    } else {
      showFailToast(t(result.msg))
    }
  })
}
</script>

<style lang="scss" scoped>
::v-deep .van-cell-group {
  background: transparent;

  .van-cell {
    background: transparent;
    padding-left: 0;
    padding-right: 0;
  }

  .van-cell:after {
    display: none;
  }

  .van-field__label--top {
    color: #fff;
    line-height: 36px;
  }

  .van-field__control {
    line-height: 36px;
    padding: 0 6px;
    border-radius: 25px;
    background: #fff;
    outline: 0;
  }

  .van-cell {
    padding-bottom: 0;
    padding-top: 5px;
  }
}
</style>
