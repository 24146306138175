export default {
  English: 'English',
  Chinese: '简体中文',
  TraditionalChinese: '繁體中文',
  Korean: '한국인',
  Japanese: '日本語',
  Vietnamese: 'Tiếng Việt',

  Asset_Management_Savings_Bank: '자산저축은행',
  Bank_Account: '은행계좌',
  Participate_in_savings: '참여저축',
  Selected_financial_management: '정선재테크',
  user_pool: '사용자 풀',
  account: '계정',
  total_output: '총생산량',
  Valid_node: '유효노드',
  participant: '참가자',
  income: '수익',
  Remaining_amount: '저축수익',
  savings_income: '저축수익',
  Todays_earnings: '오늘의수익',
  Financial_management_income: '재테크수익',
  exchange: '교환하다',
  recharge: '돈을',
  extract: '인출하다',
  Record: '기록',
  convertible_income: '전환가능수익',
  exchange_amount: '교환금액',
  Please_enter_the_exchange_amount: '교환금액을 입력하세요',
  total: '전부',
  Available_Balance: '가용잔액',
  Withdrawal_amount: '인출금액',
  Please_enter_the_withdrawal_amount: '인출금액을입력하세요',
  Profit_record: '이익기록',
  address: '주소',
  number: '숫자',
  common_problem: '자주 묻는 질문',
  Previous_page: '이전 페이지',
  Next_page: '다음 페이지',
  Audit_Report: '감사보고서',
  We_have_a_security_audit_report: '우리는 안전 감사 보고서를 보유하고 있습니다',
  partner: '파트너',
  our_business_partners: '우리의 비즈니스 파트너',
  expenditure: '지출',
  amount: '계정',
  remark: '비고',
  bankNumberName: '계정명',
  Quote_change: '등락폭',
  Highest: '최고',
  My_Order: '내 주문서',
  Buy: '구입',
  Kline_chart: 'K선도',
  Please_select_contract_time: '계약 기간을 선택하십시오',
  purchasing_price: '구매금액',
  Please_enter_the_purchase_amount: '구매금액을 입력해 주세요',
  savings: '저축',
  Financial_institution_name: '금융기관명',
  Please_enter: '입력하세요',
  Branch_name: '지점명',
  branch_number: '지점 번호',
  zhanghao: '계정',
  Account_holder: '계좌보유자',
  UsdtERC20Address: 'USDT/ERC20 주소',
  binding: '확인 바인딩',
  Invite_friends: '친구 초대',
  CopiedSuccessfully: '복사 성공',
  Copy_failed: '복사 실패',
  Please_enter_the_amount: '금액을 입력해 주세요',
  Select_storage_period: '저장 주기 선택',
  money: '금액',
  USDT: 'USDT',
  Daily_rate_of_return: '일수익률',
  savings_products: '저축 상품',
  Currency: '화폐 종류',
  Saving_amount: '저축금액',
  PleaseEnterTheCorrectAmount: '정확한 금액을 입력하세요',
  NotOpenYet: '아직 개방되지 않음',
  Earnings: '소득',
  Expenses: '지출',
  Confirm: '확정하다',
  Cancel: '취소',
  Copy: '복사',
  RechargeAmount: '충전수량',
  PleaseEnterTheRechargeAmount: '충전수량을 입력하세요',
  RechargeERC20Address: 'USDT/ERC20 충전 주소',
  Submit: '제출하다',

  YourAccountHasBeenDisabled: '계정이 비활성화되었습니다!',
  InformationUpdatedSuccessfully: '정보 업데이트 성공',
  YourAccountIsFrozen: '계정이 동결되었습니다!',
  ProductDoesNotExist: '제품이 존재하지 않습니다',
  ThePurchaseAmountMustBeLessThanTheProductAmount: '구매금액이 제품금액보다 작아야 합니다',
  InsufficientAccountBalance: '계좌잔액부족',
  WithdrawalRequestHasBeenSubmitted: '현금 인출 요청이 제출되었습니다',
  RechargeRequestHasBeenSubmitted: '충전 요청이 제출되었습니다',
  AmountEnteredIncorrectly: '입력한 금액이 잘못되었습니다!',
  Successfully: '성공적으로',
  Day: '일',
  BuyFailed: '구매 실패',
  BuySuccessfully: '성공적으로 구매',

  Withdrawal: '돈을 인출',
  PurchaseProduct: '제품을 구매하다',
  RegistrationTrialBonus: '등록 체험 보너스',
  CurrencyConversion: '통화 변환',
  SavingsIncome: '저축 소득',
  FinancialIncome: '재무관리수익',
  SavingsRelease: '저축 해제',
  BindingSuccessful: '바인딩 성공',
  ViewThisAccount: '이 계정 보기',
  NoWalletEnv: '이 시스템을 열려면 지갑 브라우저를 사용하십시오!',
  PleaseBindYourBankCardBeforeWithdrawingMoney: '돈을 인출하기 전에 은행 카드를 묶어주세요!',
  天: '일',
  周: '주',
  月: '월',
  年: '년',
  ContractTrading: '계약거래',
  '1Min': '1분',
  '5Min': '5분',
  '1Hour': '1시간',
  '1Day': '1일',

  Loading: '로드 중',
  ContractTimeError: '계약 시간 오류',
  ContractTypeError: '계약 유형 오류',
  ContractNotSupported: '계약이 지원되지 않음',
  YouHaveAContractOrderInProgressButTheOrderFailed: 'YouHaveAContractOrder진행 중이지만TheOrderFailed',
  NotOrder: '주문하지 않음',
  PleaseSelectContractTime: 'PleaseSelect계약 시간',
  PleaseEnterThePurchaseAmount: '구매 금액을 입력하세요.',
  BuyLong: '매수롱',
  BuyLess: '덜 구매',
  PurchasingPrice: '구매가격',
  Gain: '얻다',
  Loss: '손실',
  Ok: '좋아요',
  ContractOrder: '계약주문',
  ContractPrincipal: '계약 당사자',
  WithdrawalReturn: '출금반환',

  OrderStatusError: '주문상태오류!',
  HighestPrice: '최고가',
  LowestPrice: '최저 가격',
  TradingVolume: '거래량',
  TransactionVolume: '거래량',
  Position: '위치',
  CommissionRecord: '커미션 기록',
  ProfitLoss: '이익 손실',
  OpeningPrice: '개장가격',
  CurrentPrice: '현재 가격',
  PositionQuantity: '위치수량',
  ClosePosition: '닫기 위치',
  Service: '서비스',
  ContractList: '계약 목록',
  LatestPrice: '최신 가격',
  PleaseEnterTheSearchKeyword: '검색 키워드를 입력하세요',
  Number: '숫자',
  NoData: '데이터 없음',
  Price: '가격',

  TradeBuy: '무역구매',
  TradeSell: '무역판매',
  Security: '보안',
  Lever: '지렛대',
  Chart: '차트',
  Trading: '거래',
  Cross: '십자가',
  Isolated: '외딴',
  WalletLogin: '지갑로그인',
  Username: '사용자 이름',
  Password: '비밀번호',
  ConfirPassword: '패스워드 확인',
  PleaseEnterTheUsername: '사용자 이름을 입력하세요.!',
  PleaseEnterThePassword: '비밀번호를 입력하세요.!',
  PleaseEnterTheConfirPassword: '비밀번호를 입력하세요.!',
  Logout: '로그 아웃',
  ConfirPasswordError: '비밀번호 확인오류!',
  UsernameAlreadyExists: '사용자 이름이 이미 존재합니다!',
  InsufficientAccountSecurity: '불충분한계정보안!',
  AlreadyHaveAnAccountClickToLogIn: '이미 계정이 있습니다. 로그인하려면 클릭하세요.',
  DontHaveAnAccountYetClickToRegister: '아직 계정이 없습니다. 등록하려면 클릭하세요.',

  PleaseCompleteTheIdentityAuthenticationFirst: '먼저 본인확인을 완료해주세요!',
  AuthenticationBindingSuccessful: '인증 바인딩에 성공했습니다!',
  WaitingReview: '확인을 기다리고 있습니다!',
  Next: '다음 단계',
  Jump: '뛰어 넘다',
  RegisterAccount: '계정 등록',
  Authentication: '입증',
  SecureBinding: '보안 바인딩',
  Finish: '마치다',

  Name: '이름',
  PleaseTypeInYourName: '이름을 입력해주세요',
  IdNumber: 'ID 번호',
  PleaseTypeInYourIdNumber: '주민등록번호를 입력해주세요',
  FrontOfDocument: '문서 앞',
  ReverseSideOfId: '신분증 뒷면',
  HoldingIdPhoto: '증명사진을 들고 있는 모습',
  UsdtAddress: 'Usdt 주소',
  Failed: '실패하다',
  ClosePositionPrice: '종가',
  PositionClosed: '포지션 폐쇄',

  QuickRecharge: '빠른 재충전',
  PleaseTypeInYourUsdtAddress: '귀하의 Usdt 주소를 입력해주세요',
  CopyRechargeAddress: '충전 주소 복사',
  Login: '로그인',
  Email: '우편',
  PleaseEnterTheEmail: '당신의 이메일 주소를 입력하십시오',
  PleaseEnterTheVerifyCode: '이메일 인증코드를 입력해주세요',
  EmailCode: '이메일 인증코드',
  GetCode: '인증 코드 받기',
  SwitchLanguage: '언어를 선택하세요',
  RegisterType: '등록 유형',
  RegisterSuccess: '등록 성공',
  PasswordError: '잘못된 비밀번호',
  PleaseEnterTheTransferAddress: '송금주소를 입력해주세요',
  TransferVoucher: '환승 바우처',
  OpenPosition: '포지션을 개설하세요',
  BuyToOpenLong: '오래오래 열려면 구매하세요',
  SellToOpenShort: '공매도하다',
  BuyShort: '오래 팔다',
  SellLong: '짧게 사다',
  Kepingduo: '커핑두오',
  Kepingkong: '닫을 수 있음',

  PleaseTypeInYourETHAddress: 'ETH 주소를 입력해주세요',
  PleaseTypeInYourBTCAddress: 'BTC 주소를 입력하세요',
  Save: '구하다',
  SecurityRate: '마진율',
  Handling: '수수료',
  Zhang: '열려 있는',
  SecurityUnfreeze: '보안 동결해제',
  AccountError: '계정 오류!',

  jiaoyileixing: '거래 종류:',
  Account_holder_jiaming: '계좌명의(가나)'
}
