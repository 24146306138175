import ajax from '../ajax'

/**
 * 创建秒合约订单
 * @param id 货币对Id
 * @param timeId 秒合约时间Id
 * @param type 交易方向
 * @param number 交易金额
 */
function createMarketOrder(id, timeId, type, number) {
  return ajax.post('/API/createMarketOrder', {
    id, timeId, type, number
  })
}

/**
 * 获取秒合约订单列表
 * @param page 页码
 * @param limit 数据量
 */
function getMarketOrderList(page, limit) {
  return ajax.post('/API/GetMarketOrderList', {
    page, limit
  })
}

/**
 * 创建合约订单
 * @param id 合约Id
 * @param type 交易方向
 * @param number 下单数量
 */
function createContractOrder(id, type, tradeType, number, lever, securityType): ResponseData<any> {

  return ajax.post('/API/createContractOrder', {
    id, type, tradeType, number,
    lever,
    securityType
  })
}

/**
 * 获取订单列表
 * @param page 页码
 * @param limit 页数锯大小
 * @param status 订单状态
 * @returns 订单列表实体
 */
function getContractOrderList(page, limit, status): ResponseData<any> {
  return ajax.post('/API/GetContractOrderList', {
    page, limit, status
  })
}

/**
 * 订单平仓
 * @param id 订单Id
 */
function closePosition(id): ResponseData<any> {
  return ajax.post('/API/ClosePosition', {
    id
  })
}



export default {
  createMarketOrder,
  getMarketOrderList,
  createContractOrder,
  getContractOrderList,
  closePosition
}
