<template>
  <div class="top-menu-box">
    <ul class="top-menu-ul">
      <!-- 1min, 5min, 60min, 1day -->
      <li v-bind:class="{ 'li-curr': huobiStore.kTime == '1min' }" @click="huobiStore.changeKtime('1min')">{{ $t('1Min')
      }}</li>
      <li v-bind:class="{ 'li-curr': huobiStore.kTime == '5min' }" @click="huobiStore.changeKtime('5min')">{{ $t('5Min')
      }}</li>
      <li v-bind:class="{ 'li-curr': huobiStore.kTime == '60min' }" @click="huobiStore.changeKtime('60min')">{{
        $t('1Hour') }}</li>
      <li v-bind:class="{ 'li-curr': huobiStore.kTime == '1day' }" @click="huobiStore.changeKtime('1day')">{{ $t('1Day')
      }}</li>
    </ul>
    <ul class="top-menu-ul-right">
      <!-- <li>设置</li> -->
    </ul>
  </div>
  <div class="chart" ref="chartBoxRef"></div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, watch, markRaw } from 'vue'
import { useRoute } from 'vue-router'
import { useHuobiStore } from '@/store/index'
import * as echarts from 'echarts'

const route = useRoute()
const huobiStore = useHuobiStore()

const chartBoxRef = ref(null)
const myChart = ref(null)

const kType = ref('ETHUSDT')

const calculateMA = (dayCount) => {
  var result = [];
  for (var i = 0, len = huobiStore.candlestick.length; i < len; i++) {
    if (i < dayCount) {
      result.push('-');
      continue;
    }
    var sum = 0;
    for (var j = 0; j < dayCount; j++) {
      sum += +huobiStore.candlestick[i - j][1];
    }
    result.push(sum / dayCount);
  }
  return result;
}

const draw = () => {
  myChart.value = markRaw(echarts.init(chartBoxRef.value, 'dark'))
  var option = {
    animation: false,
    tooltip: {
      show: false,
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        snap: true
      },
    },
    axisPointer: {
      link: [
        {
          xAxisIndex: [0, 1]
        }
      ]
    },
    grid: [
      {
        top: '5%',
        left: '2%',
        right: '15%',
        height: 230,
      },
      {
        left: '2%',
        right: '15%',
        height: 80,
        top: 280,
        // borderWidth: 1,
        // show: true,
      }
    ],
    xAxis: [
      {
        type: 'category',
        data: huobiStore.date,
        boundaryGap: false,
        // axisLine: { onZero: false },
        splitLine: { show: true },
        // min: 'dataMin',
        // max: 'dataMax',
      },
      {
        type: 'category',
        data: huobiStore.bar,
        show: false,
        gridIndex: 1,
        boundaryGap: false
      }
    ],
    yAxis: [
      {
        position: 'right',
        scale: true,
        splitArea: {
          show: true
        }
      },
      {
        position: 'right',
        splitNumber: 2,
        type: 'value',
        scale: true,
        splitLine: { show: true },
        splitArea: {
          show: true
        },
        gridIndex: 1,
        // axisLabel: { interval: 2 },
        // axisLine: { interval: 2 },
        // axisTick: { show: false },
        // splitLine: { show: false }
      }
    ],
    dataZoom: [
      {
        animation: false,
        type: 'inside',
        xAxisIndex: [0, 1],
        start: 88,
        end: 100,
        top: 30,
        height: 20
      },
    ],
    series: [
      {
        type: 'candlestick',

        data: huobiStore.candlestick,

        markPoint: {
          symbol: 'rect',
          symbolSize: [2, 20],
          itemStyle: {
            color: '#575D6B'
          },
          data: [
            {
              type: 'max'
            },
            {
              type: 'min',
            }
          ]
        }
      },
      {
        type: 'bar',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: huobiStore.bar,
      },
      {
        name: 'MA5',
        type: 'line',
        symbol: 'none',
        data: calculateMA(5),
        smooth: true,
        lineStyle: {
          opacity: 0.5
        }
      },
      {
        name: 'MA10',
        type: 'line', symbol: 'none',
        data: calculateMA(10),
        smooth: true,
        lineStyle: {
          opacity: 0.5
        }
      },
      {
        name: 'MA30',
        type: 'line', symbol: 'none',
        data: calculateMA(30),
        smooth: true,
        lineStyle: {
          opacity: 0.5
        }
      },

    ]
  }

  myChart.value.setOption(option)
  window.addEventListener("resize", function () {
    myChart.value.resize()
  })
}

const reDraw = () => {
  var option = {
    xAxis: [
      {
        data: huobiStore.date
      },
      {
        data: huobiStore.bar
      }
    ],
    series: [
      {
        data: huobiStore.candlestick,
      },
      {
        data: huobiStore.bar,
      },
      {
        data: calculateMA(5),
      },
      {
        data: calculateMA(10),
      },
      {
        data: calculateMA(30),
      },
    ]
  }

  myChart.value.setOption(option)
}



onMounted(() => {
  draw()
})

// 可以直接侦听一个 ref
watch(huobiStore, (newHuobiStore, oldHuobiStore) => {
  let type = newHuobiStore.kLineType0 + '' + newHuobiStore.kLineType1;
  if (kType.value != type) {
    kType.value = type
    console.log("执行修改")
    huobiStore.changeKtime("1min")
  }
  // console.log("watch", huobiStore)
  reDraw()
})
</script>

<style lang="less">
.top-menu-box {
  // height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #4A505C;
  border-bottom: 1px solid;

  ul {
    display: flex;
    margin: 5px 0px;

    li {
      width: 50px;
      text-align: center;
    }

    .li-curr {
      color: white;
      font-weight: 900;
    }
  }
}

.chart {
  width: 100%;
  height: 370px;
}
</style>
