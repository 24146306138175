import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import i18n from './locales/index'

import router from './router'

import App from './App.vue'
import Axios from './apis/ajax'
// import mountElementUI from './utils/elementUI'
import 'uno.css'
import 'vant/lib/index.css'
import vue3SeamlessScroll from "vue3-seamless-scroll";

document.title = import.meta.env.VITE_APP_TITLE

const app = createApp(App)
app.use(createPinia())
app.use(vue3SeamlessScroll);
app.use(i18n)
app.provide('$http', Axios)
app.use(router)

// 全量引入Element UI
// mountElementUI(app)
app.mount('#app')
